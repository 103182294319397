import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { uuid } from "../../utils/uuid";

type Toast = {
  id: string;
  mode: "success" | "warning" | "error" | "info";
  message: string;
};

type UIState = {
  toasts: Toast[];
  removingToast: string;
};

const initialState: UIState = {
  toasts: [],
  removingToast: "",
};

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    addToast: (
      state,
      action: PayloadAction<{ mode: Toast["mode"]; message: Toast["message"] }>
    ) => {
      const { mode, message } = action.payload;
      const newToast = {
        mode,
        message,
        id: uuid(),
      };
      state.toasts = [...state.toasts, newToast];
    },
    removeToast: (state, action: PayloadAction<string>) => {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload
      );
    },
    setRemovingToast: (state, action: PayloadAction<string>) => {
      state.removingToast = action.payload;
    },
  },
});

export const { addToast, removeToast, setRemovingToast } = uiSlice.actions;

export const selectToasts = ({ ui }: { ui: UIState }) => ({
  toasts: ui.toasts,
  removing: ui.removingToast,
});

export default uiSlice.reducer;
