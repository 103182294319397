import { Product } from "./../types/types";
import HttpService from "../../SharedModule/utils/api";
const BASE_URL = "/products/Product";

function getAllProducts(): Promise<Product[]> {
  return HttpService.req(`${BASE_URL}/GetAllProducts`);
}

function addProduct<T>(name: string, accountId: number, body: any): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/AddProduct?productName=${encodeURIComponent(
      name
    )}&accountId=${accountId}`,
    data: [...body],
  });
}

function editProduct<T>(
  id: number,
  name: string,
  accountId: number,
  body: any
): Promise<T> {
  return HttpService.req({
    method: "put",
    url: `${BASE_URL}/EditProduct?id=${id}&productName=${encodeURIComponent(
      name
    )}&accountId=${accountId}`,
    data: [...body],
  });
}

function setInactiveProduct<T>(id: number): Promise<T> {
  return HttpService.req({
    method: "patch",
    url: `${BASE_URL}/SetInactiveProduct?id=${id}`,
  });
}

function setActiveProduct<T>(id: number): Promise<T> {
  return HttpService.req({
    method: "patch",
    url: `${BASE_URL}/SetActiveProduct?id=${id}`,
  });
}

function deleteProduct<T>(id: number): Promise<T> {
  return HttpService.req({
    method: "delete",
    url: `${BASE_URL}/DeleteProduct?id=${id}`,
  });
}

export const ProductsService = {
  getAllProducts,
  addProduct,
  editProduct,
  setInactiveProduct,
  setActiveProduct,
  deleteProduct
};
