import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product } from "./../../types/types";
import { sortDataBy } from "../../../SharedModule/utils/dataSort";

type ProductsState = {
  list: Product[];
  orderedBy: {
    orderBy: string;
    criteria: "asc" | "desc";
    dataType?: "string";
  };
  isLoadingProducts: boolean;
  isShowingActives: boolean;
  isLoadingExternalBilling: boolean;
  isLoadingExternalProducts: boolean;
};

const initialState: ProductsState = {
  list: [],
  orderedBy: {
    orderBy: "name",
    criteria: "asc",
  },
  isLoadingProducts: true,
  isShowingActives: true,
  isLoadingExternalBilling: true,
  isLoadingExternalProducts: true,
};

export const productsSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    load: (state, action) => {
      state.list = action.payload;
      state.isLoadingProducts = false;
      state.isShowingActives = true;
    },
    setIsLoadingProducts: (state, action) => {
      state.isLoadingProducts = action.payload;
    },
    setIsLoadingExternalBilling: (state, action) => {
      state.isLoadingExternalBilling = action.payload;
    },
    setIsLoadingExternalProducts: (state, action) => {
      state.isLoadingExternalProducts = action.payload;
    },
    setShowActives: (state, action) => {
      state.isShowingActives = action.payload;
    },
    changeOrder: (
      state,
      action: PayloadAction<{
        orderBy: string;
        dataType?: "string";
      }>
    ) => {
      const newOrderedBy: any = {
        ...action.payload,
        criteria: "asc",
      };
      if (newOrderedBy.orderBy === state.orderedBy.orderBy) {
        newOrderedBy.criteria =
          state.orderedBy.criteria === "asc" ? "desc" : "asc";
      }
      return { ...state, orderedBy: newOrderedBy };
    },
    clear: (state) => {
      state.list = [];
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  load,
  setIsLoadingProducts,
  setIsLoadingExternalBilling,
  setIsLoadingExternalProducts,
  setShowActives,
  clear,
  changeOrder,
} = productsSlice.actions;

export const IsLoadingProducts = (state: any) => {
  return state.products.isLoadingProducts;
};

export const IsLoadingExternalBilling = (state: any) => {
  return state.products.isLoadingExternalBilling;
};

export const IsLoadingExternalProducts = (state: any) => {
  return state.products.isLoadingExternalProducts;
};

export const IsShowingActives = (state: any) => {
  return state.products.isShowingActives;
};

export const selectProducts = ({ products }: { products: ProductsState }) => {
  return { list: products.list };
};

export const selectOrderCriteria = ({
  products,
}: {
  products: ProductsState;
}) => products.orderedBy;

export const selectOrderedBy = createSelector(
  [selectProducts, selectOrderCriteria],
  (product, orderCriteria) => {
    return {
      list:
        (product.list &&
          sortDataBy(
            product.list,
            orderCriteria.orderBy,
            orderCriteria.criteria,
            orderCriteria.dataType
          )) ||
        []
    };
  }
);

export default productsSlice.reducer;