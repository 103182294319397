export const getHostUrl = () => {
  let response: any = process.env.REACT_APP_MSAL_REDIRECT_URI?.replace(
    "https://",
    ""
  );
  return response.substring(0, response.length - 1);
};

export const getServiceUrl = () => {
  return process.env.REACT_APP_MSAL_REDIRECT_URI + "api/reports";
};
