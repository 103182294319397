import { currencyFormat } from "../../SharedModule/utils/formatters";
import { CheckSkeleton } from "../../SharedModule/utils/checkSkeleton";

export const TotalTile = ({
  isLoading,
  title,
  tag,
  amount,
  count
}: {
  isLoading: boolean;
  title: string;
  tag: string;
  amount: number;
  count: number;
}) => (
  <div className="p-4 flex-fill">
    <div>{title}</div>
      <CheckSkeleton 
        isLoading={isLoading} 
        classes="fs-6 py-2" 
        style={{ width: title === 'Recurring' ? '25%' : '80%'}}>
        <div className="fs-6 fw-bold py-2">{currencyFormat(amount)}</div>
      </CheckSkeleton>
      <CheckSkeleton 
        isLoading={isLoading} 
        style={{ width: title === 'Recurring' ? '25%' : '80%'}}>
        <div>
          {count} {tag}
        </div>
      </CheckSkeleton>
  </div>
);
