import {
  Account,
  Project,
  Department,
  ContactContainer
} from "../../types";
import HttpService from "../../SharedModule/utils/api";
const BASE_URL = "/products/External";

function getClients(): Promise<Account[]> {
  return HttpService.req(`${BASE_URL}/Clients`);
}

function getProjects(): Promise<Project[]> {
  return HttpService.req(`${BASE_URL}/Projects`);
}

function getDepartments(): Promise<Department[]> {
  return HttpService.req(`${BASE_URL}/Departments`);
}

function getContacts(id: number): Promise<ContactContainer> {
  return HttpService.req(`${BASE_URL}/Contacts?productId=${id}`);
}

export const ExternalService = {
  getClients,
  getProjects,
  getDepartments,
  getContacts
};