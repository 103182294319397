import { MessageIcon } from "./MessageIcon";

const UNSELECTED_ITEMS_MESSAGE =
  "Heads up. There are unselected items in this period.";

export const TabLabelContent = ({ title, selectedCount, totalCount }: any) => {
  return (
    <>
      <div className="tab-title me-2">{title}</div>
      <div>
        ({selectedCount}/{totalCount})
      </div>
      {totalCount - selectedCount > 0 ? (
        <MessageIcon message={UNSELECTED_ITEMS_MESSAGE} />
      ) : null}
    </>
  );
};
