import { createSlice } from "@reduxjs/toolkit";
import { Spotlight, Status } from "../../types/types";

type SpotlightState = {
  actualProductName: string;
  lastSpotlight: Spotlight | null;
  spotlightHistory: Spotlight[];
  isLoadingData: boolean;
  spotlightStatuses: Status[];
  isSpotlightOn: boolean;
};

const initialState: SpotlightState = {
  actualProductName: '',
  lastSpotlight: {
    productName: "",
    isSpotlightOn: false,
    isContentEditable: false,
    lastSpotlight: null
  },
  spotlightHistory: [],
  isLoadingData: true,
  spotlightStatuses: [],
  isSpotlightOn: true
};

export const spotlightSlice = createSlice({
  name: "spotlight",
  initialState,
  reducers: {
    loadSpotlightData: (state, action) => {
      state.isLoadingData = true;
      state.lastSpotlight = action.payload.lastSpotlight;
      state.spotlightHistory = action.payload.spotlightHistory;
      state.spotlightStatuses = action.payload.spotlightStatuses;
      state.isLoadingData = false;
    },
    loadLastSpotlightData: (state, action) => {
      state.isLoadingData = true;
      state.lastSpotlight = action.payload.lastSpotlight;
      state.spotlightHistory = action.payload.spotlightHistory;
      state.isLoadingData = false;
    },
    setIsLoadingData: (state, action) => {
      state.isLoadingData = action.payload;
    },
    setIsSpotlightOn: (state, action) => {
      state.isSpotlightOn = action.payload;
    },
    setActualProductName: (state, action) => {
      state.actualProductName = action.payload;
    },
    clear: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadSpotlightData,
  loadLastSpotlightData,
  setIsLoadingData,
  setIsSpotlightOn,
  setActualProductName,
  clear,
} = spotlightSlice.actions;

export const IsLoadingData = (state: any) => {
  return state.spotlight.isLoadingData;
};

export const IsSpotlightOn = (state: any) => {
  return state.spotlight.isSpotlightOn;
};

export const GetActualProductName = (state: any) => {
  return state.spotlight.actualProductName;
};

export const LastSpotlight = (state: any) => {
    return state.spotlight.lastSpotlight;
  };

export const SpotlightHistory = (state: any) => {
  return state.spotlight.spotlightHistory;
};

export const SpotlightStatuses = (state: any) => {
  return state.spotlight.spotlightStatuses;
};

export default spotlightSlice.reducer;
