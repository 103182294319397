import { configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";
import { tokenMiddleware } from "./reducers/auth.reducer";
import rootReducer from "./root.reducer";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(tokenMiddleware),
});

export const persistor = persistStore(store);