import { TelerikReport } from "../components/TelerikReport";

export const SampleReportPage = () => {
  return (
    <TelerikReport
      title="Sample Report"
      reportName="SampleReport.trdp"
      parametersAreaVisible={false}
    />
  );
};
