import { useRef, useState } from "react";
import useOnClickOutside from "../../../SharedModule/hooks/useOnClickOutside";

export enum actions {
  approve,
  approveAndViewNext,
  reset,
  void,
}

export const MoreActionsButton = ({
  disabled,
  resetInvoices,
  voidInvoices,
  sendReminders,
}: any) => {
  const [moreActionsOpen, setMoreActionsOpen] = useState(false);
  const dropdownRef: any = useRef();

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(dropdownRef, () => {
    setMoreActionsOpen(false);
  });

  return (
    <span className="mx-2 position-relative">
      <button
        className={`btn button-secondary dropdown-toggle ${
          disabled.reminder && disabled.reset && disabled.void && "disabled"
        }`}
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
        onClick={() => setMoreActionsOpen((state) => !state)}
      >
        More Actions
      </button>
      <div
        className={`dropdown-menu ${moreActionsOpen ? "show" : ""}`}
        ref={dropdownRef}
      >
        <button
          className={`dropdown-item ${disabled.reminder && "disabled"}`}
          onClick={() => {
            sendReminders();
            setMoreActionsOpen(false);
          }}
        >
          Send reminder
        </button>
        <button
          className={`dropdown-item ${disabled.reset && "disabled"}`}
          onClick={() => {
            resetInvoices();
            setMoreActionsOpen(false);
          }}
        >
          Reset
        </button>
        <button
          className={`dropdown-item ${disabled.void && "disabled"}`}
          onClick={() => {
            voidInvoices();
            setMoreActionsOpen(false);
          }}
        >
          Void
        </button>
      </div>
    </span>
  );
};
