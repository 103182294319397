import {
  EntryListView,
  FiltersToApproveBody,
  RowSheetView,
  TimeEntryApprovalTime,
  TimeEntryForm,
  TimeEntrySheetView,
} from "./../types";
import { newUuid } from "../../SharedModule/utils/uuid";
import { FiltersBody, SelectItemNumber } from "../types";
import {
  ALL_PROJECTS_AND_WORK_ORDERS,
  BAMBOO_HR,
  PROJ_MEAL_BREAK_ID,
  PROJ_NURSING_MOTHER_ID,
  PROJ_PAID_TIME_OFF_ID,
  PROJ_UNPAID_TIME_OFF_ID,
  ROLE_PROJECT_OWNER,
  ROLE_SUPERVISOR,
  ROLE_SUPER_ADMIN,
  ROLE_TIMESHEETS_ADMIN,
  TASKTYPE_HOLIDAY_ID,
  TASKTYPE_MEAL_BREAK_ID,
  TASKTYPE_NURSING_MOTHER_ID,
  TASKTYPE_UNPAID_TIME_OFF_ID,
  closed_status,
} from "../../SharedModule/utils/constants";
import { quantityFormat } from "../../SharedModule/utils/formatters";
import { MetaTimesState } from "../redux/reducers/metaTimes.reducer";
import {
  getDateFromDateString,
  getDateFromISOFormat,
  getIsoFormatStringWithTimeZone,
  isBetweenTwoDates,
  isToday,
} from "../../SharedModule/utils/dateUtils";

export const empty: SelectItemNumber = {
  value: 0,
  label: "—",
};

export const hoursTypeOptions: SelectItemNumber[] = [
  {
    value: 1,
    label: "Client Billable",
  },
  {
    value: 2,
    label: "Client Non-billable",
  },
  {
    value: 3,
    label: "Internal",
  },
];

export const emptyProjectWorkOrder: SelectItemNumber = {
  value: 0,
  label: "Select a project, task or work order you are assigned to",
};

// Param is array of roles only for time module
export const isSupervisorOrProjectOwner = (userRoles: string[]) => {
  let response: boolean = false;
  userRoles?.forEach((role: string) => {
    if (role === ROLE_SUPERVISOR || role === ROLE_PROJECT_OWNER) {
      response = true;
    }
  });
  return response;
};

// Param is array of roles only for time module
export const hasTimeAdminAccess = (userRoles: string[]) => {
  let response: boolean = false;
  userRoles?.forEach((role: string) => {
    if (
      role === ROLE_SUPER_ADMIN ||
      role === ROLE_TIMESHEETS_ADMIN ||
      role === ROLE_SUPERVISOR ||
      role === ROLE_PROJECT_OWNER
    ) {
      response = true;
    }
  });
  return response;
};

// Param is array of roles only for time module
export const hasSuperTimeAdminAccess = (userRoles: string[]) => {
  let response: boolean = false;
  userRoles?.forEach((role: string) => {
    if (role === ROLE_SUPER_ADMIN || role === ROLE_TIMESHEETS_ADMIN) {
      response = true;
    }
  });
  return response;
};

export const getEmptyTimeEntry = (day: Date) => {
  return {
    entryCanEdit: true,
    entryCanDelete: true,
    entryId: newUuid(),
    entryDate: getIsoFormatStringWithTimeZone(day),
    entryDateValid: true,
    entryProjectWorkOrder: emptyProjectWorkOrder,
    entryProjectWorkOrderValid: false,
    entryHours: "",
    entryHoursValid: false,
    entryTaskType: empty,
    entryTaskTypeValid: false,
    entryDescription: "",
    entryIsBillable: true,
    entryNonBillableReason: empty,
    entryNonBillableReasonValid: false,
    isEntryValid: false,
    isEntryTouched: false,
  };
};

export const isDateValidToEnterTime = (
  isSuperTimeAdmin,
  isSupervisorOrPO,
  activeUserId,
  actualUserValue,
  pageDate,
  previousWorkableDay,
  lastWorkableDay
) => {
  let response = false;
  // super time admin => superAdmin and TimeAdmin
  if (
    isSuperTimeAdmin ||
    // supervisor and project owner for others
    (isSupervisorOrPO && activeUserId !== actualUserValue) ||
    // supervisor and project owner for himself and common users
    (getDateFromISOFormat(
      getIsoFormatStringWithTimeZone(previousWorkableDay)
    ) <= getDateFromISOFormat(getIsoFormatStringWithTimeZone(pageDate)) &&
      pageDate < lastWorkableDay)
  ) {
    response = true;
  }

  return response;
};

export const getCategoryId = (list: any, value: number) => {
  let selected = list.find((elem) => elem.entityId === value);
  return selected.categoryId;
};

export const getProjectWorkOrder = (list: any, value: number) => {
  let selected = list.find((elem) => elem.entityId === value);
  return selected;
};

export const getValueFromSelect = (list: any, value: number | null) => {
  let selected = list?.find((elem) => elem.value === value);
  return selected;
};

export const getValueFromSelectWithLabel = (list: any, label: string) => {
  let selected = list.find((elem) => elem.label === label);
  return selected;
};

export const camelCaseToWords = (s: string) => {
  const result = s.replace(/([A-Z])/g, " $1");
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const firstLetterToLower = (word: string) => {
  return word.charAt(0).toLowerCase() + word.slice(1);
};

export const transformValue = (property: string, value: any) => {
  let response;

  switch (property) {
    case "costCenter":
    case "client":
    case "project":
    case "projectManager":
    case "user":
    case "taskType":
    case "country":
    case "nonBillableReasons":
    case "projectOwner":
    case "billableType":
      if (value === null) {
        if (property === "project") {
          response = ALL_PROJECTS_AND_WORK_ORDERS;
        } else {
          response = "All " + camelCaseToWords(property);
        }
      } else {
        value.forEach((element) => {
          if (!response) {
            response = element.label;
          } else {
            response = response + ", " + element.label;
          }
        });
      }
      break;
    case "hourType":
    case "timeOff":
    case "approvalStatus":
      if (
        property === "timeOff" &&
        !value["paidTimeOff"] &&
        !value["unpaidTimeOff"]
      ) {
        response = "None";
      } else {
        for (const prop in value) {
          if (value[prop]) {
            if (!response) {
              response = camelCaseToWords(prop);
            } else {
              response = response + ", " + camelCaseToWords(prop);
            }
          }
        }
      }
      break;
    default:
      break;
  }
  return response;
};

export const preparedFilters = (filters: any) => {
  // projectManagers
  let projectManagerInfo = [];
  if (filters.projectManager) {
    filters.projectManager.forEach((proj) => {
      projectManagerInfo = projectManagerInfo.concat(proj.projectIds);
    });
  }
  // projects
  let projectsInfo: any[] = [];
  // send null id all project and all work orders are selected
  if (
    !(
      filters.project &&
      filters.project.length === 2 &&
      filters.project.every((proj) => proj.value < 0)
    )
  ) {
    if (filters.project) {
      filters.project.forEach((proj) => {
        projectsInfo.push({
          categoryId: proj.categoryId,
          entityId: proj.entityId,
        });
      });
    }
  }
  // hourType
  let hourTypeInfo: number[] = [];
  if (filters.hourType.billableHours) hourTypeInfo.push(1);
  if (filters.hourType.nonBillableHours) hourTypeInfo.push(2);
  if (filters.hourType.internalHours) hourTypeInfo.push(3);
  // timeOff
  let timeOffInfo: number[] = [];
  if (filters.timeOff.paidTimeOff) timeOffInfo.push(1);
  if (filters.timeOff.unpaidTimeOff) timeOffInfo.push(2);
  // approvalStatus
  let approvalStatusInfo: number[] = [];
  if (filters.approvalStatus.pendingHours) approvalStatusInfo.push(1);
  if (filters.approvalStatus.approvedHours) approvalStatusInfo.push(2);
  if (filters.approvalStatus.lockedHours) approvalStatusInfo.push(3);

  let response: FiltersBody = {
    startDate: filters.startDate,
    endDate: filters.endDate,
    costCentersIds:
      filters.costCenter === null
        ? null
        : filters.costCenter.map((elem) => elem.value),
    clientIds:
      filters.client === null ? null : filters.client.map((elem) => elem.value),
    entities: projectsInfo.length > 0 ? projectsInfo : null,
    managersProjects:
      filters.projectManager === null ? null : projectManagerInfo,
    userIds:
      filters.user === null ? null : filters.user.map((elem) => elem.value),
    taskTypeIds:
      filters.taskType === null
        ? null
        : filters.taskType.map((elem) => elem.value),
    countries:
      filters.country === null
        ? null
        : filters.country.map((elem) => elem.value),
    hourTypes: hourTypeInfo.length > 0 ? hourTypeInfo : null,
    nonBillableReasonsIds:
      filters.nonBillableReasons === null
        ? null
        : filters.nonBillableReasons.map((elem) => elem.value),
    timeOff: timeOffInfo.length > 0 ? timeOffInfo : null,
    statusIds: approvalStatusInfo.length > 0 ? approvalStatusInfo : null,
  };
  return response;
};

export const navigateTo = (entry: EntryListView | TimeEntryApprovalTime) => {
  let url: string = "";
  switch (entry.categoryId) {
    case 1:
      //project
      url = `${process.env.REACT_APP_ASUITE_PROJECT_URI}${entry.entityId}`;
      break;
    case 2:
      // task
      url = `${process.env.REACT_APP_ASUITE_PROJECT_URI}${entry.projectId}`;
      break;
    default:
      // work order
      url = `${process.env.REACT_APP_ASUITE_WO_URI}${entry.entityId}`;
      break;
  }
  return url;
};

export const findEntityId = (structure: any) => {
  let responseId: number = 0;
  Object.keys(structure).forEach((key: string) => {
    if (structure[key].timeEntries.length > 0 && responseId === 0) {
      responseId = structure[key].timeEntries[0].entityId;
    }
  });
  return responseId;
};

export const navigateToFromSheetView = (entry: RowSheetView) => {
  let url: string = "";
  switch (entry.categoryId) {
    case 1:
      //project
      url = `${process.env.REACT_APP_ASUITE_PROJECT_URI}${entry.entityId}`;
      break;
    case 2:
      // task
      url = `${process.env.REACT_APP_ASUITE_PROJECT_URI}${entry.projectId}`;
      break;
    default:
      // work order
      let partialEntiryId: number = findEntityId(entry.dailyDetails);
      url = `${process.env.REACT_APP_ASUITE_WO_URI}${
        partialEntiryId ? partialEntiryId : entry.entityId
      }`;
      break;
  }
  return url;
};

// creates an object, filters by any property
export const groupByProperty = (array, property) => {
  let hash = {};

  for (let elem of array) {
    if (!hash[elem[property]]) hash[elem[property]] = [];
    hash[elem[property]].push(elem);
  }
  return hash;
};

// to avoid issue of 2 structures separated by categoryId
export const concatProjectsWorkOrders = (projectsWorkOrders) => {
  return projectsWorkOrders[0].options.concat(projectsWorkOrders[1].options);
};

const replaceKeyByCorrectName = (str) => {
  switch (str) {
    case "EntryDate":
      return "Date";
    case "Project":
      return "Project / Work order";
    case "NonBillableReason":
      return "Non-billable reason";
    case "TimeEntryType":
      return "Billable";
    case "TaskType":
      return "Task type";
    default:
      return str;
  }
};
export const formatAuditNewChanges = (oldValues: any, newValues: any) => {
  let response: string[] = [];

  for (const key in oldValues) {
    if (oldValues[key] !== newValues[key]) {
      response.push(
        `${replaceKeyByCorrectName(key)}: ${
          oldValues[key] ? oldValues[key] : "N/A"
        } → ${newValues[key] ? newValues[key] : "N/A"}`
      );
    }
  }
  return response;
};

// Sum of hours worked from diff subtask in call of project (sheet view)
export const sumHoursWorked = (dailyDetails: any[]) => {
  let sum = 0;
  dailyDetails.forEach((element) => {
    sum = sum + element.hoursWorked;
  });
  return quantityFormat(sum);
};

export const getCellClassTodayClickable = (condition: any) => {
  if (
    (typeof condition === "string" &&
      isToday(getDateFromDateString(condition))) ||
    (typeof condition === "object" && isToday(condition))
  ) {
    return "today-clickable";
  } else {
    return "time-entry-clickable";
  }
};

// To load select grouped by
export const getProjectOrWorkOrderGroupedBy = (structure: any) => {
  if (structure && structure.length > 0) {
    return [
      {
        label: "All",
        options: structure?.filter((item) => {
          if (item.categoryId < 0) {
            return item;
          }
          return null;
        }),
      },
      {
        label: "Projects",
        options: structure?.filter((item) => {
          if (item.categoryId === 1) {
            return item;
          }
          return null;
        }),
      },
    ];
  } else {
    return [];
  }
};

export const getTaskCategoryName = (value: number) => {
  switch (value) {
    case 1:
      return "Ordinary";
    case 2:
      return "Special";
    case 3:
      return "Time Off";
    default:
      return "";
  }
};

export const setTimeEntryTypeIdforRequest = (entry: TimeEntryForm) => {
  return entry.entryTaskType.value === TASKTYPE_NURSING_MOTHER_ID
    ? 4
    : entry.entryTaskType.value === TASKTYPE_UNPAID_TIME_OFF_ID ||
      entry.entryTaskType.value === TASKTYPE_MEAL_BREAK_ID
    ? 5
    : entry.entryIsBillable
    ? 1
    : 2;
};

export const checkMealBreakOrNursingMother = (dailyDetails: any[]) => {
  let response = false;
  Object.keys(dailyDetails).forEach((elem) => {
    if (dailyDetails[elem].timeEntries.length > 0) {
      response =
        dailyDetails[elem].timeEntries[0].entityId === PROJ_MEAL_BREAK_ID ||
        dailyDetails[elem].timeEntries[0].entityId === PROJ_NURSING_MOTHER_ID
          ? true
          : false;
    }
  });
  return response;
};

export const preparedFiltersToApprove = (
  filters: any,
  isSuperTimeAdmin: boolean,
  isSupervisorOrPO: boolean,
  MetaTimesInfo: any
) => {
  let managersApprovalTime, projectsApprovalTime;

  if (isSupervisorOrPO && !isSuperTimeAdmin) {
    let managersApprovalTimeInCache: any = localStorage.getItem(
      "managersApprovalTime"
    );
    let projectsApprovalTimeInCache: any = localStorage.getItem(
      "projectsApprovalTime"
    );

    managersApprovalTime = MetaTimesInfo.managersApprovalTime
      ? MetaTimesInfo.managersApprovalTime
      : JSON.parse(managersApprovalTimeInCache);
    projectsApprovalTime = MetaTimesInfo.projectsApprovalTime
      ? MetaTimesInfo.projectsApprovalTime
      : JSON.parse(projectsApprovalTimeInCache);
  }

  // projectManagers
  let projectManagerInfo = [];
  if (filters.projectOwner && filters.projectOwner.length > 0) {
    filters.projectOwner.forEach((proj) => {
      projectManagerInfo = projectManagerInfo.concat(proj.projectIds);
    });
  } else if (isSupervisorOrPO && !isSuperTimeAdmin && managersApprovalTime) {
    managersApprovalTime.forEach((proj) => {
      projectManagerInfo = projectManagerInfo.concat(proj.projectIds);
    });
  }
  // projects
  let projectsInfo: any[] = [];
  // send null id all project and all work orders are selected
  if (filters.project && filters.project.length > 0) {
    filters.project.forEach((proj) => {
      projectsInfo.push({
        categoryId: proj.categoryId,
        entityId: proj.value,
      });
    });
  } else if (isSupervisorOrPO && !isSuperTimeAdmin && projectsApprovalTime) {
    projectsApprovalTime.forEach((proj) => {
      projectsInfo.push({
        categoryId: proj.categoryId,
        entityId: proj.value,
      });
    });
  }

  let response: FiltersToApproveBody = {
    endDate: filters.endDate,
    costCentersIds:
      filters.costCenter === null || filters.costCenter.length === 0
        ? null
        : filters.costCenter.map((elem) => elem.value),
    entities: projectsInfo.length > 0 ? projectsInfo : null,
    managersProjects:
      filters.projectOwner === null || filters.projectOwner.length === 0
        ? isSupervisorOrPO && !isSuperTimeAdmin
          ? projectManagerInfo
          : null
        : projectManagerInfo,
    hourTypes:
      filters.billableType === null || filters.billableType.length === 0
        ? null
        : filters.billableType.map((elem) => elem.value),
  };
  return response;
};

// This function replace property "isLoaded" from times recuder
// TODO: review prop "isLoaded" in others meta reducers
export const checkDataIsLoaded = (
  state: MetaTimesState,
  isTimeAdmin: boolean
) => {
  return !!(
    state.userAssignees &&
    state.taskTypes &&
    state.nonBillableReasons &&
    state.costCenters &&
    state.clients &&
    state.timeProjects &&
    state.projectManagers &&
    state.timeUsers &&
    state.countries &&
    (!isTimeAdmin ||
      (isTimeAdmin &&
        state.costCentersApprovalTime &&
        state.projectsApprovalTime &&
        state.managersApprovalTime))
  );
};

export const isEntryTimeOff = (
  day: RowSheetView | EntryListView | TimeEntrySheetView
) => {
  return (
    day.entityId === PROJ_PAID_TIME_OFF_ID ||
    day.entityId === PROJ_UNPAID_TIME_OFF_ID
  );
};

export const isRegularTime = (
  entry: EntryListView | TimeEntrySheetView | TimeEntryApprovalTime
) => {
  return (
    entry.entityId !== PROJ_PAID_TIME_OFF_ID &&
    entry.entityId !== PROJ_UNPAID_TIME_OFF_ID &&
    entry.entityId !== PROJ_NURSING_MOTHER_ID &&
    entry.entityId !== PROJ_MEAL_BREAK_ID
  );
};

export const isPaidTimeOff = (
  entry: EntryListView | TimeEntrySheetView | TimeEntryApprovalTime
) => {
  return entry.entityId === PROJ_PAID_TIME_OFF_ID;
};

export const isUnpaidTimeOff = (
  entry: EntryListView | TimeEntrySheetView | TimeEntryApprovalTime
) => {
  return (
    entry.entityId === PROJ_NURSING_MOTHER_ID ||
    entry.entityId === PROJ_MEAL_BREAK_ID ||
    entry.entityId === PROJ_UNPAID_TIME_OFF_ID
  );
};

export const isNursingOrMealBreak = (
  entry: EntryListView | TimeEntrySheetView | TimeEntryApprovalTime
) => {
  return (
    entry.entityId === PROJ_NURSING_MOTHER_ID ||
    entry.entityId === PROJ_MEAL_BREAK_ID ||
    (entry.entityId === PROJ_UNPAID_TIME_OFF_ID &&
      entry.taskTypeId === TASKTYPE_NURSING_MOTHER_ID) ||
    (entry.entityId === PROJ_UNPAID_TIME_OFF_ID &&
      entry.taskTypeId === TASKTYPE_MEAL_BREAK_ID)
  );
};

export const getCorrectHoursType = (hoursType: string) => {
  switch (hoursType) {
    case "billableHours":
      return "Billable Hours Only";
    case "nonBillableHours":
      return "Non Billable Hours Only";
    case "internalHours":
      return "Internal Hours Only";
    case "workOrderHours":
      return "Work Order Hours Only";
    case "pendingHours":
      return "All Pending Hours";
    default:
      break;
  }
};

export const isObjectEmpty = (objectName) => {
  return (
    Object.keys(objectName).length === 0 && objectName.constructor === Object
  );
};

export const sendNullIfEmpty = (actualArray: any[]) => {
  return actualArray.length > 0 ? actualArray : null;
};

// ASUITE11-2477: Changing a holiday (task type) - only from bamboo super admin and for others
export const canEditHolidayFromBamboo = (
  entry: EntryListView | TimeEntryApprovalTime,
  activeUserId: any,
  userId: any
): boolean => {
  return (
    entry.timeEntrySourceId === 2 &&
    entry.source === BAMBOO_HR &&
    entry.taskTypeId === TASKTYPE_HOLIDAY_ID &&
    activeUserId !== userId
  );
};

// ASUITE11-2512
export const isClosed = (status: string) => {
  return closed_status.includes(status);
};

// ASUITE11-2573
export const isProjectClosed = (
  entry: EntryListView | TimeEntryApprovalTime | TimeEntrySheetView
) => {
  let response: boolean = false;
  // for WO (categoryId === 3) => verify if projectID !== null before check projectStatus
  if (entry.categoryId === 3 && entry.projectId && entry.projectStatus) {
    response = isClosed(entry.projectStatus);
  } else {
    response = false;
  }
  if (entry.categoryId !== 3 && entry.projectStatus) {
    response = isClosed(entry.projectStatus);
  }

  return response;
};

// TimesPage - FOR LIST VIEW ONLY
// QueryResultsPage and ApproveTimeByUserPage - SHOULD BE EQUAL TO TIMES PAGES, EXCEPT TO COMPARE EACH ENTRY USER WITH ACTUAL USER
export const iconEditEnabled = (
  entry: EntryListView | TimeEntryApprovalTime,
  activeUserId: any,
  userId: any,
  isSuperTimeAdmin: boolean,
  isSupervisorOrPO: boolean,
  previousWorkableDay: Date | null,
  today: Date | null,
  lastWorkableDay: Date | null
) => {
  let response: boolean = false;
  let isImportedFromChangeGear: boolean = entry.timeEntrySourceId === 3;
  let isImportedFromBambooNotHoliday: boolean =
    entry.timeEntrySourceId === 2 &&
    entry.source === BAMBOO_HR &&
    entry.taskTypeId !== TASKTYPE_HOLIDAY_ID;
  let isTimeEntryLocked: boolean = entry.timeEntryStatusId === 3;

  // FOR HIMSELF
  if (
    activeUserId === userId &&
    !isImportedFromChangeGear &&
    !isImportedFromBambooNotHoliday &&
    !isTimeEntryLocked &&
    !isClosed(entry.entityStatus) &&
    !isProjectClosed(entry)
  ) {
    // for super admis
    if (isSuperTimeAdmin) {
      if (
        // unpaid time off
        isNursingOrMealBreak(entry) &&
        (entry.timeEntryStatusId === 1 || entry.timeEntryStatusId === 2)
      ) {
        response = true;
      }
      // regular hours
      if (
        isRegularTime(entry) &&
        (entry.timeEntryStatusId === 1 || entry.timeEntryStatusId === 2)
      )
        response = true;
      // supervisor or project owners
    } else if (isSupervisorOrPO) {
      if (
        // unpaid time off
        isNursingOrMealBreak(entry) &&
        entry.timeEntryStatusId === 1 &&
        isBetweenTwoDates(entry.entryDate, previousWorkableDay, today)
      )
        response = true;
      // regular hours
      if (
        isRegularTime(entry) &&
        entry.canEdit &&
        entry.timeEntryStatusId === 1 &&
        isBetweenTwoDates(entry.entryDate, null, lastWorkableDay)
      )
        response = true;
    } else {
      // for common users
      if (
        // unpaid time off
        isNursingOrMealBreak(entry) &&
        entry.timeEntryStatusId === 1 &&
        isBetweenTwoDates(entry.entryDate, previousWorkableDay, today)
      )
        response = true;
      // regular hours
      if (
        isRegularTime(entry) &&
        entry.canEdit &&
        entry.timeEntryStatusId === 1 &&
        isBetweenTwoDates(entry.entryDate, null, lastWorkableDay)
      )
        response = true;
    }
  } else if (
    !isImportedFromChangeGear &&
    !isTimeEntryLocked &&
    !isImportedFromBambooNotHoliday &&
    !isClosed(entry.entityStatus) &&
    !isProjectClosed(entry)
  ) {
    // FOR OTHERS
    if (isSuperTimeAdmin) {
      if (canEditHolidayFromBamboo(entry, activeUserId, userId))
        response = true;
      if (isUnpaidTimeOff(entry)) response = true;
      if (isPaidTimeOff(entry) && entry.timeEntrySourceId === 1)
        response = true;
      // regular hours
      if (
        isRegularTime(entry) &&
        (entry.timeEntryStatusId === 1 || entry.timeEntryStatusId === 2)
      )
        response = true;
      // supervisor or project owners
    } else if (isSupervisorOrPO) {
      if (
        // unpaid time off
        isNursingOrMealBreak(entry) &&
        entry.timeEntryStatusId === 1 &&
        isBetweenTwoDates(entry.entryDate, previousWorkableDay, today)
      )
        response = true;
      // regular hours
      if (
        isRegularTime(entry) &&
        entry.canEdit &&
        entry.timeEntryStatusId === 1
      )
        response = true;
    }
  }

  return isImportedFromChangeGear || isTimeEntryLocked ? false : response;
};

// TimesPage - FOR LIST VIEW ONLY
// QueryResultsPage and ApproveTimeByUserPage - SHOULD BE EQUAL TO TIMES PAGES, EXCEPT TO COMPARE EACH ENTRY USER WITH ACTUAL USER
// EntryHoursBox - ON DELETE ON MULTIPLE TIME ENTRIES = type of first param: TimeEntrySheetView
export const iconDeleteEnabled = (
  entry: EntryListView | TimeEntrySheetView,
  activeUserId: any,
  userId: any,
  isSuperTimeAdmin: boolean,
  isSupervisorOrPO: boolean,
  previousWorkableDay: Date | null,
  today: Date | null,
  lastWorkableDay: Date | null
) => {
  let response: boolean = false;
  let isImportedFromChangeGear: boolean = entry.timeEntrySourceId === 3;
  let isImportedFromBambooNotHoliday: boolean =
    entry.timeEntrySourceId === 2 &&
    entry.source === BAMBOO_HR &&
    entry.taskTypeId !== TASKTYPE_HOLIDAY_ID;
  let isTimeEntryLocked: boolean = entry.timeEntryStatusId === 3;

  // FOR HIMSELF
  if (
    activeUserId === userId &&
    !isImportedFromChangeGear &&
    !isImportedFromBambooNotHoliday &&
    !isTimeEntryLocked &&
    !isClosed(entry.entityStatus) &&
    !isProjectClosed(entry)
  ) {
    // for super admis
    if (isSuperTimeAdmin) {
      if (
        isNursingOrMealBreak(entry) &&
        (entry.timeEntryStatusId === 1 || entry.timeEntryStatusId === 2)
      ) {
        response = true;
      }
      // regular hours
      if (
        isRegularTime(entry) &&
        (entry.timeEntryStatusId === 1 || entry.timeEntryStatusId === 2)
      )
        response = true;
      // supervisor or project owners
    } else if (isSupervisorOrPO) {
      if (
        isNursingOrMealBreak(entry) &&
        entry.timeEntryStatusId === 1 &&
        isBetweenTwoDates(entry.entryDate, previousWorkableDay, today)
      )
        response = true;
      // regular hours
      if (
        isRegularTime(entry) &&
        entry.canDelete &&
        entry.timeEntryStatusId === 1 &&
        isBetweenTwoDates(entry.entryDate, null, lastWorkableDay)
      )
        response = true;
    } else {
      // for common users
      if (
        isNursingOrMealBreak(entry) &&
        entry.timeEntryStatusId === 1 &&
        isBetweenTwoDates(entry.entryDate, previousWorkableDay, today)
      )
        response = true;
      if (
        isRegularTime(entry) &&
        entry.canDelete &&
        entry.timeEntryStatusId === 1 &&
        isBetweenTwoDates(entry.entryDate, null, lastWorkableDay)
      )
        response = true;
    }
  } else if (
    !isImportedFromChangeGear &&
    !isImportedFromBambooNotHoliday &&
    !isTimeEntryLocked &&
    !isClosed(entry.entityStatus) &&
    !isProjectClosed(entry)
  ) {
    // FOR OTHERS
    if (isSuperTimeAdmin) {
      if (isUnpaidTimeOff(entry)) response = true;
      if (isPaidTimeOff(entry) && entry.timeEntrySourceId === 1)
        response = true;
      // regular hours
      if (
        isRegularTime(entry) &&
        (entry.timeEntryStatusId === 1 || entry.timeEntryStatusId === 2)
      )
        response = true;
      // supervisor or project owners
    } else if (isSupervisorOrPO) {
      if (
        isNursingOrMealBreak(entry) &&
        entry.timeEntryStatusId === 1 &&
        isBetweenTwoDates(entry.entryDate, previousWorkableDay, today)
      )
        response = true;
      // regular hours
      if (
        isRegularTime(entry) &&
        entry.canDelete &&
        entry.timeEntryStatusId === 1
      )
        response = true;
    }
  }

  return isImportedFromChangeGear || isTimeEntryLocked ? false : response;
};
