import {
  ROLE_NOT_OWNERS,
  ROUTE_INVOICES_LIST,
  ROUTE_PROFFESSIONAL_SERVICES_QUERY,
  ROUTE_RECURRING_QUERY,
} from "../../SharedModule/utils/constants";
import { LeftNavBar } from "../../SharedModule/components/LeftNavBar";
import { LeftNavBarSection } from "../../SharedModule/components/LeftNavBarSection";

export const LeftNavBarBilling = () => {
  return (
    <LeftNavBar>
      <LeftNavBarSection
        mainTitle="Manage Billing"
        biIconName="BiMoneyWithdraw"
        asuiteAccess={false}
        asuiteRolesAccess={[]}
        theHubAccess={true}
        role={ROLE_NOT_OWNERS}
        listOfLinks={[
          {
            text: "Professional Services",
            route: ROUTE_PROFFESSIONAL_SERVICES_QUERY,
            theHubAccess: true,
            role: ROLE_NOT_OWNERS,
            asuiteAccess: false,
            asuiteRolesAccess: [],
          },
          {
            text: "Recurring Billing",
            route: ROUTE_RECURRING_QUERY,
            theHubAccess: true,
            role: ROLE_NOT_OWNERS,
            asuiteAccess: false,
            asuiteRolesAccess: [],
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Reports"
        biIconName="BiBarChartAlt2"
        listOfLinks={[
          { text: "Invoices", route: ROUTE_INVOICES_LIST },
          {
            text: "Billing Reports",
            route: "/billing/billingreports.asp",
            asuiteLink: true,
          },
          {
            text: "View Price Levels",
            route: "/billing/billpricelevels.asp",
            asuiteLink: true,
          },
        ]}
      />
      <LeftNavBarSection
        mainTitle="Administration"
        biIconName="BiShieldQuarter"
        listOfLinks={[
          {
            text: "Administrative Functions",
            route: "/billing/billingmenu.asp",
            asuiteLink: true,
          },
          {
            text: "Edit Recurring Biling Items",
            route: "/billing/AdminBillingRecurring.asp",
            asuiteLink: true,
          },
          {
            text: "Pay Commissions",
            route: "/billing/BillPayCommissionsSelect.asp",
            asuiteLink: true,
          },
        ]}
      />
    </LeftNavBar>
  );
};
