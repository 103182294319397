import { useDispatch } from "react-redux";
import { BillingService } from "../services/billing";
import { Invoice } from "../../types";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";

export const useSendReminder = (
  showModal: (options: any) => Promise<void>,
  closeModal: () => void,
  callback: Function,
  invoices: (Invoice & { selected?: boolean })[] | null
) => {
  const dispatch = useDispatch();
  return async (id?: string) => {
    if (!invoices) return;

    const selectedElements = invoices.filter((invoice) => {
      return id ? (invoice.id as unknown as string) === id : invoice.selected;
    });

    const modalOptions = {
      title: `Send Reminder?`,
      text: `Are you sure you want to send a reminder to the owner(s) of the invoice(s)?`,
      btnLabel: `Yes, send reminder`,
    };

    await showModal(modalOptions);

    const invoicesToNotify = selectedElements.map((invoice) => ({
      id: invoice.id,
      date: invoice.date,
      amountWithTaxes: invoice.amountWithTaxes,
      billingGroup: invoice.billingGroup,
      account: invoice.account,
      owner: invoice.owner,
      createdAt: invoice.createdAt,
      updatedAt: invoice.updatedAt
    }));

    closeModal();

    try {
      const response = await BillingService.sendReminder(invoicesToNotify);
      if (response === "Reminder Notifications sent successfully.") {
        const toastText = `Reminder sent.`;
        dispatch(addToast({ mode: "success", message: toastText }));
        callback();
      } else {
        dispatch(addToast({ mode: "error", message: "Something went wrong" }));
      }
    } catch (e) {
      dispatch(addToast({ mode: "error", message: "Something went wrong" }));
    }
  };
};
