import React from "react";

interface CheckboxProps {
  label: string;
  value: boolean;
  onClickHandler: () => void;
  className?: string;
  isDisabled?: boolean;
}

export const Checkbox = ({
  label,
  value,
  onClickHandler,
  className,
  isDisabled,
}: CheckboxProps) => {
  return (
    <>
      <div className={`form-check ${className ? className : ""}`}>
        <input
          className="form-check-input"
          id={label}
          type="checkbox"
          checked={value}
          onChange={onClickHandler}
          disabled={isDisabled}
        />
        <label className="form-check-label" htmlFor={label}>
          {label}
        </label>
      </div>
    </>
  );
};
