import { useDispatch } from "react-redux";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { BillingService } from "../services/billing";

export type deleteCommentPayload = {
  commentId: string;
};

export const useDeleteComment = (
  showModal: (options: any) => Promise<void>,
  closeModal: () => void,
  loadInvoice: Function
) => {
  const dispatch = useDispatch();
  const deleteComment = async (payload: deleteCommentPayload) => {
    const options = {
        title: "Delete Comment?",
        text: "Are you sure you want to delete this comment?",
        btnLabel: "Yes, delete comment"
    };
    await showModal(options);
    closeModal();
    // confirmation response: "Comment deleted successfully."
    const commentResponse = await BillingService.deleteComment(payload.commentId);
    if (commentResponse === "Comment deleted successfully.") {
        dispatch(addToast({ mode: "success", message: "Comment deleted" }));
        loadInvoice();
      } else {
        dispatch(addToast({ mode: "error", message: "Something went wrong" }));
      }
  };

  return deleteComment;
};
