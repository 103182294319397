import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import {
  dateFormatWithHours,
  dateFormatLocal,
} from "../../SharedModule/utils/formatters";
import { IterableQuill } from "./IterableQuill";
import { getStatusStyle } from "../utils/spotlightUtils";
import Footer from "../../SharedModule/components/Footer";

function SpotlightItems({ currentItems }: any) {
  return (
    <>
      <table className="table grey-table-header-background mt-5">
        <thead className="align-middle sticky sticky-header">
          <tr>
            <th className="border-dark border-top ps-3">Date/Time</th>
            <th className="border-dark border-top ps-3">User</th>
            <th className="border-dark border-top ps-3">Status</th>
            <th className="border-dark border-top ps-3">Comments</th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((spotlight: any) => {
              return (
                <tr key={spotlight.lastSpotlight.id}>
                  <td className="ps-3 py-4" style={{ width: "15%" }}>
                    {dateFormatWithHours(spotlight.lastSpotlight.createdAt)}
                  </td>
                  <td className="ps-3 py-4" style={{ width: "15%" }}>
                    {spotlight.lastSpotlight.fullName}
                  </td>
                  <td className="ps-3 py-3" style={{ width: "20%" }}>
                    <div
                      className={`status-style ${getStatusStyle(
                        spotlight.lastSpotlight.status.id
                      )}`}
                    >
                      {spotlight.lastSpotlight.status.name}
                    </div>
                  </td>
                  <td className="p-3" style={{ width: "50%" }}>
                    <IterableQuill value={spotlight.lastSpotlight.comment} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

function ConfidenceItems({ currentItems }: any) {
  return (
    <>
      <table className="table grey-table-header-background mt-5">
        <thead className="align-middle sticky sticky-header">
          <tr>
            <th
              style={{ width: "15%", paddingLeft: '2.5vw' }}
              className="border-dark border-top"
            >
              Confidence Level
            </th>
            <th
              style={{ width: "45%" }}
              className="border-dark border-top p-3"
            >
              Client Comment
            </th>
            <th
              style={{ width: "20%" }}
              className="border-dark border-top p-3"
            >
              Last Verified
            </th>
            <th
              style={{ width: "20%" }}
              className="border-dark border-top p-3"
            >
              Last Updated
            </th>
          </tr>
        </thead>
        <tbody>
          {currentItems &&
            currentItems.map((stakeholderConfidence: any) => {
              return (
                <tr key={stakeholderConfidence.id}>
                  <td className="p-3 text-center">
                    <span className="key-contact-role-block">{stakeholderConfidence.confidenceLevel}</span>
                  </td>
                  <td className="p-3">
                    {stakeholderConfidence.clientComment}
                  </td>
                  <td className="p-3">
                    {dateFormatLocal(stakeholderConfidence.lastVerified)}<br/>
                    By <span style={{fontWeight: '600'}}>{stakeholderConfidence.lastVerifiedBy}</span>
                  </td>
                  <td className="p-3">
                    {dateFormatWithHours(stakeholderConfidence.lastUpdate)}<br/>
                    By <span style={{fontWeight: '600'}}>{stakeholderConfidence.lastUpdatedBy}</span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}

export const PaginatedItems = ({ itemsPerPage, items, type }: any) => {
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(items);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
    
    // keep this code, because is not a change of url, just a new page
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 100);
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  return (
    <>
      {type === "spotlight" && <SpotlightItems currentItems={currentItems} />}
      {type === "stackholder" && (
        <ConfidenceItems currentItems={currentItems} />
      )}
      <div className="pagination">
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< previous"
          marginPagesDisplayed={2}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
        />
      </div>
      <Footer />
    </>
  );
};
