import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { sortDataBy } from "../../../SharedModule/utils/dataSort";
import { KeyContact } from "../../types/types";

type KeyContactListState = {
  actualProductName: string;
  keyContactList: KeyContact[] | null | undefined;
  isLoadingData: boolean;
  isContentEditable: boolean;
  isProductActive: boolean;
  orderedBy: {
    orderBy: string;
    criteria: "asc" | "desc";
    dataType?: "string" | "date" | "number";
  };
};

const initialState: KeyContactListState = {
  actualProductName: "",
  keyContactList: null,
  isLoadingData: true,
  isContentEditable: false,
  isProductActive: false,
  orderedBy: {
    orderBy: "contactName",
    criteria: "asc",
    dataType: "string"
  },
};

export const keyContactListSlice = createSlice({
  name: "keyContacts",
  initialState,
  reducers: {
    loadKeyContactListData: (state, action) => {
      state.keyContactList = action.payload.keyContactList;
      state.isLoadingData = false;
    },
    setIsLoadingData: (state, action) => {
      state.isLoadingData = action.payload;
    },
    setActualProductName: (state, action) => {
      state.actualProductName = action.payload;
    },
    setIsContentEditable: (state, action) => {
      state.isContentEditable = action.payload;
    },
    setIsProductActive: (state, action) => {
      state.isProductActive = action.payload;
    },
    clear: (state) => {
      state = initialState;
    },
    changeOrder: (
      state,
      action: PayloadAction<{
        orderBy: string;
        dataType?: "string" | "date" | "number";
        criteria?: "asc" | "desc";
      }>
    ) => {
      const newOrderedBy: any = {
        ...action.payload,
        criteria: action.payload.criteria ? action.payload.criteria : "asc",
      };
      if (newOrderedBy.orderBy === state.orderedBy.orderBy) {
        newOrderedBy.criteria = action.payload.criteria ? action.payload.criteria :
          state.orderedBy.criteria === "asc" ? "desc" : "asc";
      }
      return { ...state, orderedBy: newOrderedBy };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadKeyContactListData,
  setIsLoadingData,
  setActualProductName,
  setIsContentEditable,
  setIsProductActive,
  clear,
  changeOrder,
} = keyContactListSlice.actions;

export const selectContactList = ({
  keyContacts,
}: {
  keyContacts: KeyContactListState;
}) => {
  return { keyContactList: keyContacts.keyContactList };
};

export const IsLoadingData = (state: any) => {
  return state.keyContacts.isLoadingData;
};

export const GetActualProductName = (state: any) => {
  return state.keyContacts.actualProductName;
};

export const GetIsContentEditable = (state: any) => {
  return state.keyContacts.isContentEditable;
};

export const GetIsProductActive = (state: any) => {
  return state.keyContacts.isProductActive;
};

export const GetKeyContactList = (state: any) => {
  return state.keyContacts.keyContactList;
};

export const selectOrderCriteria = ({
  keyContacts,
}: {
  keyContacts: KeyContactListState;
}) => keyContacts.orderedBy;

export const selectOrderedBy = createSelector(
  [selectContactList, selectOrderCriteria],
  (keyContacts, orderCriteria) => {
    return {
      keyContactList:
        (keyContacts.keyContactList &&
          keyContacts.keyContactList.length > 0 &&
          sortDataBy(
            keyContacts.keyContactList,
            orderCriteria.orderBy,
            orderCriteria.criteria,
            orderCriteria.dataType
          )) ||
        null,
    };
  }
);

export default keyContactListSlice.reducer;
