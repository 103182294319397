import React from "react";

const base = {
  display: "inline-block",
  width: "8px",
  height: "8px",
  margin: "0",
  border: "solid 1px",
  borderColor: "#6656c1",
  borderBottom: 0,
  borderLeft: 0,
};

const leftArrow = {
  transform: "translate(2px,0) rotate(225deg)",
};

const rightArrow = {
  transform: "translate(-2px,0) rotate(45deg)",
};

export const Arrow = ({ type, color }: any) => {
  let arrowType;
  if (type === "left") {
    arrowType = leftArrow;
  } else {
    arrowType = rightArrow;
  }
  return (
    <div
      style={{ ...base, ...arrowType, borderColor: color || base.borderColor }}
    ></div>
  );
};
