import { useState } from 'react';
import { useDispatch } from "react-redux";
import { addToast } from "../../../SharedModule/redux/reducers/ui.reducer";
import { ReactComponent as DeleteIcon } from "../../../SharedModule/svg/ic-delete.svg";
import { ActivityCommentType } from "../../../types";
import { activityLogFormat, dateFormatWithHours } from "../../../SharedModule/utils/formatters";
import { BillingService } from "../../../BillingModule/services/billing";
import { useIsAllowed } from "../../../SharedModule/components/AccessControl";
import { ROLE_SUPER_ADMIN } from "../../../SharedModule/utils/constants";
import { useModal } from "../../../SharedModule/hooks/useModal";
import { useDeleteComment } from '../../hooks/useDeleteComment';

export const ActivityAndComments = (
  { activityLogs, invoiceId, loadInvoice} : 
  { activityLogs:Array<ActivityCommentType>, invoiceId:string, loadInvoice: Function }) => {

  const { showModal, closeModal, Modal, ...modalProps } = useModal();
  
  const dispatch = useDispatch();
  const { activeUserId, isAllowed } = useIsAllowed(ROLE_SUPER_ADMIN);
  
  const [comment, setComment] = useState('');
  
  const handleChange = (comment: string) => {
    setComment(comment);
  }
  
  const saveComment = async() => {
    const commentResponse = await BillingService.addCommentToInvoice(invoiceId, comment);
    if (commentResponse === "Comment added to Invoice successfully.") {
      const toastText = `Comment added`;
      dispatch(addToast({ mode: "success", message: toastText }));
      setComment('');
      loadInvoice();
    } else {
      dispatch(addToast({ mode: "error", message: "Something went wrong" }));
    }
  }
  
  const deleteComment = useDeleteComment(showModal, closeModal, loadInvoice);

  return (
    <>
      <Modal closeModal={closeModal} {...modalProps} />
      <div className="px-5 py-4" id="activity-section">
        <h4 className="fs-6 pb-3 fw-bold border-bottom">Activity & Comments</h4>
        <div className="border-bottom py-2">
          <input
            type="text"
            className="form-control form-control-sm mb-3"
            placeholder="Write a comment…"
            value={ comment }
            onChange={(event) => handleChange(event.target.value)}
            />
          <button 
            className="btn btn-primary mb-2"
            disabled={comment.length === 0}
            onClick={() => saveComment()}>Add Comment</button>
        </div>
        { activityLogs.filter((log: ActivityCommentType) => {
          return log.activityType !== "InvoiceQBNumberChanged" && log.activityType !== "InvoiceApproverChanged"
        })
        .map((log: ActivityCommentType) => {
          return (
            <div 
            key={ log.id }
            className="d-flex border-bottom py-3 comment-line">
                <div className="w-100">
                  {log.activityType !== "InvoiceStatusChanged" && <span className="fw-bold">{ activityLogFormat(log.activityType) }</span>}
                  {log.activityType === "InvoiceCommentAdded" && <span> ({ log.comment })</span>}
                  {log.activityType === "InvoiceOwnerChanged" && <span> (From: { log.oldValues } - To: {log.newValues})</span>}
                  {log.activityType === "InvoiceStatusChanged" && <span className="fw-bold">{ activityLogFormat(log.newValues) }</span>}
                  <div className="text-muted">
                    {`${ log.userName } on ${ dateFormatWithHours(log.createdDate) } (ET).`}
                  </div>
                </div>
                <div className="flex-shrink-1 d-flex align-items-center me-2">
                  { log.activityType === "InvoiceCommentAdded" && (isAllowed || activeUserId === log.userId) && <DeleteIcon 
                    className="align-self-center show-on-hover pointer"
                    onClick={() => deleteComment({commentId: log.id})}/>
                  }
                </div>
              </div>
            );
          })}
      </div>
    </>
  )
};
