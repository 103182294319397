import { useDispatch } from "react-redux";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { KeyContactsService } from "../services/keyContacts";
import eventHandler from "../../SharedModule/utils/eventHandler";

export type deleteKeyContactPayload = {
  productId: number;
  keyContactId: number;
  name: string;
};

export const useDeleteKeyContact = (
  showModal: (options: any) => Promise<void>,
  closeModal: () => void
) => {
  const dispatch = useDispatch();
  const deleteProduct = async (payload: deleteKeyContactPayload) => {
    const options = {
      title: "Delete Key Contact?",
      text: `Are you sure you want to remove ${payload.name}? This will not delete the contact from Business Development.`,
      btnLabel: "Yes, delete key contact.",
    };
    await showModal(options);
    closeModal();

    let result = await KeyContactsService.deleteKeyContact(
      payload.productId,
      payload.keyContactId
    );
    if (result === "Key Contact Deleted Successfully") {
      dispatch(addToast({ mode: "success", message: result }));
      eventHandler.dispatch("toast action", { isClosed: false });
      return true;
    } else {
      dispatch(addToast({ mode: "error", message: "Something went wrong" }));
      eventHandler.dispatch("toast action", { isClosed: false });
      return false;
    }  
  };

  return deleteProduct;
};
