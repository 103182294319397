import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetActualProductName,
  IsLoadingData,
  GetSuccessCriteriaList,
  loadSuccessCriteriaListData,
  setActualProductName,
  setIsContentEditable,
  GetIsContentEditable,
  SuccessCriteriaStatuses,
  GetIsProductActive,
  setIsProductActive,
} from "../redux/reducers/successCriteria.reducer";
import { SuccessCriteriaService } from "../services/successCriteria";

export const useSuccessCriteria = (productId: number) => {
  const dispatch = useDispatch();
  const actualProductName = useSelector(GetActualProductName);
  const isContentEditable = useSelector(GetIsContentEditable);
  const isProductActive = useSelector(GetIsProductActive);
  const isLoadingData = useSelector(IsLoadingData);
  const successCriteriaList = useSelector(GetSuccessCriteriaList);
  const successCriteriaStatuses = useSelector(SuccessCriteriaStatuses);

  const loadSuccessCriteriaInfo = useCallback(async () => {
    const successCriteriaData =
      await SuccessCriteriaService.getSuccessCriteriaList(productId)
        .then((data) => {
          return data;
        })
        .catch((error) => {
          return error.response || error.message;
        });

    const successCriteriaStatuses =
      await SuccessCriteriaService.getSuccessCriteriaStatus();

    let action: any = {
      successCriteriaList: successCriteriaData.data.errors ? successCriteriaData.data : successCriteriaData.data.successCriteriaList,
      successCriteriaStatuses: successCriteriaStatuses,
    };
    dispatch(loadSuccessCriteriaListData(action));

    dispatch(setActualProductName(successCriteriaData.data.productName));

    dispatch(setIsContentEditable(successCriteriaData.data.isContentEditable));

    dispatch(setIsProductActive(successCriteriaData.data.isProductActive));
  }, [dispatch, productId]);

  useEffect(() => {
    loadSuccessCriteriaInfo();
  }, []);

  return {
    actualProductName,
    isContentEditable,
    isProductActive,
    isLoadingData,
    successCriteriaList,
    successCriteriaStatuses,
    loadSuccessCriteriaInfo,
  };
};
