import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../../SharedModule/redux/reducers/auth.reducer";
import { getHostUrl } from "../utils/reportsUtils";
import { TelerikReport } from "../../SharedModule/components/TelerikReport";
import { useEffect } from "react";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { ROUTE_PRODUCT_RELATIONSHIP_UPDATES_REPORT } from "../../SharedModule/utils/constants";

export const RelationshipUpdatesReportPage = () => {
  const auth = useSelector(selectAuth);
  let parameters = {
    HostUrl: getHostUrl(),
    Auth: auth.accessToken,
  };
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "The Hub - Relationship Updates Report";
    dispatch(setActiveTab(ROUTE_PRODUCT_RELATIONSHIP_UPDATES_REPORT));
  }, []);

  return (
    <TelerikReport
      title="Relationship Updates Report"
      reportName="KeyContactsRelationshipUpdates.trdp"
      parameters={parameters}
      parametersAreaVisible={false}
    />
  );
};
