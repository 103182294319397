import advocacy from './advocacy-level-texts.json';

export function setAdvocacyDescription(level: number): string {
  // ASUITE-2107: N/A in Database has a new id equal to 5 to allow save
  if (level === 5){
    return advocacy.advocacy[0].description;
  }
  return advocacy.advocacy[level].description;
}

export function setAdvocacyTexts(level: number): Array<string> {
  // ASUITE-2107: N/A in Database has a new id equal to 5 to allow save
  if (level === 5){
    return advocacy.advocacy[0].texts;
  }
  return advocacy.advocacy[level].texts;
}
