import { quantityFormat } from "../../SharedModule/utils/formatters";
import { isSameDay } from "../../SharedModule/utils/dateUtils";
import { EntryListView } from "../types";

interface TypesDayTotals {
  timeEntries?: any;
  actualDay?: any;
  daySummary?: any;
}

export const DayTotals = ({
  timeEntries,
  actualDay,
  daySummary,
}: TypesDayTotals) => {
  // if daySummary => from QueryResultsPage
  let billable = daySummary ? daySummary.billable : 0;
  let nonBillable = daySummary ? daySummary.nonBillable : 0;
  let internal = daySummary ? daySummary.internal : 0;
  let timeOff = daySummary ? daySummary.timeOff : 0;
  let total = daySummary ? daySummary.total : 0;

  // if timeEntries => from TimesPage
  if (timeEntries) {
    timeEntries
      .filter((entry) => isSameDay(entry.entryDate, actualDay))
      .forEach((entry: EntryListView) => {
        switch (
          entry.timeEntryType.toLocaleLowerCase()
        ) {
          case "billable":
            billable += entry.hours;
            total += entry.hours;
            break;
          case "nonbillable":
            nonBillable += entry.hours;
            total += entry.hours;
            break;
          case "internal":
            internal += entry.hours;
            total += entry.hours;
            break;
          case "timeoff":
            timeOff += entry.hours;
            total += entry.hours;
            break;
          default:
            break;
        }
      });
  }

  return (
    <div className="right-side d-flex justify-content-between align-items-center">
      <div className="grey-text">Billable: {quantityFormat(billable)}</div>
      <div className="grey-text">
        Non-Billable: {quantityFormat(nonBillable)}
      </div>
      <div className="grey-text">Internal: {quantityFormat(internal)}</div>
      <div className="grey-text">Time Off: {quantityFormat(timeOff)}</div>
      <div className="totals-text">Total: {quantityFormat(total)}</div>
    </div>
  );
};
