import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortDataBy } from "../../../SharedModule/utils/dataSort";
import { Status } from "../../types/types";

type spotlightReportState = {
  list: any[] | null | undefined;
  isLoading: boolean;
  filters: {
    status: number;
  };
  orderedBy: {
    orderBy: string;
    criteria: "asc" | "desc";
    dataType?: "string" | "date" | "number";
  };
  spotlightStatuses: Status[];
};

const initialState: spotlightReportState = {
  list: null,
  isLoading: false,
  filters: {
    status: 0,
  },
  orderedBy: {
    orderBy: "status",
    criteria: "desc"
  },
  spotlightStatuses: []
};

export const spotlightReportProductsSlice = createSlice({
  name: "spotlightReportProducts",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    load: (state, action) => {
      state.list = action.payload;
      state.isLoading = false;
    },
    loadStatuses: (state, action) => {
      state.spotlightStatuses = action.payload;
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    changeOrder: (
      state,
      action: PayloadAction<{
        orderBy: string;
        dataType?: "string" | "date" | "number";
      }>
    ) => {
      const newOrderedBy: any = {
        ...action.payload,
        criteria: "asc",
      };
      if (newOrderedBy.orderBy === state.orderedBy.orderBy) {
        newOrderedBy.criteria =
          state.orderedBy.criteria === "asc" ? "desc" : "asc";
      }
      return { ...state, orderedBy: newOrderedBy };
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  load,
  loadStatuses,
  setIsLoading,
  setFilters,
  changeOrder,
} = spotlightReportProductsSlice.actions;

export const selectReports = ({ spotlightReportProducts }: { spotlightReportProducts: spotlightReportState }) => {
  return { list: spotlightReportProducts.list };
};

export const IsLoadingReports = (state: any) => {
  return state.spotlightReportProducts.isLoading;
}

export const selectOrderCriteria = ({
  spotlightReportProducts,
}: {
  spotlightReportProducts: spotlightReportState;
}) => spotlightReportProducts.orderedBy;

export const selectOrderedBy = createSelector(
  [selectReports, selectOrderCriteria],
  (products, orderCriteria) => {
    return {
      list:
        (products.list &&
          sortDataBy(
            products.list,
            orderCriteria.orderBy,
            orderCriteria.criteria,
            orderCriteria.dataType
          )) ||
        null
    };
  }
);

export const SpotlightStatuses = (state: any) => {
  return state.spotlightReportProducts.spotlightStatuses;
};

export default spotlightReportProductsSlice.reducer;