import HttpService from "../../SharedModule/utils/api";
import { UserProjectsWorkOrders } from "../../types";
import { FiltersTimeConfirmationBody, FiltersToApproveBody, TimeEntryEditForm } from "../types";

const BASE_URL = "/timesheets";

function getWeeklyHours(): Promise<any> {
  return HttpService.req(`${BASE_URL}/timeentries/weekly-hours`);
}

function getTaskTypes(): Promise<any> {
  return HttpService.req(`${BASE_URL}/TaskTypes`);
}

function getNonBillableReasons(): Promise<any> {
  return HttpService.req(`${BASE_URL}/NonBillableReasons`);
}

function getTimesEntries(
  userId: string,
  startDate: string,
  endDate: string
): Promise<any> {
  return HttpService.req(
    `${BASE_URL}/timeentries/${userId}/${startDate}/${endDate}`
  );
}

function addTimesEntries(body: any): Promise<any> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/timeentries`,
    data: [...body],
    hasOwnHandler: true,
  });
}

function editTimesEntry(body: TimeEntryEditForm, id: number): Promise<any> {
  return HttpService.req({
    method: "put",
    url: `${BASE_URL}/timeentries/${id}`,
    data: { ...body },
    hasOwnHandler: true,
  });
}

function deleteTimesEntry(id: number): Promise<any> {
  return HttpService.req({
    method: "patch",
    url: `${BASE_URL}/timeentries/${id}/delete`,
    hasOwnHandler: true,
  });
}

function getTimeEntryInfo(id: number): Promise<any> {
  return HttpService.req({
    methos: "get",
    url: `${BASE_URL}/Audit/time-entries/${id}`,
    hasOwnHandler: true,
  });
}

function getQueryFilters(): Promise<any> {
  return HttpService.req(`${BASE_URL}/query/filters`);
}

function getUserProjects(id: string): Promise<UserProjectsWorkOrders> {
  return HttpService.req(`${BASE_URL}/users/${id}/projects`);
}

function getQueryResults(body: any): Promise<any> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/query`,
    data: { ...body },
    hasOwnHandler: true,
  });
}

function updateTimeEntries(body: any): Promise<any> {
  return HttpService.req({
    method: "patch",
    url: `${BASE_URL}/timeentries/update`,
    data: { ...body },
    hasOwnHandler: true,
  });
}

function getUserRolesForTimeModule(): Promise<any> {
  return HttpService.req(`${BASE_URL}/users/roles`);
}

function getApprovalTimeFilters(): Promise<any> {
  return HttpService.req(`${BASE_URL}/query/approve-filters`);
}

function getProjectsByUsers(body: string[]): Promise<any> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/users/common-projects`,
    data: [...body],
  });
}

function getTimeEntriesToApproveByUsers(body: FiltersToApproveBody): Promise<any> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/query/to-approve`,
    data: { ...body },
  });
}

function getTimeEntriesToApproveByUser(body: number[]): Promise<any> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/timeentries/list-view`,
    data: [ ...body ],
  });
}

function getPayPeriods(): Promise<any> {
  return HttpService.req(`${BASE_URL}/payperiods/current`);
}

function postTimeConfirmation(body: FiltersTimeConfirmationBody): Promise<any> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/timeConfirmationPost`,
    data: { ...body },
  });
}

export const TimesheetsService = {
  getWeeklyHours,
  getTaskTypes,
  getNonBillableReasons,
  getTimesEntries,
  addTimesEntries,
  editTimesEntry,
  deleteTimesEntry,
  getTimeEntryInfo,
  getQueryFilters,
  getUserProjects,
  getQueryResults,
  updateTimeEntries,
  getUserRolesForTimeModule,
  getApprovalTimeFilters,
  getProjectsByUsers,
  getTimeEntriesToApproveByUsers,
  getTimeEntriesToApproveByUser,
  getPayPeriods,
  postTimeConfirmation
};
