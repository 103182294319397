export const getStatus = (value: number) => {
  switch (value) {
    case 1:
      return "not-started";
    case 2:
      return "on-track";
    case 3:
      return "ahead-of-schedule";
    case 4:
      return "cautionary";
    case 5:
      return "critical";
    default:
      return "";
  }
};
