import { useEffect, useState } from "react";

export const usePortal = ({ element, id, style }: any) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const el = document.createElement(element);
    el.id = id;
    el.style = style;
    document.getElementsByTagName("body")[0].prepend(el);
    
    setLoaded(true);

    return () => document.getElementsByTagName("body")[0].removeChild(el);
  }, [element, id, style]);

  return loaded;
};
