import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Role } from "../../../types";
import httpService from "../../utils/api";

interface AuthState {
  isAuthenticated: boolean;
  accessToken: string | null;
  renewSessionToken: string | null;
  sessionExpiration: string | null;
  activeUserId: string | null;
  activeUserName: string | null;
  activeUserEmail: string | null;
  userRoles: Role[] | null;
}

const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: null,
  renewSessionToken: null,
  sessionExpiration: null,
  activeUserId: null,
  activeUserName: null,
  activeUserEmail: null,
  userRoles: null,
};

export const filtersSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (
      state,
      action: PayloadAction<{
        accessToken: string;
        activeUserId: string;
        activeUserName?: string;
        activeUserEmail?: string;
      }>
    ) => {
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken;
      state.activeUserId = action.payload.activeUserId;
      state.activeUserName = action.payload.activeUserName || null;
      state.activeUserEmail = action.payload.activeUserEmail || null;
    },
    logout: () => {      
      return initialState;
    },
    setUserRoles: (state, action) => {
      state.userRoles = [...action.payload];
    },
    refresh: () => {},
  },
});

// Action creators are generated for each case reducer function
export const { login, logout, setUserRoles, refresh } = filtersSlice.actions;

export const selectAuth = ({ auth }: { auth: AuthState }) => {
  return {
    isAuthenticated: auth.isAuthenticated,
    accessToken: auth.accessToken,
    renewSessionToken: auth.renewSessionToken,
    sessionExpiration: auth.sessionExpiration,
  };
};

export const selectUserRoles = ({
  auth,
}: {
  auth: AuthState;
}): Partial<AuthState> => {
  return {
    activeUserId: auth.activeUserId,
    activeUserName: auth.activeUserName,
    activeUserEmail: auth.activeUserEmail,
    userRoles: auth.userRoles,
  };
};

export default filtersSlice.reducer;

// Middleware to set token on http service when the login action is called
export const tokenMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action.type === "auth/login") {
    // after a successful login, update the token in the API
    const { accessToken } = action.payload;
    httpService.setToken(accessToken);
  }

  // continue processing this action
  return next(action);
};
