import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { Invoice } from "../../../types";
import {
  APPROVED,
  EXPORTED,
  RESET,
  VOID,
  THIS_MONTHS_BILLING,
  ALL_CLIENTS,
  ALL_STATUSES,
  ALL_OWNERS,
  ALL_DEPARTMENTS,
} from "../../../SharedModule/utils/constants";
import { sortDataBy } from "../../../SharedModule/utils/dataSort";
import {
  LAST_MONTHS_START,
  LAST_MONTHS_END,
  THIS_MONTHS_START,
  THIS_MONTHS_END,
} from "../../../SharedModule/utils/dateUtils";

type InvoicesState = {
  filters: {
    dateFrom: string;
    dateTo: string;
    invoiceOwners: any;
    accountIds: any;
    departmentIds: any;
    statusIds: any;
    invoiceNumbers: any;
    internalNumbers: any;
    idsInputValue: any;
  };
  list: (Invoice & { selected?: boolean })[] | null;
  orderedBy: {
    orderBy: string;
    criteria: "asc" | "desc";
    dataType?: "string" | "date";
  };
  allSelected: boolean;
  isLoadingInvoices: boolean;
};

const initialState: InvoicesState = {
  filters: {
    dateFrom: LAST_MONTHS_START.toString(),
    dateTo: THIS_MONTHS_END.toString(),
    invoiceOwners: null,
    accountIds: null,
    departmentIds: null,
    statusIds: null,
    invoiceNumbers: null,
    internalNumbers: null,
    idsInputValue: null,
  },
  list: null,
  orderedBy: {
    orderBy: "id",
    criteria: "asc",
  },
  allSelected: false,
  isLoadingInvoices: false,
};

export const invoicesSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {
    load: (state, action) => {
      state.list = action.payload;
      state.allSelected = false;
      state.isLoadingInvoices = false;
    },
    setIsLoadingInvoices: (state, action) => {
      state.isLoadingInvoices = action.payload;
    },
    setFilters: (state, action) => {
      state.filters = { ...state.filters, ...action.payload };
    },
    resetFilters: (state) => {
      return { ...state, filters: { ...initialState.filters } };
    },
    selectMidmonthDate: (state) => {
      state.filters.dateFrom = THIS_MONTHS_BILLING.startOf("day").toISO();
      state.filters.dateTo = THIS_MONTHS_BILLING.endOf("day").toISO({ includeOffset: false });
    },
    selectEndmonthDate: (state) => {
      state.filters.dateFrom = LAST_MONTHS_END.startOf("day").toISO();
      state.filters.dateTo = LAST_MONTHS_END.endOf("day").toISO({ includeOffset: false });
    },
    selectRecurringDate: (state) => {
      state.filters.dateFrom = THIS_MONTHS_START.startOf("day").toISO();
      state.filters.dateTo = THIS_MONTHS_START.endOf("day").toISO({ includeOffset: false });
    },
    selectLastmonthDate: (state) => {
      state.filters.dateFrom = LAST_MONTHS_START.startOf("day").toISO();
      state.filters.dateTo = LAST_MONTHS_END.endOf("day").toISO({ includeOffset: false });
    },

    resetFiltersWithoutOwner: (state) => {
      return {
        ...state,
        filters: {
          ...initialState.filters,
          invoiceOwners: state.filters.invoiceOwners,
        },
      };
    },
    changeOrder: (
      state,
      action: PayloadAction<{
        orderBy: string;
        dataType?: "string" | "date";
      }>
    ) => {
      const newOrderedBy: any = {
        ...action.payload,
        criteria: "asc",
      };
      if (newOrderedBy.orderBy === state.orderedBy.orderBy) {
        newOrderedBy.criteria =
          state.orderedBy.criteria === "asc" ? "desc" : "asc";
      }
      return { ...state, orderedBy: newOrderedBy };
    },
    toggleItemSelection: (state, action) => {
      if (!state.list) return state;
      const list = state.list.map((item) => {
        if (item.id === action.payload) {
          item.selected = !item.selected;
        }
        return item;
      });
      const anyUnselected = list.some((item: any) => {
        return item.status.id === 1 && !item.selected;
      });
      state.allSelected = !anyUnselected;
      state.list = [...list];
    },
    toggleSelectAll: (state) => {
      if (!state.list) return state;
      const noneSelected = state.list.some((item: any) => {
        return item.status.id === 1 && !item.selected;
      });
      let result: any;
      if (noneSelected) {
        result = state.list.map((item: any) => {
          if (item.status.id === 1) item.selected = true;
          return item;
        });
        state.allSelected = true;
      } else {
        result = state.list.map((item: any) => {
          item.selected = false;
          return item;
        });
        state.allSelected = false;
      }
      state.list = [...result] as [];
    },
    clear: (state) => {
      state.list = null;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  load,
  setIsLoadingInvoices,
  setFilters,
  resetFilters,
  selectMidmonthDate,
  selectEndmonthDate,
  selectRecurringDate,
  selectLastmonthDate,
  resetFiltersWithoutOwner,
  toggleItemSelection,
  toggleSelectAll,
  clear,
  changeOrder,
} = invoicesSlice.actions;

export const IsLoadingInvoices = (state: any) => {
  return state.invoices.isLoadingInvoices;
};

export const selectFilters = (state: any) => {
  return state.invoices.filters;
};

export const selectInvoices = ({ invoices }: { invoices: InvoicesState }) => {
  return { list: invoices.list, allSelected: invoices.allSelected };
};

export const selectOrderCriteria = ({
  invoices,
}: {
  invoices: InvoicesState;
}) => invoices.orderedBy;

export const selectOrderedBy = createSelector(
  [selectInvoices, selectOrderCriteria],
  (invoices, orderCriteria) => {
    return {
      list:
        (invoices.list &&
          sortDataBy(
            invoices.list,
            orderCriteria.orderBy,
            orderCriteria.criteria,
            orderCriteria.dataType
          )) ||
        null,
      allSelected: invoices.allSelected,
    };
  }
);

export const selectSelectedState = (state: any) => {
  let anyPending = false;
  let anyApproved = false;
  let anyExported = false;
  let anyReset = false;
  let anyVoided = false;

  const selectedInvoices =
    state.invoices.list?.filter((item: any) => item.selected) || [];

  selectedInvoices.forEach((item: any) => {
    switch (item.status.id) {
      case APPROVED:
        anyApproved = true;
        break;
      case EXPORTED:
        anyExported = true;
        break;
      case RESET:
        anyReset = true;
        break;
      case VOID:
        anyVoided = true;
        break;
      default:
        anyPending = true;
    }
  });

  const anyVoidable = !selectedInvoices.some((item: any) => !item.voidable);

  const disabled = {
    approve: true,
    reminder: true,
    void: true,
    reset: true,
  };
  if (anyPending && !(anyApproved || anyExported || anyReset || anyVoided)) {
    disabled.approve = false;
    disabled.reminder = false;
    disabled.void = true;
    disabled.reset = false;
  }
  if (anyExported) {
    disabled.reset = false;
    disabled.void = !anyVoidable;
  }
  if (anyApproved) {
    disabled.reset = false;
    disabled.void = true;
  }
  if (anyReset && anyVoided) {
    disabled.reset = true;
    disabled.void = true;
  }
  return { disabled };
};

export const selectSummary = (state: any) => {
  let result = {
    pendingTotal: 0,
    pendingCount: 0,
    invoicedTotal: 0,
    invoicedCount: 0,
    voidedTotal: 0,
    voidedCount: 0,
    total: 0,
    count: 0,
    resetCount: 0,
  };

  state.invoices.list?.forEach((item: Invoice) => {
    switch (item.status.id) {
      case APPROVED:
      case EXPORTED:
        result.invoicedCount++;
        result.invoicedTotal = result.invoicedTotal + item.amount;
        break;
      case RESET:
        result.resetCount++;
        break;
      case VOID:
        result.voidedCount++;
        result.voidedTotal = result.voidedTotal + item.amount;
        break;
      default:
        result.pendingCount++;
        result.pendingTotal = result.pendingTotal + item.amount;
    }
  });
  result.total =
    result.pendingTotal + result.invoicedTotal + result.voidedTotal;
  result.count =
    result.pendingCount +
    result.invoicedCount +
    result.voidedCount +
    result.resetCount;
  return result;
};

export const selectInvoiceFiltersLabels = (state: any) => {
  return {
    dateFrom: DateTime.fromISO(state.invoices.filters.dateFrom)
      .setLocale("en-US")
      .toFormat("DD"),
    dateTo: DateTime.fromISO(state.invoices.filters.dateTo)
      .setLocale("en-US")
      .toFormat("DD"),
    statuses:
      state.invoices.filters.statusIds
        ?.map((item: any) => item.label)
        ?.join(", ") || ALL_STATUSES,
    clients:
      state.invoices.filters.accountIds
        ?.map((item: any) => item.label)
        ?.join(", ") || ALL_CLIENTS,
    invoiceOwners:
      state.invoices.filters.invoiceOwners
        ?.map((item: any) => item.label)
        ?.join(", ") || ALL_OWNERS,
    departments:
      state.invoices.filters.departamentIds
        ?.map((item: any) => item.label)
        ?.join(", ") || ALL_DEPARTMENTS,
  };
};

export default invoicesSlice.reducer;
