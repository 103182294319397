import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { Provider as ReduxProvider } from "react-redux";
import { msalConfig } from "./authConfig";
import { store, persistor } from "../src/SharedModule/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Integrations } from "@sentry/tracing";
import * as Sentry from "@sentry/react";
import reportWebVitals from "./reportWebVitals";
import { MatomoProvider, createInstance } from "@jonkoops/matomo-tracker-react";
import { BrowserRouter } from "react-router-dom";
import { DEVELOPMENT, PRODUCTION } from "./SharedModule/utils/constants";
import ScrollToTop from "./SharedModule/components/ScrollToTop";
import { checkEnvironment } from "./SharedModule/utils/headerUtils";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

if (process.env.NODE_ENV !== DEVELOPMENT) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    autoSessionTracking: true,
    integrations: [new Integrations.BrowserTracing() as never],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
  // To track a custom message
  // Sentry.captureMessage("App started");
}

const APP_VERSION = process.env.REACT_APP_CURRENT_GIT_SHA;

if (localStorage.APP_VERSION !== APP_VERSION) {
  localStorage.clear();
  localStorage.setItem("APP_VERSION", APP_VERSION as string);
}

// only track for staging and production
let actualSiteId =
  checkEnvironment() === PRODUCTION
    ? 1
    : process.env.REACT_APP_MSAL_REDIRECT_URI ===
      "https://thehub-release.itx.dev/"
    ? 6
    : 5;

const instance = createInstance({
  urlBase: "https://thehub.itx.com",
  siteId: actualSiteId,
  userId: undefined, // optional, default value: `undefined`.
  trackerUrl: "https://thehub.itx.com/tracking.php", // optional, default value: `${urlBase}matomo.php`
  srcUrl: "https://thehub.itx.com/tracking.js", // optional, default value: `${urlBase}matomo.js`
  disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
  heartBeat: {
    // optional, enabled by default
    active: true, // optional, default value: true
    seconds: 10, // optional, default value: `15
  },
  linkTracking: false, // optional, default value: true
  configurations: {
    // optional, default value: {}
    // any valid matomo configuration, all below are optional
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={msalInstance}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <MatomoProvider value={instance}>
            <BrowserRouter>
              <ScrollToTop />
              <App />
            </BrowserRouter>
          </MatomoProvider>
        </PersistGate>
      </ReduxProvider>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
