import { useDispatch } from "react-redux";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { BillingService } from "../services/billing";

export type ChangeOwnerPayload = {
  id: number;
  owner: { id: string; fullName: string };
};

export const useChangeOwner = (
  showModal: (options: any) => Promise<void>,
  closeModal: () => void,
  callback: Function
) => {
  const dispatch = useDispatch();
  const changeOwner = async (payload: ChangeOwnerPayload) => {
    const options = {
      title: `Change Owner?`,
      text: `Are you sure you want to make ${
        payload.owner.fullName
      } the owner of this invoice? ${
        payload.owner.fullName.split(", ")[1]
      } will be notified via email.`,
      btnLabel: `Yes, change owner`,
    };
    await showModal(options);
    const invoicesToUpdate = [
      {
        id: payload.id,
        owner: {
          id: payload.owner.id,
        },
      },
    ];
    closeModal();
    const newInvoices:any = await BillingService.updateInvoices(invoicesToUpdate);
    if (newInvoices.data === "Invoices updated successfully.") {
      const toastText = "Owner changed";
      dispatch(addToast({ mode: "success", message: toastText }));
      callback();
    } else {
      dispatch(addToast({ mode: "error", message: "Something went wrong" }));
    }
  };

  return changeOwner;
};
