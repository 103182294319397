import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import Footer from "../components/Footer";
import { ADMIN_ACCESS, PRODUCTION, ROLE_SUPER_ADMIN } from "../utils/constants";
import { checkEnvironment } from "../utils/headerUtils";
import { AccessControl } from "../components/AccessControl";

export const AdminPage = () => {
  const { trackPageView } = useMatomo();

  let rolesUrl =
    "https://appsmith.itx.com/app/for-testing-itx-roles-assignment/manage-user-roles-66cce2565fbd604b3dceccee";
  let priceLevelsUrl =
    "https://appsmith.itx.com/app/for-testing-time-price-calculation/price-levels-66e9bc395fbd604b3dcee66a";
  let userPriceLevelsUrl =
    "https://appsmith.itx.com/app/for-testing-time-price-calculation/user-price-levels-66e9bc395fbd604b3dcee66c";
  let accountPriceLevelsUrl =
    "https://appsmith.itx.com/app/for-testing-time-price-calculation/account-price-levels-66e9bc395fbd604b3dcee66d";
  let accountPriceExceptionsUrl =
    "https://appsmith.itx.com/app/for-testing-time-price-calculation/account-price-exceptions-66e9bc395fbd604b3dcee66e";
  let projectPriceExceptionsUrl =
    "https://appsmith.itx.com/app/for-testing-time-price-calculation/project-price-exceptions-66e9bc395fbd604b3dcee66b";

  if (checkEnvironment() === PRODUCTION) {
    rolesUrl =
      "https://appsmith.itx.com/app/itx-roles-assignment/manage-user-roles-66a8eea84c985c49aadd2d5d";
    priceLevelsUrl =
      "https://appsmith.itx.com/app/time-price-calculation/price-levels-650dcc94a9aa460a23d23825";
    userPriceLevelsUrl =
      "https://appsmith.itx.com/app/time-price-calculation/user-price-levels-6513427ca9aa460a23d23bac";
    accountPriceLevelsUrl =
      "https://appsmith.itx.com/app/time-price-calculation/account-price-levels-6517266da9aa460a23d23ef9";
    accountPriceExceptionsUrl =
      "https://appsmith.itx.com/app/time-price-calculation/account-price-exceptions-651daa85a9aa460a23d24136";
    projectPriceExceptionsUrl =
      "https://appsmith.itx.com/app/time-price-calculation/project-price-exceptions-6526bd63fe2b55560b18ec18";
  }

  const url = process.env.REACT_APP_ASUITE_BASE_URI;

  useEffect(() => {
    document.title = "The Hub - Admin";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  const goToAsuiteAdmin = () => {
    window.location.href = url + "/admin/adminmenu.asp";
  };

  return (
    <div className="content">
      <div className="content-header" style={{ marginTop: "0" }}>
        <h4 className="admin-title">The Hub Administration</h4>
        <button
          className="btn btn-primary button-link"
          onClick={() => goToAsuiteAdmin()}
        >
          ASuite Admin
        </button>
      </div>
      <div className="admin-container">
        <div className="left-column">
          <AccessControl allowedRoles={ROLE_SUPER_ADMIN}>
            <>
              <h5 className="fw-600">Users</h5>
              <a
                className="link-text"
                target="_blank"
                rel="noreferrer"
                href={rolesUrl}
              >
                Assign Roles to Users
              </a>
              <a
                className="link-text"
                target="_blank"
                rel="noreferrer"
                href={userPriceLevelsUrl}
              >
                Assign Price Level to Users
              </a>
            </>
          </AccessControl>
          <AccessControl allowedRoles={ADMIN_ACCESS}>
            <>
              <h5 className="fw-600 mt-4">Accounts</h5>
              <a
                className="link-text"
                target="_blank"
                rel="noreferrer"
                href={accountPriceLevelsUrl}
              >
                Assign Price Schedule to Accounts
              </a>
              <a
                className="link-text"
                target="_blank"
                rel="noreferrer"
                href={accountPriceExceptionsUrl}
              >
                Account Price Exceptions
              </a>
              <h5 className="fw-600 mt-4">Projects</h5>
              <a
                className="link-text"
                target="_blank"
                rel="noreferrer"
                href={projectPriceExceptionsUrl}
              >
                Project Price Exceptions
              </a>
              <h5 className="fw-600 mt-4">Administration</h5>
              <a
                className="link-text"
                target="_blank"
                rel="noreferrer"
                href={priceLevelsUrl}
              >
                Price Levels
              </a>
            </>
          </AccessControl>
        </div>
        <div className="right-column"></div>
      </div>
      <Footer />
    </div>
  );
};
