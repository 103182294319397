import { actions, MoreActionsButton } from "../InvoicesPage/MoreActionsButton";
import { useInvoiceDisabledActions } from "../../hooks/useInvoicesDisabledActions";
import { Invoice } from "../../../types";
import { AccessControl } from "../../../SharedModule/components/AccessControl";
import {
  PENDING,
  ROLE_NOT_OWNERS,
  ROUTE_INVOICES_DETAIL,
  ROUTE_INVOICES_LIST,
} from "../../../SharedModule/utils/constants";
import { useNavigate } from "react-router-dom";
import { useInvoices } from "../../hooks/useInvoices";

export const InvoiceActions = ({
  invoice,
  updateInvoice: updateInvoiceFn,
  sendReminders,
  loadInvoice,
}: {
  invoice: Invoice;
  updateInvoice: (
    callback: Function,
    action: actions,
    id?: number
  ) => Promise<void>;
  sendReminders: Function;
  loadInvoice: Function;
}) => {
  const disabled = useInvoiceDisabledActions(invoice.status, invoice.voidable);
  const navigate = useNavigate();

  const { invoices, loadInvoices } = useInvoices(true);

  const updateInvoice = (action: actions, id: number) =>
    updateInvoiceFn(loadInvoice, action, id);

  const approveCb = (loadNext?: boolean) => {
    if (!loadNext) {
      return () => navigate(ROUTE_INVOICES_LIST);
    }
    return async () => {
      const { id } = invoice;

      // Set updated list of invoices with hook useInvoices
      loadInvoices();
      
      const currentInvoiceIndex = invoices?.findIndex(
        (invoice: Invoice) => invoice.id === id
      );

      const firstHalf = invoices?.slice(0, currentInvoiceIndex);
      const secondHalf = invoices?.slice(currentInvoiceIndex);

      const nextPendingInvoices = secondHalf
        ?.concat(firstHalf || [])
        ?.filter(
          (invoice: Invoice) => invoice.status.id === PENDING && invoice.id !== id
        );

      const nextPendingInvoice = nextPendingInvoices && nextPendingInvoices[0];

      if (nextPendingInvoice) {
        const route = ROUTE_INVOICES_DETAIL.replace(
          ":id",
          nextPendingInvoice.id.toString()
        );
        navigate(route);
      } else {
        navigate(ROUTE_INVOICES_LIST);
      }
    };
  };

  return (
    <div className="bg-light d-flex p-2 border-bottom">
      <button
        className={`btn btn-primary ${disabled.approve ? "disabled" : ""}`}
        onClick={() => {
          updateInvoiceFn(approveCb(), actions.approve, invoice.id);
        }}
      >
        Approve Invoice
      </button>
      <button
        className={`btn btn-primary ms-2 ${disabled.approve ? "disabled" : ""}`}
        onClick={() => {
          updateInvoiceFn(
            approveCb(true),
            actions.approveAndViewNext,
            invoice.id
          );
        }}
      >
        Approve &amp; View next invoice
      </button>
      <AccessControl
        allowedRoles={ROLE_NOT_OWNERS}
        fallback={
          <button
            className={`btn btn-outline-secondary bg-white text-primary ms-2 ${
              disabled.approve ? "disabled" : ""
            }`}
            onClick={() => updateInvoice(actions.reset, invoice.id)}
          >
            Reset
          </button>
        }
      >
        <MoreActionsButton
          disabled={disabled}
          sendReminders={() => sendReminders(invoice.id)}
          resetInvoices={() => updateInvoice(actions.reset, invoice.id)}
          voidInvoices={() => {
            updateInvoice(actions.void, invoice.id);
          }}
        />
      </AccessControl>
    </div>
  );
};
