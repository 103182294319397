import { Spotlight, Status } from "./../types/types";
import HttpService from "../../SharedModule/utils/api";
const BASE_URL = "/products/Spotlight";

function toggleSpotlight<T>(id: number): Promise<T> {
  return HttpService.req({
    method: "patch",
    url: `${BASE_URL}/ToggleSpotlight?id=${id}`,
  });
}

function addSpotlight<T>(
  ownerId: string,
  productId: number,
  status: number,
  comment: string
): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/AddSpotlight?ownerId=${ownerId}&productId=${productId}&status=${status}`,
    data: JSON.stringify(comment),
  });
}

function getLastSpotlight(productId: number): Promise<Spotlight> {
  return HttpService.req({
    url: `${BASE_URL}/GetLastSpotlight?productId=${productId}`,
    hasOwnHandler: true,
  });
}

function getSpotlightHistory(productId: number): Promise<Spotlight[]> {
  return HttpService.req(
    `${BASE_URL}/GetSpotlightHistory?productId=${productId}`
  );
}

function getSpotlightStatuses(): Promise<Status[]> {
  return HttpService.req(`${BASE_URL}/GetSpotlightStatuses`);
}

function getSpotlightSummary(): Promise<Status[]> {
  return HttpService.req(`${BASE_URL}/Summary`);
}

export const SpotlightService = {
  toggleSpotlight,
  addSpotlight,
  getLastSpotlight,
  getSpotlightHistory,
  getSpotlightStatuses,
  getSpotlightSummary,
};
