import { useEffect } from "react";
import { getHostUrl } from "../../ProductsModule/utils/reportsUtils";
import { TelerikReport } from "../components/TelerikReport";
import { useDispatch } from "react-redux";
import { setActiveTab } from "../redux/reducers/uiShared.reducer";
import { ROUTE_ACCESS_CONTROL_REPORT_BY_JIRA_PROJECT } from "../utils/constants";

export const AccessControlReportPageByJiraProject = () => {
  let parameters = {
    HostUrl: getHostUrl(),
  };
  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "The Hub - Access Control Report By Jira Proyect";
    dispatch(setActiveTab(ROUTE_ACCESS_CONTROL_REPORT_BY_JIRA_PROJECT));
  }, []);

  return (
    <TelerikReport
      title="Access Control Report By Jira Project"
      reportName="AccessControlReportByJiraProject.trdp"
      parameters={parameters}
      parametersAreaVisible={true}
    />
  );
};
