import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { clear as spotlightClear } from "../redux/reducers/spotlight.reducer";
import { clear as keyContactClear } from "../redux/reducers/keyContact.reducer";
import { clear as successCriteriaClear } from "../redux/reducers/successCriteria.reducer";
import image from "./../../../src/styles/legacy/spotlight-off.png";
import { NoContent } from "../../SharedModule/components/NoContent";
import Footer from "../../SharedModule/components/Footer";

export const DeleteProductPage = () => {
  const { trackPageView } = useMatomo();

  const dispatch = useDispatch();

  useEffect(() => {
    document.title = "The Hub - Delete Product";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    // Should clean reducers with initial state to avoid wrong data when prop 'isloading' is true
    dispatch(spotlightClear());
    dispatch(keyContactClear());
    dispatch(successCriteriaClear());
  }, []);

  return (
    <div className="content">
      <NoContent
        title="That product does not exist"
        text="You may access a product from the Products menu"
        image={image}
        style={{ margin: "3% auto" }}
      />
      <Footer />
    </div>
  );
};
