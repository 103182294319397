import {
  PROJ_MEAL_BREAK_ID,
  PROJ_UNPAID_TIME_OFF_ID,
  TASKTYPE_MEAL_BREAK_ID,
} from "../../SharedModule/utils/constants";
import { getDateFromDateString, isSameDay } from "../../SharedModule/utils/dateUtils";
import { EntryListView, TimeEntryForm } from "../types";

export const mealBreakHours = [
  {
    value: "12:00 am",
    label: "12:00 am",
  },
  {
    value: "12:15 am",
    label: "12:15 am",
  },
  {
    value: "12:30 am",
    label: "12:30 am",
  },
  {
    value: "12:45 am",
    label: "12:45 am",
  },
  {
    value: "01:00 am",
    label: "01:00 am",
  },
  {
    value: "01:15 am",
    label: "01:15 am",
  },
  {
    value: "01:30 am",
    label: "01:30 am",
  },
  {
    value: "01:45 am",
    label: "01:45 am",
  },
  {
    value: "02:00 am",
    label: "02:00 am",
  },
  {
    value: "02:15 am",
    label: "02:15 am",
  },
  {
    value: "02:30 am",
    label: "02:30 am",
  },
  {
    value: "02:45 am",
    label: "02:45 am",
  },
  {
    value: "03:00 am",
    label: "03:00 am",
  },
  {
    value: "03:15 am",
    label: "03:15 am",
  },
  {
    value: "03:30 am",
    label: "03:30 am",
  },
  {
    value: "03:45 am",
    label: "03:45 am",
  },
  {
    value: "04:00 am",
    label: "04:00 am",
  },
  {
    value: "04:15 am",
    label: "04:15 am",
  },
  {
    value: "04:30 am",
    label: "04:30 am",
  },
  {
    value: "04:45 am",
    label: "04:45 am",
  },
  {
    value: "05:00 am",
    label: "05:00 am",
  },
  {
    value: "05:15 am",
    label: "05:15 am",
  },
  {
    value: "05:30 am",
    label: "05:30 am",
  },
  {
    value: "05:45 am",
    label: "05:45 am",
  },
  {
    value: "06:00 am",
    label: "06:00 am",
  },
  {
    value: "06:15 am",
    label: "06:15 am",
  },
  {
    value: "06:30 am",
    label: "06:30 am",
  },
  {
    value: "06:45 am",
    label: "06:45 am",
  },
  {
    value: "07:00 am",
    label: "07:00 am",
  },
  {
    value: "07:15 am",
    label: "07:15 am",
  },
  {
    value: "07:30 am",
    label: "07:30 am",
  },
  {
    value: "07:45 am",
    label: "07:45 am",
  },
  {
    value: "08:00 am",
    label: "08:00 am",
  },
  {
    value: "08:15 am",
    label: "08:15 am",
  },
  {
    value: "08:30 am",
    label: "08:30 am",
  },
  {
    value: "08:45 am",
    label: "08:45 am",
  },
  {
    value: "09:00 am",
    label: "09:00 am",
  },
  {
    value: "09:15 am",
    label: "09:15 am",
  },
  {
    value: "09:30 am",
    label: "09:30 am",
  },
  {
    value: "09:45 am",
    label: "09:45 am",
  },
  {
    value: "10:00 am",
    label: "10:00 am",
  },
  {
    value: "10:15 am",
    label: "10:15 am",
  },
  {
    value: "10:30 am",
    label: "10:30 am",
  },
  {
    value: "10:45 am",
    label: "10:45 am",
  },
  {
    value: "11:00 am",
    label: "11:00 am",
  },
  {
    value: "11:15 am",
    label: "11:15 am",
  },
  {
    value: "11:30 am",
    label: "11:30 am",
  },
  {
    value: "11:45 am",
    label: "11:45 am",
  },
  {
    value: "12:00 pm",
    label: "12:00 pm",
  },
  {
    value: "12:15 pm",
    label: "12:15 pm",
  },
  {
    value: "12:30 pm",
    label: "12:30 pm",
  },
  {
    value: "12:45 pm",
    label: "12:45 pm",
  },
  {
    value: "01:00 pm",
    label: "01:00 pm",
  },
  {
    value: "01:15 pm",
    label: "01:15 pm",
  },
  {
    value: "01:30 pm",
    label: "01:30 pm",
  },
  {
    value: "01:45 pm",
    label: "01:45 pm",
  },
  {
    value: "02:00 pm",
    label: "02:00 pm",
  },
  {
    value: "02:15 pm",
    label: "02:15 pm",
  },
  {
    value: "02:30 pm",
    label: "02:30 pm",
  },
  {
    value: "02:45 pm",
    label: "02:45 pm",
  },
  {
    value: "03:00 pm",
    label: "03:00 pm",
  },
  {
    value: "03:15 pm",
    label: "03:15 pm",
  },
  {
    value: "03:30 pm",
    label: "03:30 pm",
  },
  {
    value: "03:45 pm",
    label: "03:45 pm",
  },
  {
    value: "04:00 pm",
    label: "04:00 pm",
  },
  {
    value: "04:15 pm",
    label: "04:15 pm",
  },
  {
    value: "04:30 pm",
    label: "04:30 pm",
  },
  {
    value: "04:45 pm",
    label: "04:45 pm",
  },
  {
    value: "05:00 pm",
    label: "05:00 pm",
  },
  {
    value: "05:15 pm",
    label: "05:15 pm",
  },
  {
    value: "05:30 pm",
    label: "05:30 pm",
  },
  {
    value: "05:45 pm",
    label: "05:45 pm",
  },
  {
    value: "06:00 pm",
    label: "06:00 pm",
  },
  {
    value: "06:15 pm",
    label: "06:15 pm",
  },
  {
    value: "06:30 pm",
    label: "06:30 pm",
  },
  {
    value: "06:45 pm",
    label: "06:45 pm",
  },
  {
    value: "07:00 pm",
    label: "07:00 pm",
  },
  {
    value: "07:15 pm",
    label: "07:15 pm",
  },
  {
    value: "07:30 pm",
    label: "07:30 pm",
  },
  {
    value: "07:45 pm",
    label: "07:45 pm",
  },
  {
    value: "08:00 pm",
    label: "08:00 pm",
  },
  {
    value: "08:15 pm",
    label: "08:15 pm",
  },
  {
    value: "08:30 pm",
    label: "08:30 pm",
  },
  {
    value: "08:45 pm",
    label: "08:45 pm",
  },
  {
    value: "09:00 pm",
    label: "09:00 pm",
  },
  {
    value: "09:15 pm",
    label: "09:15 pm",
  },
  {
    value: "09:30 pm",
    label: "09:30 pm",
  },
  {
    value: "09:45 pm",
    label: "09:45 pm",
  },
  {
    value: "10:00 pm",
    label: "10:00 pm",
  },
  {
    value: "10:15 pm",
    label: "10:15 pm",
  },
  {
    value: "10:30 pm",
    label: "10:30 pm",
  },
  {
    value: "10:45 pm",
    label: "10:45 pm",
  },
  {
    value: "11:00 pm",
    label: "11:00 pm",
  },
  {
    value: "11:15 pm",
    label: "11:15 pm",
  },
  {
    value: "11:30 pm",
    label: "11:30 pm",
  },
  {
    value: "11:45 pm",
    label: "11:45 pm",
  },
];

export const getIndexFromMealBreakHours = (
  entryDescription: string,
  start: boolean
) => {
  let mealBreakFromDescription = {
    value: start
      ? entryDescription.split(" ")[0] + " " + entryDescription.split(" ")[1]
      : entryDescription.split(" ")[3] + " " + entryDescription.split(" ")[4],
    label: start
      ? entryDescription.split(" ")[0] + " " + entryDescription.split(" ")[1]
      : entryDescription.split(" ")[3] + " " + entryDescription.split(" ")[4],
  };

  return mealBreakHours.findIndex(
    (elem) => mealBreakFromDescription.value === elem.value
  );
};

export const alreadyExistMealBreak = (
  entry: TimeEntryForm,
  timeEntriesList: EntryListView[]
) => {
  // check is actual entry is not meal break
  if (entry.entryProjectWorkOrder.value !== PROJ_MEAL_BREAK_ID) {
    if (
      entry.entryProjectWorkOrder.value !== PROJ_UNPAID_TIME_OFF_ID ||
      (entry.entryProjectWorkOrder.value === PROJ_UNPAID_TIME_OFF_ID &&
        entry.entryTaskType.value !== TASKTYPE_MEAL_BREAK_ID)
    ) {
      return false;
    }
  }
  let response: any = timeEntriesList
    .filter(
      (elem) =>
        elem.entityId === PROJ_MEAL_BREAK_ID ||
        (elem.entityId === PROJ_UNPAID_TIME_OFF_ID &&
          elem.taskTypeId === TASKTYPE_MEAL_BREAK_ID)
    )
    .find((elem) => isSameDay(elem.entryDate, getDateFromDateString(entry.entryDate)));
  return !!response;
};
