import axios from "axios";
import { EventEmitter } from "./eventEmitter";
import { ROUTE_FORBIDDEN_PAGE } from "./constants";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_MSAL_REDIRECT_URI === "http://localhost:3000" ? "http://localhost:3001/api" : "/api",
  headers: {
    "Content-Type": "application/json",
  },
});

const emitter = new EventEmitter();

const httpService = (() => {
  let _token: any;
  let _listener: any;

  const setToken = (token: any) => {
    _token = token;
    if (_listener) emitter.emit("tokenSet");
  };

  const req = async (params: any) => {
    return new Promise<any>(async (resolve, reject) => {
      if (!_token) {
        const handler = async () => {
          const result = await configCall(params);
          resolve(result);
          emitter.removeListener("tokenSet", handler);
          _listener = false;
        };
        _listener = emitter.on("tokenSet", handler);
      } else {
        try {
          const result = await configCall(params);
          resolve(result);
        } catch (e) {
          reject(e);
        }
      }
    });
  };

  const configCall = (params: any) => {
    let options: any;
    if (typeof params !== "object") {
      options = {
        url: params,
      };
    } else {
      options = params;
    }

    return call({
      ...options,
      headers: {
        Authorization: `Bearer ${_token}`,
      },
    });
  };

  const call = (options: any) => {
    const onSuccess = function (response: any) {
      return response.data;
    };

    const onError = function (error: any) {
      console.error("Request Failed:", error.config);

      if (error.response) {
        console.error("Status:", error.response.status);
        console.error("Data:", error.response.data);
        console.error("Headers:", error.response.headers);
      } else {
        console.error("Error Message:", error.message);
      }

      // condition when user is deny to enter a page or could not execute an action
      if (error.response.status === 403) {
        window.location.href =
          process.env.REACT_APP_MSAL_REDIRECT_URI?.slice(0, -1) +
          ROUTE_FORBIDDEN_PAGE;
      }

      return Promise.reject(error.response || error.message);
    };

    // HANDLER EXCEPTION
    // if we should handler the error exception, you shold add flag 'hasOwnHandler' to HttpService.req as a param
    // then you should add 'then and catch' that we remove on line below on async/await function
    // could check and example on useUpdateInvoices.tsx
    if (options.hasOwnHandler) {
      return axiosInstance(options);
    } else {
      return axiosInstance(options).then(onSuccess).catch(onError);
    }
  };

  return { req, setToken };
})();

export default httpService;
