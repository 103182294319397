import { useMemo } from "react";
import styles from "./Toast.module.scss";

export const Toast = ({ mode, onClose, message }: any) => {
  const classes = useMemo(
    () => [styles.toast, styles[mode]].join(" ") + " d-flex justify-content-center",
    [mode]
  );

  return (
    <div onClick={onClose} className={classes}>
      <div className={styles.message}>{message}</div>
    </div>
  );
};
