import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useEffect } from "react";
import image from "./../../styles/legacy/404.png";
import { NoContent } from "../components/NoContent";
import Footer from "../components/Footer";
import { ROUTE_HOME } from "../utils/constants";
import { useNavigate } from "react-router-dom";

export const NotFoundPage = () => {
  const { trackPageView } = useMatomo();

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "The Hub - 404 - Page Not found";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  const goHomePage = () => {
    navigate(ROUTE_HOME);
  };

  return (
    <div className="content">
      <NoContent
        title="404 - Page Not found"
        text="The page you are looking for does not exist."
        action={() => goHomePage()}
        conditionButton={true}
        image={image}
        style={{ margin: "3% auto" }}
        textButton="Go to home page"
      />
      <Footer />
    </div>
  );
};
