import { useDispatch, useSelector } from "react-redux";
import {
  toggleLeftNavBarFixed,
  actualUIState,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import { BiArrowFromRight, BiArrowFromLeft } from "react-icons/bi";
import { useState } from "react";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import eventHandler from "../utils/eventHandler";

export const LeftNavBar = (props: any) => {
  const dispatch = useDispatch();
  const { isLeftNavBarFixed } = useSelector(actualUIState);

  const [expandLeftNavBar, setExpandLeftNavBar] = useState(false);
  const [forceCollapse, setForceCollapse] = useState(false);
  const [forceNotHover, setForceNotHover] = useState(false);

  // Force collapse when left nav bar is fixed
  const collapseBar = () => {
    notExpand(false);
    setForceCollapse(true);
    dispatch(toggleLeftNavBarFixed());
    // event to reload telerik reports
    eventHandler.dispatch("toggleLeftNavBarFixed", {});
    setTimeout(() => {
      setForceCollapse(false);
    }, 500);
  };

  // Set left nav bar expanded fixed
  const expandBar = () => {
    setForceNotHover(false);
    dispatch(toggleLeftNavBarFixed());
    // event to reload telerik reports
    eventHandler.dispatch("toggleLeftNavBarFixed", {});
  };

  // avoid expand when left nav bar is totally collapsed
  const notExpand = (state: boolean) => {
    if (!expandLeftNavBar) {
      setForceNotHover(state);
    }
  };

  // Just a flag to recognized is left nav bar is expanded or not (on hover)
  const expand = (state) => {
    if (!isLeftNavBarFixed) {
      setExpandLeftNavBar(state);
    }
  };

  return (
    <ul
      onMouseEnter={() => expand(true)}
      onMouseLeave={() => expand(false)}
      className={`sidemenu-elements${isLeftNavBarFixed ? " fixed" : ""}${
        forceCollapse ? " forceCollapse" : ""
      }${forceNotHover ? " not-hover" : ""}
      `}
    >
      <li>
        {!isLeftNavBarFixed && (
          <Tooltip
            title={<b>{"Expand"}</b>}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [10, -10],
                    },
                  },
                ],
              },
            }}
            placement="top"
            TransitionComponent={Zoom}
          >
            <div
              className="collapse-button-container"
              onMouseEnter={() => notExpand(true)}
              onMouseLeave={() => setForceNotHover(false)}
            >
              <button className="collapse-button" onClick={() => expandBar()}>
                <BiArrowFromLeft className="collapse-button-icon" />
              </button>
            </div>
          </Tooltip>
        )}
        {isLeftNavBarFixed && (
          <Tooltip
            title={<b>{"Collapse"}</b>}
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [10, -10],
                    },
                  },
                ],
              },
            }}
            placement="top"
            TransitionComponent={Zoom}
          >
            <button
              className="collapse-button"
              onClick={() => collapseBar()}
              onMouseEnter={() => expand(true)}
            >
              <BiArrowFromRight className="collapse-button-icon" />
            </button>
          </Tooltip>
        )}
      </li>

      <div className="collapse-row" style={{ marginTop: "0.25rem" }}>
        {props.children}
      </div>
    </ul>
  );
};
