import { useState } from "react";
import { Calendar } from "../../SharedModule/components/Calendar";

export const ModalActionChangeDate = ({ btnAction, btnCancel }: any) => {
  const [newDate, setNewDate] = useState(new Date());

  return (
    <>
      <h4 className="text-capitalize">Change Date</h4>
      <label className="color-neutrals-800">
        Move time entry to this date.
      </label>
      <div className="d-flex my-4 flex-column">
        <label className="mb-1">Date</label>
        <div className="mb-2">
          <Calendar
            className="pointer"
            date={newDate}
            onChange={(date: Date) => setNewDate(date)}
            isDisabled={false}
          />
        </div>
      </div>
      <div className="d-flex mt-2">
        <button className="btn button-secondary w-100" onClick={btnCancel}>
          Cancel
        </button>
        <button
          className="btn btn-primary w-100 ms-2"
          onClick={() => btnAction(5, newDate)}
        >
          Save
        </button>
      </div>
    </>
  );
};
