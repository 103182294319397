import { Invoice } from "../../../types";
import { currencyFormat } from "../../../SharedModule/utils/formatters";

export const InvoiceFooter = ({ invoice }: { invoice: Invoice }) => (
  <div className="d-flex justify-content-end mt-4">
    <table className="text-end">
      <tbody>
        <tr>
          <td>Total:</td>
          <td className="fw-bold">{currencyFormat(invoice.amount)}</td>
        </tr>
        <tr>
          <td>
            Tax jurisdiction: {invoice.taxJurisdiction} ({invoice.taxRate * 100}
            %)
          </td>
          <td className="fw-bold">{currencyFormat(invoice.taxes)}</td>
        </tr>
        <tr className="h6 fw-bold">
          <td className="pt-3">Total after tax:</td>
          <td className="pt-3">
            <div className="ps-4">
              {currencyFormat(invoice.amountWithTaxes)}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
);
