import { useDispatch } from "react-redux";
import { actions } from "../components/InvoicesPage/MoreActionsButton";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { BillingService } from "../services/billing";
import { Invoice } from "../../types";

export const useUpdateInvoices = (
  showModal: (options: any) => Promise<void>,
  closeModal: Function,
  invoices: (Invoice & { selected?: boolean })[] | null
) => {
  const dispatch = useDispatch();

  const saveComment = async (invoiceId: string, comment: string) => {
    await BillingService.addCommentToInvoice(invoiceId, comment);
  };

  const updateInvoices = async (
    callback: Function,
    action: actions,
    id?: number
  ) => {
    if (!invoices) return;
    const selectedElements = invoices.filter((invoice) => {
      return id ? invoice.id === id : invoice.selected;
    });

    const actionId = {
      [actions.approve]: {
        statusId: 2,
        modalAction: "approve",
        toastAction: "approved",
        message: "",
        secondMessage: null,
      },
      [actions.approveAndViewNext]: {
        statusId: 2,
        toastAction: "approved",
        secondMessage: null,
      },
      [actions.reset]: {
        statusId: 4,
        modalAction: "reset",
        toastAction: "reset",
        message: `Select "Yes, reset invoice" if you need to make changes to the items on the invoice (time records or resources). The invoice will be reset so you can modify items. Remember to notify the Accounting Team as soon as your modifications have been made so an updated invoice can be created.`,
        secondMessage: `Select "No" to cancel OR if the invoice should be voided as we won´t request payment from the client. In that case, select "No" and contact the Accounting team.`,
      },
      [actions.void]: {
        statusId: 5,
        modalAction: "void",
        toastAction: "voided",
        message:
          "This action is for when we have completed work but will not request payment from the client or the client has rejected the invoice. The items in the invoice will be marked as non-billable.",
        secondMessage: null,
      },
    };
    const modalOptions =
      action === actions.approveAndViewNext
        ? {
            title: `Approve and View Next Invoice?`,
            text: `Are you sure you want to approve this invoice?`,
            btnLabel: `Yes, Approve and view next invoice`,
            hasInput: false,
          }
        : {
            title: `${actionId[action].modalAction} ${
              selectedElements.length > 1 ? selectedElements.length : ""
            } Invoice${selectedElements.length > 1 ? "s" : ""}?`,
            text: `Are you sure you want to ${actionId[action].modalAction} ${
              selectedElements.length > 1 ? "these invoices" : "this invoice"
            }? ${actionId[action]?.message}`,
            secondText: actionId[action]?.secondMessage,
            btnLabel: `Yes, ${actionId[action].modalAction} invoice${
              selectedElements.length > 1 ? "s" : ""
            }`,
            hasInput:
              actionId[action].statusId === 4 ||
              actionId[action].statusId === 5,
            actionPlaceholder: `${
              actionId[action].statusId === 4
                ? "reset"
                : `${actionId[action].statusId === 5 ? "void" : ""}`
            }`,
          };
    await showModal(modalOptions);

    const invoicesToApprove = selectedElements.map((invoice: any) => {
      const status = {
        id: actionId[action].statusId,
      };
      return { id: invoice.id, status };
    });
    closeModal();
    const response: any = await BillingService.updateInvoices(invoicesToApprove)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response || error.message;
      });
    if (response.status === 200) {
      const toastText = `Invoice${invoicesToApprove.length > 1 ? "s" : ""} ${
        actionId[action].toastAction
      }`;
      dispatch(addToast({ mode: "success", message: toastText }));
      let commentToAdd = localStorage.getItem("inputModalComment");
      invoicesToApprove.forEach((invoice: any) => {
        if (invoice.status.id === 4 || invoice.status.id === 5) {
          saveComment(invoice.id, commentToAdd || "");
        }
      });
      callback();
    } else {
      dispatch(addToast({ mode: "error", message: "Something went wrong" }));
      response.data.forEach((err) => {
        dispatch(addToast({ mode: "error", message: err }));
      });
    }
  };

  return updateInvoices;
};
