import React, { FunctionComponent } from "react";
import styles from "./Tab.module.scss";

export interface TabLabelProps {
  active?: boolean;
  children: any;
  classes?: string;
  onClick: () => void;
}

export const TabLabel: FunctionComponent<TabLabelProps> = ({
  active,
  children,
  classes,
  onClick: clickHandler,
}) => {
  return (
    <div
      className={`${styles.tab} ${classes ? classes : ""} ${active ? "active" : ""}`}
      onClick={() => clickHandler()}
    >
      {children}
    </div>
  );
};
