import "../../styles/main.module.scss";
import { Calendar } from "../../SharedModule/components/Calendar";
import {
  clear,
  resetFilters,
  resetFiltersWithoutOwner,
  selectFilters,
  setFilters,
  selectEndmonthDate,
  selectMidmonthDate,
  selectRecurringDate,
  selectLastmonthDate,
} from "../redux/reducers/invoices.reducer";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { Select } from "../../SharedModule/components/Select";
import { selectClients, selectUsers } from "../redux/reducers/metaBilling.reducer";
import {
  APPROVED,
  EXPORTED,
  PENDING,
  RESET,
  ROLE_NOT_OWNERS,
  ROUTE_INVOICES_LIST,
  ROUTE_INVOICES_FILTERS,
  VOID,
  ROLE_BILLING_MODULE_PERFORM_SEARCH,
  INVOICE_OWNERS_LABEL,
} from "../../SharedModule/utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { DateTime } from "luxon";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import {
  AccessControl,
  useIsAllowed,
} from "../../SharedModule/components/AccessControl";
import Footer from "../../SharedModule/components/Footer";

const invoiceStatuses = [
  { value: PENDING, label: "Pending" },
  { value: APPROVED, label: "Approved" },
  { value: EXPORTED, label: "Exported" },
  { value: RESET, label: "Reset" },
  { value: VOID, label: "Void" },
];

export const InvoiceQuery = () => {
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters, shallowEqual);
  const users = useSelector(selectUsers);
  const clients = useSelector(selectClients);
  const navigate = useNavigate();
  const location = useLocation();

  const { trackPageView } = useMatomo();
  const { isAllowed } = useIsAllowed(ROLE_NOT_OWNERS);

  const resetPageFilters = () => {
    if (isAllowed) {
      dispatch(resetFilters());
    } else {
      // TO DO: should keep the owner in the filters
      dispatch(resetFiltersWithoutOwner());
    }
  };

  useEffect(() => {
    document.title = "The Hub - Filter by Invoice Details";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });
  }, []);

  useEffect(() => {
    dispatch(clear());
    // clean id filters
    dispatch(
      setFilters({
        internalNumbers: null,
        invoiceNumbers: null,
        idsInputValue: null,
      })
    );
  }, [dispatch, location]);

  enum periods {
    MIDMONTH,
    MONTHEND,
    RECURRING,
    LASTMONTH,
  }

  const changePeriod = (period: number) => {
    switch (period) {
      case periods.MIDMONTH:
        dispatch(selectMidmonthDate());
        break;
      case periods.MONTHEND:
        dispatch(selectEndmonthDate());
        break;
      case periods.RECURRING:
        dispatch(selectRecurringDate());
        break;
      case periods.LASTMONTH:
        dispatch(selectLastmonthDate());
        break;
    }
  };

  const goToInvoices = () => {
    navigate(ROUTE_INVOICES_LIST, { state: { from: ROUTE_INVOICES_FILTERS } });
  };

  const backDefaultInvoices = () => {
    resetPageFilters();
    navigate(ROUTE_INVOICES_LIST);
  };

  return (
    <div className="content">
      <div className="d-flex flex-wrap">
        <h2 className="flex-fill">Filter by Invoice Details</h2>
        <button
          className="btn button-secondary"
          onClick={() => backDefaultInvoices()}
        >
          Cancel Filters
        </button>
      </div>
      <form className="card invoice-summary-background p-4 mt-3">
        <div className="row justify-content-center">
          <label className="col-sm-3 col-form-label">Invoice Date</label>
          <div className="col-sm-5">
            <div className="d-flex">
              <div className="position-relative">
                <label className="form-label">From</label>
                <Calendar
                  date={filters.dateFrom}
                  onChange={(date: string) => {
                    dispatch(setFilters({ dateFrom: date }));
                  }}
                />
              </div>
              <div className="position-relative ms-5">
                <label className="form-label">To</label>
                <Calendar
                  date={filters.dateTo}
                  onChange={(date: string) => {
                    // End of day because endpoint takes hour.
                    dispatch(
                      setFilters({
                        dateTo: DateTime.fromISO(date, { zone: "utc" }).endOf(
                          "day"
                        ),
                      })
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="mt-2 offset-sm-5 col-sm-7">
            <div
              className="btn button-secondary"
              onClick={() => changePeriod(periods.MIDMONTH)}
            >
              Mid Month
            </div>
            <div
              className="btn button-secondary ms-2"
              onClick={() => changePeriod(periods.MONTHEND)}
            >
              Month's End
            </div>
            <div
              className="btn button-secondary ms-2"
              onClick={() => changePeriod(periods.RECURRING)}
            >
              Recurring
            </div>
            <div
              className="btn button-secondary ms-2"
              onClick={() => changePeriod(periods.LASTMONTH)}
            >
              Last Month
            </div>
          </div>
        </div>

        <div className="row mt-3 justify-content-center">
          <label className="col-sm-3 col-form-label">Invoice Status</label>
          <div className="col-sm-5">
            <Select
              isMulti
              options={invoiceStatuses}
              value={filters.statusIds}
              onChange={(statuses: any) => {
                dispatch(setFilters({ statusIds: statuses }));
              }}
              isDisabled={false}
            />
          </div>
        </div>

        <AccessControl allowedRoles={ROLE_BILLING_MODULE_PERFORM_SEARCH}>
          <div className="row mt-3 justify-content-center">
            <label className="col-sm-3 col-form-label">{INVOICE_OWNERS_LABEL}</label>
            <div className="col-sm-5">
              <Select
                isMulti
                options={users}
                value={filters.invoiceOwners}
                isLoading={!users}
                onChange={(owners: any) => {
                  dispatch(setFilters({ invoiceOwners: owners }));
                }}
                isDisabled={false}
              />
            </div>
          </div>
        </AccessControl>

        <div className="row mt-3 justify-content-center">
          <label className="col-sm-3 col-form-label">Clients</label>
          <div className="col-sm-5">
            <Select
              isMulti
              options={clients}
              value={filters.accountIds}
              isLoading={!clients}
              onChange={(clients: any) => {
                dispatch(setFilters({ accountIds: clients }));
              }}
              isDisabled={false}
            />
          </div>
        </div>

        <hr className="separator"/>
        <div className="mt-4 footer-filter">
          <div
            className="link-text"
            onClick={(e) => {
              e.preventDefault();
              resetPageFilters();
            }}
          >
            Reset Filters
          </div>
          <button
            className="btn btn-primary"
            onClick={(e) => {
              e.preventDefault();
              goToInvoices();
            }}
          >
            Filter Invoices
          </button>
        </div>
      </form>
      <Footer />
    </div>
  );
};
