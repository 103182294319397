import { useSelector } from "react-redux";
import { selectUserRoles } from "../redux/reducers/auth.reducer";
import { Role } from "../../types";
import { useIsAuthenticated } from "@azure/msal-react";

export const AccessControl = ({
  allowedRoles,
  fallback,
  children,
}: {
  allowedRoles: Role["name"][] | Role["name"];
  fallback?: React.JSX.Element;
  children: React.JSX.Element;
}) => {
  const { isAllowed } = useIsAllowed(allowedRoles);

  const isAuth = useIsAuthenticated();

  if (isAllowed && isAuth) return children;

  return fallback || null;
};

export const useIsAllowed = (
  allowedRoles: Role["name"][] | Role["name"],
): {
  activeUserId: string | null;
  activeUserName: string | null;
  isAllowed: boolean;
} => {
  const { activeUserId, activeUserName, userRoles } =
    useSelector(selectUserRoles);
  return {
    activeUserId: activeUserId || null,
    activeUserName: activeUserName || null,
    isAllowed:
      userRoles?.some((role) =>
        Array.isArray(allowedRoles)
          ? allowedRoles.find((allowedRole) => allowedRole === role.name)
          : allowedRoles === role.name
      ) || false,
  };
};
