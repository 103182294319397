import { ROLE_SUPER_ADMIN } from "./constants";

/* check access for The Hub AND Asuite roles
- first 2 params: ask is need to check roles access
- second 2 params: roles needed to access
- third 2 params: roles that user has in the hub and asuite
*/
export const verifyLinkAccess = (
  requireTheHubAccess: boolean | undefined,
  requireASuiteAccess: boolean | undefined,
  rolesTheHubAccess: string[] | undefined,
  rolesASuiteAccess: string[] | undefined,
  thehubRolesList: string[],
  asuitePermissionList: string[]
) => {
  let response: any = false;
  if (!requireTheHubAccess && !requireASuiteAccess) {
    response = true;
  } else if (requireTheHubAccess && !requireASuiteAccess) {
    response = rolesTheHubAccess?.some((elem) =>
      thehubRolesList.includes(elem)
    );
  } else if (!requireTheHubAccess && requireASuiteAccess) {
    response = rolesASuiteAccess?.some((elem) =>
      asuitePermissionList.includes(elem)
    );
  } else if (requireTheHubAccess && requireASuiteAccess) {
    response =
      rolesTheHubAccess?.some((elem) => thehubRolesList.includes(elem)) &&
      rolesASuiteAccess?.some((elem) => asuitePermissionList.includes(elem));
  }
  return response;
};

// Param is array of roles only for time module
export const hasSuperAdminAccess = (userRoles: any) => {
  let response: boolean = false;
  userRoles?.forEach((role) => {
    if (role.name === ROLE_SUPER_ADMIN) {
      response = true;
    }
  });
  return response;
};
