import { useDispatch } from "react-redux";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { ProductsService } from "../services/products";
import eventHandler from "../../SharedModule/utils/eventHandler";

export type editProductPayload = {
  id: number;
  projectName: string;
  client: any;
  projectList: any;
};

export const useEditProduct = (
  showModal: (options: any) => Promise<void>,
  closeModal: () => void,
  isEditMode: boolean
) => {
  const dispatch = useDispatch();
  const editProduct = async (payload: editProductPayload) => {
    const options = {
      title: `${isEditMode ? "Edit" : "Add"} Product?`,
      text: `Are you sure you want to ${
        isEditMode ? "edit" : "create"
      } this product?`,
      btnLabel: `Yes, ${isEditMode ? "Edit" : "Add"} Product`,
    };
    await showModal(options);
    closeModal();

    let result;
    if (isEditMode) {
      result = await ProductsService.editProduct(
        payload.id,
        payload.projectName.trim(),
        Number(payload.client),
        payload.projectList
      );
      if (result === "Product Edited successfully.") {
        dispatch(addToast({ mode: "success", message: result }));
      } else {
        dispatch(addToast({ mode: "error", message: "Something went wrong" }));
      }
    } else {
      result = await ProductsService.addProduct(
        payload.projectName.trim(),
        Number(payload.client),
        payload.projectList
      );
      if (result === "Product added successfully.") {
        dispatch(addToast({ mode: "success", message: result }));
      } else {
        dispatch(addToast({ mode: "error", message: "Something went wrong" }));
      }
    }
    eventHandler.dispatch("toast action", { isClosed: false });
  };

  return editProduct;
};
