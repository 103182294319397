import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IsLoadingData,
  LastSpotlight,
  SpotlightHistory,
  loadSpotlightData,
  SpotlightStatuses,
  IsSpotlightOn,
  GetActualProductName,
  setActualProductName,
  setIsSpotlightOn,
} from "../redux/reducers/spotlight.reducer";
import { SpotlightService } from "./../services/spotlight";

export const useSpotlights = (productId: number) => {
  const dispatch = useDispatch();
  const actualProductName = useSelector(GetActualProductName);
  const isLoadingData = useSelector(IsLoadingData);
  const isSpotlightOn = useSelector(IsSpotlightOn);
  const lastSpotlight = useSelector(LastSpotlight);
  const spotlightHistory = useSelector(SpotlightHistory);
  const spotlightStatuses = useSelector(SpotlightStatuses);

  const loadSpotlightInfo = useCallback(async () => {
    const lastSpotlight = await SpotlightService.getLastSpotlight(productId)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.response || error.message;
      });

    let spotlightHistory, spotlightStatuses;
    if (!lastSpotlight.data.errors){
      [spotlightHistory, spotlightStatuses] = await Promise.all([
        SpotlightService.getSpotlightHistory(productId),
        SpotlightService.getSpotlightStatuses(),
      ]);
      spotlightStatuses.sort((a: any, b: any) => a.sortOrder - b.sortOrder);
    }

    let action: any = {
      lastSpotlight: lastSpotlight.data,
      spotlightHistory: spotlightHistory ? spotlightHistory : null,
      spotlightStatuses: spotlightStatuses ? spotlightStatuses : null,
    };
    dispatch(loadSpotlightData(action));

    // set data for spotlight page
    dispatch(setActualProductName(lastSpotlight.data.productName));
    dispatch(setIsSpotlightOn(lastSpotlight.data.isSpotlightOn));
  }, [dispatch, productId]);

  useEffect(() => {
    loadSpotlightInfo();
  }, [loadSpotlightInfo]);

  return {
    actualProductName,
    lastSpotlight,
    spotlightHistory,
    isLoadingData,
    isSpotlightOn,
    spotlightStatuses,
  };
};
