import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TimesheetsService } from "../services/timesheets";
import {
  IsLoading,
  QueryResultList,
  Summary,
  loadQueryResultsInfo,
  setLoading,
} from "../redux/reducers/queryResult.reducer";
import {
  hasSuperTimeAdminAccess,
  hasTimeAdminAccess,
  isSupervisorOrProjectOwner,
  preparedFilters,
} from "../utils/timesUtils";
import {
  Holidays,
  ProjectList,
  SelectProjectOrWorkOrder,
} from "../redux/reducers/times.reducer";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";

export const useQueryResults = (filters: any) => {
  const dispatch = useDispatch();

  const projectList = useSelector(ProjectList);
  const selectProjectOrWorkOrder = useSelector(SelectProjectOrWorkOrder);
  const holidays = useSelector(Holidays);

  const MetaTimesInfo = useSelector(selectMetaTimesData);

  const filtersForRequest = preparedFilters(filters);

  const summary = useSelector(Summary);
  const queryResultList = useSelector(QueryResultList);

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);
  const isSuperTimeAdmin = hasSuperTimeAdminAccess(userRolesTimesModule);
  const isSupervisorOrPO = isSupervisorOrProjectOwner(userRolesTimesModule);

  const { activeUserId } = useSelector(selectUserRoles);

  const isLoading = useSelector(IsLoading);

  const loadQueryResultsPageInfo = useCallback(async () => {
    dispatch(setLoading(true));
    const queryResults = await TimesheetsService.getQueryResults(
      filtersForRequest
    );
    dispatch(loadQueryResultsInfo(queryResults.data.data));
  }, [dispatch, filters]);

  useEffect(() => {
    loadQueryResultsPageInfo();
  }, [loadQueryResultsPageInfo]);

  return {
    isLoading,
    summary,
    queryResultList,
    projectList,
    selectProjectOrWorkOrder,
    holidays,
    isTimeAdmin,
    isSuperTimeAdmin,
    isSupervisorOrPO,
    activeUserId,
    MetaTimesInfo,
    loadQueryResultsPageInfo,
  };
};
