import { InvoiceStatus } from "../../types";
import { APPROVED, EXPORTED, RESET, VOID } from "../../SharedModule/utils/constants";

export const useInvoiceDisabledActions = (
  status: InvoiceStatus | undefined,
  voidable: boolean | undefined
) => {
  // Default: Pending
  const disabled = {
    details: false,
    approve: false,
    reminder: false,
    reset: false,
    void: true,
  };

  if (typeof status === "undefined" || typeof voidable === "undefined")
    return disabled;

  if (status.id === APPROVED) {
    disabled.approve = true;
    disabled.reminder = true;
  }

  if (status.id === EXPORTED) {
    disabled.approve = true;
    disabled.reminder = true;
    disabled.void = !voidable;
  }

  if (status.id === RESET || status.id === VOID) {
    disabled.approve = true;
    disabled.reminder = true;
    disabled.reset = true;
  }

  return disabled;
};
