import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { useEffect } from "react";

export const IterableQuill = ({value} : any) => {
  // new react-quilljs properties
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: null,
    },
    clipboard: {
      matchVisual: true,
    },
  });

  useEffect(() => {
    // heres is where we set default value data
    if (quill && quill.clipboard) {
      quill.clipboard.dangerouslyPasteHTML(value);
      quillRef.current.firstChild.contentEditable = false;
    }
  }, [quill]);

  return (
    <div
      ref={quillRef}
      className="ql-disabled"
    />
  );
};
