import { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { usePortal } from "../hooks/usePortal";
import {
  removeToast as removeToastFromStore,
  selectToasts,
  setRemovingToast,
} from "../redux/reducers/ui.reducer";
import { uuid } from "../utils/uuid";
import { Toast } from "./Toast";
import styles from "./ToastPortal.module.scss";
import eventHandler from "../utils/eventHandler";
const portalId = `toasts-${uuid()}`;

type ToastPortalProps = {
  autoClose?: boolean;
  autoCloseTime?: number;
};

export const ToastPortal = ({
  autoClose = false,
  autoCloseTime = 3500,
}: ToastPortalProps) => {
  const { toasts, removing } = useSelector(selectToasts);
  const dispatch = useDispatch();

  const loaded = usePortal({
    element: "div",
    id: portalId,
    style: `position: fixed;
        width:400px;
        z-index:99;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);`,
  });

  useEffect(() => {
    if (removing) {
      dispatch(removeToastFromStore(removing));
      // should close toast before open add/edit modal in products list page
      eventHandler.dispatch("toast action", {isClosed: true});
    }
  }, [removing, dispatch]);

  useEffect(() => {
    if (autoClose && toasts.length) {
      const id = toasts[toasts.length - 1].id;
      setTimeout(() => {
        dispatch(setRemovingToast(id));
      }, autoCloseTime);
    }
  }, [toasts, autoClose, autoCloseTime, dispatch]);

  const removeToast = (id: string) => {
    dispatch(removeToastFromStore(id));
  };

  return loaded ? (
    ReactDOM.createPortal(
      <div className={styles.toastContainer}>
        {toasts.map((toast) => (
          <Toast
            key={toast.id}
            mode={toast.mode}
            message={toast.message}
            onClose={() => removeToast(toast.id)}
          />
        ))}
      </div>,
      document.getElementById(portalId) as Element
    )
  ) : (
    <></>
  );
};
