import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetAdvancedFilters,
  selectAdvancedFilters,
  setItem
} from "./../../redux/reducers/filters.reducer";
import { selectAdvancedFiltersOptions } from "../../redux/reducers/metaBilling.reducer";
import { FiltersSelect } from "./AdvancedFiltersSelect";
import { INVOICE_OWNERS_LABEL } from "../../../SharedModule/utils/constants";

export const AdvancedFilters = () => {
  const advancedFiltersOptions = useSelector(selectAdvancedFiltersOptions);
  const filtersState = useSelector(selectAdvancedFilters);
  const dispatch = useDispatch();
  const [projectsOrWO, setProjectsOrWO] = useState<any>();

  const metaLoaded = advancedFiltersOptions.loaded;

  const toggleFilters = (event: any) => {
    event.preventDefault();

    if (filtersState.showAdvancedFilters) {
      dispatch(resetAdvancedFilters());
      setProjectsOrWO(null);
    }
    dispatch(
      setItem({
        showAdvancedFilters: !filtersState.showAdvancedFilters,
      })
    );
  };

  useEffect(() => {
    const projects = filtersState.projects ? filtersState.projects : [];
    const workOrders = filtersState.workOrders ? filtersState.workOrders : [];
    const selectedProjectsOrWO = [...projects, ...workOrders];
    const value =
      selectedProjectsOrWO.length !== 0 ? selectedProjectsOrWO : null;
    setProjectsOrWO(value);
  }, [filtersState.projects, filtersState.workOrders]);

  const filterProjectOrWO = (selectedValue: any) => {
    if (!filtersState) return;
    setProjectsOrWO(selectedValue);
    const projects = selectedValue?.filter((item: any) => item.isProject);
    const workOrders = selectedValue?.filter((item: any) => !item.isProject);
    dispatch(setItem({ projects }));
    dispatch(setItem({ workOrders }));
  };

  return (
    <>
      <div className="row mt-4">
        <div className="col-sm-5 offset-sm-5">
          <div className="link-text px-0" onClick={toggleFilters}>
            {filtersState.showAdvancedFilters ? "Hide" : "Show"} additional
            filter options{" "}
            {filtersState.showAdvancedFilters
              ? "(reset filters and close)"
              : ""}
          </div>
        </div>
      </div>
      {filtersState.showAdvancedFilters && (
        <div>
          <FiltersSelect
            label="Clients"
            options={advancedFiltersOptions.clients}
            value={filtersState.clients}
            isLoading={!metaLoaded}
            onChange={(clients: any) => dispatch(setItem({ clients }))}
            isDisabled={false}
          />
          <FiltersSelect
            label="Projects"
            options={advancedFiltersOptions.projectsOrWO}
            value={projectsOrWO}
            isLoading={!metaLoaded}
            onChange={(projectOrWO: any) => filterProjectOrWO(projectOrWO)}
            isDisabled={false}
          />
          <FiltersSelect
            label={INVOICE_OWNERS_LABEL}
            options={advancedFiltersOptions.invoiceOwners}
            value={filtersState.invoiceOwners}
            isLoading={!metaLoaded}
            onChange={(invoiceOwners: any) =>
              dispatch(setItem({ invoiceOwners }))
            }
            isDisabled={false}
          />
          <FiltersSelect
            label="Departments"
            options={advancedFiltersOptions.departments}
            value={filtersState.departments}
            isLoading={!metaLoaded}
            onChange={(departments: any) => dispatch(setItem({ departments }))}
            isDisabled={true}
          />
          <FiltersSelect
            label="Users"
            options={advancedFiltersOptions.users}
            value={filtersState.employees}
            isLoading={!metaLoaded}
            onChange={(employees: any) => dispatch(setItem({ employees }))}
            isDisabled={false}
          />
        </div>
      )}
    </>
  );
};
