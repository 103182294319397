import React, { Component, FunctionComponent, useState } from "react";
import { TabProps } from "./Tab";
import { TabLabel } from "./TabLabel";

export const Tabs: FunctionComponent<any> = ({ children }) => {
  const [active, setActive] = useState(0);
  const labels: FunctionComponent[] = [];
  const components: any[] = [];

  React.Children.map(children, (child) => {
    labels.push(((child as Component)?.props as TabProps)?.label);
    components.push(child);
  });

  return (
    <>
      <hr style={{ margin: "0" }} />
      <table className="w-100 mt-4 bc-white">
        <thead>
          <tr className="sticky sticky-tabs bc-white" style={{ top: "60px" }}>
            <td className="row" style={{ width: "100%", paddingLeft: "1%" }}>
              {labels.map((button: any, i) => (
                <TabLabel
                  key={button.props.title}
                  active={active === i}
                  onClick={() => setActive(i)}
                >
                  {button}
                </TabLabel>
              ))}
            </td>
            <hr style={{ margin: "-1px 0 0 0" }} />
          </tr>
        </thead>
        {components[active]}
      </table>
    </>
  );
};
