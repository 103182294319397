import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import ReactDOM from "react-dom";
import { usePortal } from "../hooks/usePortal";
import { uuid } from "../utils/uuid";
import styles from "./ModalPortal.module.scss";
const portalId = `modals-${uuid()}`;

export const Modal = ({ children }: any) => {
  const handleClick = (e: any) => {
    e.stopPropagation();
  };

  let modalStyles = styles.modalContainer;
  if (children.props.type === 'formModal') {
    modalStyles = styles.formModal;
  }
  if (children.props.type === 'moreInfoModal') {
    modalStyles = styles.moreInfoModal;
  }
  return (
    <div className={modalStyles} onClick={handleClick}>
      {children}
    </div>
  );
};

export const ModalPortal = forwardRef(({ children, zIndex }: any, ref) => {
  const [show, setShow] = useState<any>(false);

  const loaded = usePortal({
    element: "div",
    id: portalId,
    style: `position: fixed;
    z-index:${zIndex ? zIndex : 98};
    `,
  });

  useEffect(() => {
    const handler = (event: any) => {
      if (event.keyCode === 27) {
        setShow(false);
      }
    };

    document.addEventListener("keydown", handler);

    return () => document.removeEventListener("keydown", handler);
  }, []);

  // Used to call add message from the component implementing it
  // using a ref, like:
  // toastRef.current.addMessage({ mode, message: text });
  useImperativeHandle(ref, () => ({
    show(value: any) {
      setShow(value);
    },
  }));

  const handleClick = () => {
    if (children.props.actionPlaceholder !== 'notCloseOnClickOutside'){
      setShow(false)
    }
  }

  return loaded ? (
    ReactDOM.createPortal(
      <>
        {show && (
          <div className={styles.modalBackdrop} onClick={handleClick}>
            <Modal>{children}</Modal>
          </div>
        )}
      </>,
      document.getElementById(portalId) as Element
    )
  ) : (
    <></>
  );
});
