import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TimesheetsService } from "../services/timesheets";
import { setLoading } from "../redux/reducers/approveTimeByUser.reducer";
import {
  hasSuperTimeAdminAccess,
  hasTimeAdminAccess,
  isSupervisorOrProjectOwner,
} from "../utils/timesUtils";
import { actualUIState } from "../../SharedModule/redux/reducers/uiShared.reducer";
import {
  IsLoading,
  loadTimeEntriesInfo,
  SelectNextPersonIndex,
  SelectSearchState,
} from "../redux/reducers/approveTimeByUser.reducer";
import {
  SelectTimeEntriesList,
  Summary,
} from "../redux/reducers/approveTimeByUser.reducer";
import { selectUserRoles } from "../../SharedModule/redux/reducers/auth.reducer";
import {
  Holidays,
  SelectProjectOrWorkOrder,
} from "../redux/reducers/times.reducer";
import { selectMetaTimesData } from "../redux/reducers/metaTimes.reducer";

export const useApproveTimeByUser = () => {
  const dispatch = useDispatch();

  const summary = useSelector(Summary);
  const isLoading = useSelector(IsLoading);
  const timeEntriesList = useSelector(SelectTimeEntriesList);

  const { activeUserId } = useSelector(selectUserRoles);
  const holidays = useSelector(Holidays);
  const selectProjectOrWorkOrder = useSelector(SelectProjectOrWorkOrder);

  const MetaTimesInfo = useSelector(selectMetaTimesData);
  const nextPersonIndex = useSelector(SelectNextPersonIndex);

  const searchState = useSelector(SelectSearchState);

  // times module admin
  const { userRolesTimesModule } = useSelector(actualUIState);
  const isTimeAdmin = hasTimeAdminAccess(userRolesTimesModule);
  const isSuperTimeAdmin = hasSuperTimeAdminAccess(userRolesTimesModule);
  const isSupervisorOrPO = isSupervisorOrProjectOwner(userRolesTimesModule);

  const prepareBody = (hoursType: string, user: any) => {
    if (hoursType && user) {
      switch (hoursType) {
        case "billableHours":
          return user.billableIds;
        case "nonBillableHours":
          return user.nonBillableIds;
        case "internalHours":
          return user.internalIds;
        case "workOrderHours":
          return user.workOrderIds;
        case "pendingHours":
          let totalIds = user.billableIds
            .concat(user.nonBillableIds)
            .concat(user.internalIds)
            .concat(user.workOrderIds);
          return totalIds;
        default:
          return [];
      }
    }
    return [];
  };

  const loadPageInfo = useCallback(async () => {
    dispatch(setLoading(true));
    const timeEntriesToApprove =
      await TimesheetsService.getTimeEntriesToApproveByUser(
        prepareBody(searchState.hoursType, searchState.user)
      );
    dispatch(loadTimeEntriesInfo(timeEntriesToApprove.data));
  }, [dispatch, searchState.user]);

  useEffect(() => {
    loadPageInfo();
  }, [loadPageInfo]);

  return {
    isTimeAdmin,
    isSuperTimeAdmin,
    isSupervisorOrPO,
    summary,
    isLoading,
    timeEntriesList,
    activeUserId,
    holidays,
    selectProjectOrWorkOrder,
    MetaTimesInfo,
    searchState,
    nextPersonIndex,
    loadPageInfo,
  };
};
