import { Link } from "react-router-dom";
import { CheckSkeleton } from "../../../SharedModule/utils/checkSkeleton";

export const BillableItemsSummary = ({
  isLoading,
  isRecurring,
  filtersLabels,
  editQueryLink,
}: any) => {
  // To avoid show departments with '-' that represents sub-departments
  filtersLabels.departments = filtersLabels.departments.replaceAll("-", "");

  const recurringLabels = (
    <div className="row my-3">
      <div className="col">
        <div className="row lh-lg">
          <div className="col-lg-3 col-12">Billing Cycle</div>
          <CheckSkeleton isLoading={isLoading} classes="col-4">
            <div className="col-9 fw-bold">{filtersLabels.billingCycles}</div>
          </CheckSkeleton>
        </div>
      </div>
      <div className="col">
        <div className="row lh-lg">
          <div className="col-lg-3 col-12">Clients</div>
          <CheckSkeleton isLoading={isLoading} classes="col-4">
            <div className="col-9 fw-bold">{filtersLabels.clients}</div>
          </CheckSkeleton>
        </div>
      </div>
    </div>
  );

  const professionalServicesLabels = (
    <div className="row my-3">
      <div className="col">
        <div className="row lh-lg">
          <div className="col-lg-3 col-12">Billing Cycle</div>
          <CheckSkeleton isLoading={isLoading} classes="col-4">
            <div className="col-9 fw-bold">{filtersLabels.billingCycles}</div>
          </CheckSkeleton>
        </div>
        <div className="row lh-lg">
          <div className="col-lg-3 col-12">Clients</div>
          <CheckSkeleton isLoading={isLoading} classes="col-4">
            <div className="col-9 fw-bold">{filtersLabels.clients}</div>
          </CheckSkeleton>
        </div>
        <div className="row lh-lg">
          <div className="col-lg-3 col-12">Projects</div>
          <CheckSkeleton isLoading={isLoading} classes="col-4">
            <div className="col-9 fw-bold">{filtersLabels.projects}</div>
          </CheckSkeleton>
        </div>
      </div>
      <div className="col">
        <div className="row lh-lg">
          <div className="col-lg-3 col-12">Owners</div>
          <CheckSkeleton isLoading={isLoading} classes="col-4">
            <div className="col-9 fw-bold">{filtersLabels.invoiceOwners}</div>
          </CheckSkeleton>
        </div>
        <div className="row lh-lg">
          <div className="col-lg-3 col-12">Departments</div>
          <CheckSkeleton isLoading={isLoading} classes="col-4">
            <div className="col-9 fw-bold">{filtersLabels.departments}</div>
          </CheckSkeleton>
        </div>
        <div className="row lh-lg">
          <div className="col-lg-3 col-12">Users</div>
          <CheckSkeleton isLoading={isLoading} classes="col-4">
            <div className="col-9 fw-bold">{filtersLabels.employees}</div>
          </CheckSkeleton>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="d-flex flex-wrap justify-content-between">
        {isRecurring && (
          <div className="flex-fill">
            <h2 style={{ marginRight: "2%", display: "inline-flex" }}>Recurring Billing Results:</h2>
            <CheckSkeleton
              isLoading={isLoading}
              classes="w-25 d-inline-flex"
              style={{ lineHeight: "1.8" }}
            >
              <h2 style={{display: "inline-flex" }}>{filtersLabels.postingDate}</h2>
            </CheckSkeleton>
          </div>
        )}
        {!isRecurring && (
          <div className="flex-fill">
            <h2 style={{ marginRight: "2%", display: "inline-flex" }}>Professional Services Results:</h2>
            <CheckSkeleton
              isLoading={isLoading}
              classes="w-25 d-inline-flex"
              style={{ lineHeight: "1.8" }}
            >
              <h2 style={{display: "inline-flex" }}>
                {filtersLabels.dateFrom} - {filtersLabels.dateTo}
              </h2>
            </CheckSkeleton>
          </div>
        )}
        <Link to={editQueryLink} className="btn button-secondary">
          Edit Query
        </Link>
      </div>
      {isRecurring ? recurringLabels : professionalServicesLabels}
    </>
  );
};
