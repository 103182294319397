import HttpService from "../utils/api";
const BASE_URL = "/users/Security";

function getUserRole(): Promise<any> {
  return HttpService.req(`${BASE_URL}/Roles/Mine`);
}

export const SecurityService = {
  getUserRole
};
