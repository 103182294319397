import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useModal } from "../../SharedModule/hooks/useModal";
import { useUpdateInvoices } from "../hooks/useUpdateInvoices";
import { useChangeOwner } from "../hooks/useChangeOwner";
import { selectUsers } from "../redux/reducers/metaBilling.reducer";
import { BillingService } from "../services/billing";
import { Invoice as InvoiceType } from "../../types";
import { Detail } from "../components/InvoiceDetailPage/InvoiceDetail";
import { ActivityAndComments } from "../components/InvoiceDetailPage/ActivityAndComments";
import { useSendReminder } from "../hooks/useSendReminder";
import { useIsAllowed } from "../../SharedModule/components/AccessControl";
import { ROLE_NOT_OWNERS, ROUTE_NOT_FOUND_PAGE } from "../../SharedModule/utils/constants";
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { Spinner } from "../../SharedModule/components/Spinner";
import Footer from "../../SharedModule/components/Footer";

export const Invoice = () => {
  let { id } = useParams<{ id: any }>();
  const [invoice, setInvoice] = useState<(InvoiceType & { selected?: boolean }) | null>(null);

  const users = useSelector(selectUsers);
  const navigate = useNavigate();
  const location = useLocation();
  const { trackPageView } = useMatomo();

  const { showModal, closeModal, Modal, ...modalProps } = useModal();

  const { activeUserId, isAllowed } = useIsAllowed(ROLE_NOT_OWNERS);

  const loadInvoice = useCallback(async () => {
    try {
      const invoice = await BillingService.getInvoice(id, true);
      if (typeof invoice === 'object'){
        invoice.activityLogs.reverse();
        setInvoice(invoice);

        // FF has some issue that sometimes not scroll to top on navigate to invoice detail
        setTimeout(() => {
          window.scrollTo(0, 0);
        }, 100);
      } else {
        navigate(ROUTE_NOT_FOUND_PAGE);
      }
    } catch (err: any) {
      navigate(ROUTE_NOT_FOUND_PAGE);
    }
  }, [id, activeUserId, navigate, isAllowed]);

  useEffect(() => {
    document.title = "The Hub - Invoice Details";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title
    });
    loadInvoice();
  }, [loadInvoice, location.pathname]);

  const changeOwner = useChangeOwner(showModal, closeModal, loadInvoice);

  const updateInvoice = useUpdateInvoices(
    showModal,
    closeModal,
    invoice && [invoice]
  );

  const sendReminders = useSendReminder(
    showModal,
    closeModal,
    loadInvoice,
    invoice && [invoice]
  );

  return (
    <div className="content">
      {invoice ? (
        invoice.id ? (
          <>
            <Modal closeModal={closeModal} {...modalProps} />
            <Detail
              invoice={invoice}
              users={users}
              updateInvoice={updateInvoice}
              changeOwner={changeOwner}
              sendReminders={sendReminders}
              loadInvoice={loadInvoice}
            />
            <ActivityAndComments 
              invoiceId={invoice.id.toString()}
              activityLogs={invoice.activityLogs}
              loadInvoice={loadInvoice} />
          </>
        ) : (
          <div>Invoice not found</div>
        )
      ) : (
        <Spinner style={{
          marginLeft: "50%",
          marginTop: "5%"
        }}/>
      )}
      <Footer />
    </div>
  );
};
