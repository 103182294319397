import { Invoice } from "../../../types";
import { RESET, VOID } from "../../../SharedModule/utils/constants";
import { dateFormat } from "../../../SharedModule/utils/formatters";

const projectsBaseUrl = process.env.REACT_APP_ASUITE_PROJECT_URI;
const workOrderBaseUrl = process.env.REACT_APP_ASUITE_WO_URI;

const PROJECT_ID = 1;
const RECURRING_BILLING_ID = 3;

const getBillingGroupUrl = (invoice: Invoice) => {
  return invoice.billingGroup.type.id === 1
    ? `${projectsBaseUrl}${invoice.billingGroup.externalId}`
    : `${workOrderBaseUrl}${invoice.billingGroup.externalId}`;
};

export const InvoiceHeader = ({ invoice }: { invoice: Invoice }) => {
  const stamp = invoice.status.id === RESET || invoice.status.id === VOID;
  const stampText = stamp && invoice.status.name;

  return (
    <div className="position-relative">
      {stamp && <div className="stamp">{stampText}</div>}
      <p className="mb-1">Bill To</p>
      <h2 className="h6 fw-bold">{invoice.account.name}</h2>
      <div className="d-flex mt-3">
        <div className="col">
          <div>Attn:</div>
          <div style={{ maxWidth: "180px" }}>
            {`${invoice.account.address1}
                ${invoice.account.address2}`}
          </div>
          <div>
            {invoice.account.city}, {invoice.account.state}{" "}
            {invoice.account.zipCode}
          </div>
        </div>
        <div className="col-5">
          <table className="align-top">
            <tbody>
              <tr>
                <td className="align-top">
                  {invoice.billingGroup.type.id === PROJECT_ID ||
                  invoice.billingGroup.type.id === RECURRING_BILLING_ID
                    ? "Project"
                    : "Work Order"}
                </td>
                <td className="border-start ps-3">
                  {invoice.billingGroup.type.id === RECURRING_BILLING_ID ? (
                    <span>{invoice.billingGroup.name}</span>
                  ) : (
                    <a
                      className="text-decoration-none"
                      href={getBillingGroupUrl(invoice)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      #{invoice.billingGroup.externalId} -{" "}
                      {invoice.billingGroup.name}
                    </a>
                  )}
                </td>
              </tr>
              <tr>
                <td className="align-top" style={{ minWidth: "100px" }}>
                  PO Number
                </td>
                <td className="border-start ps-3">
                  {invoice.purchaseOrderNumber || "-"}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col">
          <table>
            <tbody>
              <tr>
                <td className="align-top" style={{ minWidth: "100px" }}>
                  Issue Date
                </td>
                <td className="border-start ps-3">
                  {dateFormat(invoice.date)}
                </td>
              </tr>
              <tr>
                <td className="align-top">Due Date</td>
                <td className="border-start ps-3">
                  {dateFormat(invoice.dueDate)}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
