import { useRef, useState } from "react";
import { ModalLayout } from "../components/ModalLayout";
import { ModalPortal } from "../components/ModalPortal";

let confirmAction: Function;

type ModalOptions = {
  title: string;
  text: string;
  secondText: string;
  btnLabel: string;
  hasInput: boolean;
  actionPlaceholder: string;
};

export const useModal = () => {
  const modalRef = useRef();
  const [modalOptions, setModalOptions] = useState<ModalOptions>({
    title: "",
    text: "",
    secondText: "",
    btnLabel: "",
    hasInput: false,
    actionPlaceholder: "",
  });

  const closeModal = () => {
    (modalRef.current as any)?.show(false);
  };

  const showModal = async (options: any) => {
    setModalOptions(options);
    (modalRef.current as any).show(true);

    return new Promise<void>((resolve) => {
      confirmAction = (comment: string) => {
        localStorage.setItem("inputModalComment", comment);
        closeModal();
        resolve();
      };
    });
  };

  return { modalRef, modalOptions, showModal, closeModal, Modal };
};

const Modal = ({
  modalRef,
  modalOptions,
  closeModal,
}: {
  modalRef: React.MutableRefObject<undefined>;
  modalOptions: ModalOptions;
  closeModal: () => void;
}) => (
  <ModalPortal ref={modalRef} zIndex={100}>
    {
      <ModalLayout
        title={modalOptions.title}
        text={modalOptions.text}
        secondText={modalOptions.secondText}
        btnLabel={modalOptions.btnLabel}
        btnAction={confirmAction}
        btnCancel={closeModal}
        hasInput={modalOptions.hasInput}
        actionPlaceholder={modalOptions.actionPlaceholder}
      />
    }
  </ModalPortal>
);
