import { useEffect, useState } from "react";
import { FaAsterisk } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { Select } from "../../SharedModule/components/Select";
import { Calendar } from "../../SharedModule/components/Calendar";
import { useSuccessCriteria } from "../hooks/useSuccessCriteria";
import { useParams } from "react-router-dom";
import { SuccessCriteriaService } from "../services/successCriteria";
import { addToast } from "../../SharedModule/redux/reducers/ui.reducer";
import { useDispatch } from "react-redux";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import { setIsLoadingData } from "../redux/reducers/successCriteria.reducer";
import eventHandler from "../../SharedModule/utils/eventHandler";

type criteriaType = {
  criteriaTitle: string;
  criteriaText: string;
  status: any;
  lastVerifiedDate: Date;
};

export const ModalCriteria = ({
  title,
  btnLabel,
  btnCancelLabel,
  btnCancel,
  dataType,
  editMode,
  activeItem,
  onSuccessEvent
}: any) => {
  let { productId } = useParams();

  const dispatch = useDispatch();

  // new react-quilljs properties
  const placeholder = "Edit Your Content Here!";
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: [
        ["bold", "italic", "underline", "strike"],
        [{ align: [] }],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["clean"],
      ],
    },
    placeholder,
    clipboard: {
      matchVisual: false,
    },
  });

  const { successCriteriaStatuses } = useSuccessCriteria(Number(productId));

  let successCriteriaOptions = successCriteriaStatuses.map((elem: any) => {
    return { value: elem.id.toString(), label: elem.name };
  });

  const [state, setState] = useState<criteriaType>(
    editMode
      ? {
          criteriaTitle: activeItem.title,
          criteriaText: activeItem.description,
          status: successCriteriaOptions[Number(activeItem.status) - 1],
          lastVerifiedDate: activeItem.lastVerified,
        }
      : {
          criteriaTitle: "",
          criteriaText: "",
          status: successCriteriaOptions[0],
          lastVerifiedDate: new Date(),
        }
  );
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  const checkRequires = (field: string, value: string = "") => {
    if (field === "title") {
      setIsSaveDisabled(
        value.trim().length > 0 && state.criteriaText.trim().length > 0 ? false : true
      );
    } else if (field === "text") {
      setIsSaveDisabled(
        value.trim().length > 0 && state.criteriaTitle.trim().length > 0 ? false : true
      );
    } else {
      setIsSaveDisabled(
        state.criteriaTitle.trim().length > 0 && state.criteriaText.trim().length > 0
          ? false
          : true
      );
    }
  };

  useEffect(() => {
    // heres is where we set default value data
    if (quill) {
      quill.clipboard.dangerouslyPasteHTML(editMode ? activeItem.description : "");
    }
  }, [quill]);

  const setCriteriaText = (val: any) => {
    setState({ ...state, criteriaText: val });
  };

  const setCriteriaTitle = (evt: any) => {
    const value = evt.target.value;
    setState({ ...state, criteriaTitle: value });
    checkRequires("title", value);
  };

  const handleDate = (date: Date) => {
    setState({ ...state, lastVerifiedDate: date });
    checkRequires("empty");
  };

  const [maxChars, setMaxChars] = useState(
    10000 - state.criteriaText.replace(/<(.|\n)*?>/g, "").length
  );

  const checkCharacterCount = (event: any) => {
    setCriteriaText(quill.root.innerHTML);
    const unprivilegedEditor = quill.root.innerHTML;
    let totalChars = unprivilegedEditor.replace(/<(.|\n)*?>/g, "").length;
    if (totalChars > 0) {
      quillRef.current.__quill.root.dataset.placeholder = "";
    } else {
      quillRef.current.__quill.root.dataset.placeholder = placeholder;
    }
    setMaxChars(10000 - totalChars);
    if (totalChars === 0 || totalChars > 10000) {
      setIsSaveDisabled(true);
    } else {
      checkRequires("text", unprivilegedEditor);
    }
    if (unprivilegedEditor.length > 10000 && event.key !== "Backspace") {
      setIsSaveDisabled(true);
      event.preventDefault();
    }
  };

  const handleSave = async () => {
    let result: any;
    let body = {
      title: state.criteriaTitle.trim(),
      description: state.criteriaText.trim(),
      statusId: Number(state.status.value),
      lastVerified: state.lastVerifiedDate,
    };
    if (editMode) {
      result = await SuccessCriteriaService.updateSuccessCriteria(
        Number(productId),
        activeItem.successCriteriaId,
        body
      );
      if (result === "Success Criteria Updated Successfully.") {
        dispatch(addToast({ mode: "success", message: result }));
        onSuccessEvent();
        dispatch(setIsLoadingData(true));
        btnCancel(true);
      } else {
        dispatch(addToast({ mode: "error", message: "Something went wrong" }));
      }
    } else {
      result = await SuccessCriteriaService.addSuccessCriteria(
        Number(productId),
        body
      );
      if (result.message === "Success Criteria Added Successfully.") {
        dispatch(addToast({ mode: "success", message: result.message }));
        onSuccessEvent();
        dispatch(setIsLoadingData(true));
        btnCancel(true);
      } else {
        dispatch(addToast({ mode: "error", message: "Something went wrong" }));
      }
    }
    eventHandler.dispatch("toast action", { isClosed: false });
  };

  return (
    <div className="block" style={{ width: "100%" }}>
      <div className="block-header">
        <h5 style={{ fontWeight: "bold" }}>{title}</h5>
        <div className="d-flex justify-content-end">
          <IoMdClose
            size={"20px"}
            className="pointer grayMedium"
            onClick={btnCancel}
            style={{ marginTop: "-25px" }}
          />
        </div>
      </div>
      <div className="block-body">
        <div className="d-flex flex-column mb-2">
          <label className="form-label gray-label mb-1">
            {dataType === "criteria" ? "CRITERIA TITLE" : "ISSUE TITLE"}
            <span className="required">
              <FaAsterisk size={"6px"} />
            </span>
          </label>
          <div className="position-relative d-flex">
            <input
              type="text"
              name="criteriaTitle"
              className="form-control form-control-sm"
              placeholder={
                dataType === "criteria"
                  ? "Enter Title Of Criteria Here"
                  : "Enter Title Of Issue Here"
              }
              value={state.criteriaTitle}
              onChange={(event) => setCriteriaTitle(event)}
            />
          </div>
        </div>
        <div className="d-flex mt-4 mb-2">
          <div
            className="position-relative d-flex flex-column me-5"
            style={{ width: "30%" }}
          >
            <label className="form-label gray-label mb-1">
              STATUS{" "}
              <span className="required">
                <FaAsterisk size={"6px"} />
              </span>
            </label>
            <Select
              className="mb-2"
              options={successCriteriaOptions}
              isLoading={!successCriteriaOptions}
              value={{
                value: state.status.value,
                label: state.status.label,
              }}
              onChange={(name: any) => {
                setState({ ...state, status: name });
                checkRequires("empty");
              }}
              isDisabled={false}
            />
          </div>
          <div
            className="position-relative d-flex flex-column"
            style={{ width: "30%" }}
          >
            <label className="form-label gray-label mb-1">
              LAST VERIFIED ON{" "}
              <span className="required">
                <FaAsterisk size={"6px"} />
              </span>
            </label>
            <Calendar
              date={state.lastVerifiedDate}
              onChange={(date: Date) => handleDate(date)}
            />
          </div>
        </div>
        <div className="d-flex mt-4 flex-column mb-2">
          <label className="form-label gray-label mb-1">
            {dataType === "criteria"
              ? "WHAT IS THE CRITERIA? (PLEASE BE SPECIFIC)"
              : "DESCRIBE THE ISSUE AND A PLAN TO RESOLVE IT"}{" "}
            <span className="required">
              <FaAsterisk size={"6px"} />
            </span>
          </label>
          <div
            ref={quillRef}
            className="ql-enabled"
            onKeyUp={checkCharacterCount}
          />
          <div className="gray-label mt-2 align-self-end">
            {`Characters left: ${maxChars}`}
          </div>
        </div>
      </div>
      <div className="block-footer">
        <div className="d-flex mt-2">
          <button
            className="btn button-secondary w-100"
            onClick={btnCancel}
          >
            {btnCancelLabel}
          </button>
          <button
            className="btn btn-primary w-100 ms-2"
            disabled={isSaveDisabled}
            onClick={() => handleSave()}
          >
            {btnLabel}
          </button>
        </div>
      </div>
    </div>
  );
};
