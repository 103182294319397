import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { sortDataBy } from "../../../SharedModule/utils/dataSort";

const initialState = {
  milestones: {
    detail: null,
    count: 0,
    totalAmount: 0,
  },
  resources: {
    detail: null,
    count: 0,
    totalAmount: 0,
  },
  recurring: {
    detail: null,
    count: 0,
    totalAmount: 0,
  },
  excluded: {
    milestones: [],
    resources: [],
    recurring: [],
  },
};

export const billableItemsSlice = createSlice({
  name: "otherBillable",
  initialState,
  reducers: {
    loadMilestones: (state, action: any) => {
      state.milestones = action.payload;
      const excludedMilestones: any = [];
      action.payload.detail?.forEach((item: any) => {
        if (!item.achieved) {
          excludedMilestones.push(item);
        }
      });
      state.excluded.milestones = excludedMilestones;
    },
    loadResources: (state, action: any) => {
      state.resources = action.payload;
    },
    loadRecurring: (state, action: any) => {
      state.recurring = action.payload;
    },
    addToExcluded: (state: any, action: PayloadAction<any>) => {
      const { type, id } = action.payload;
      const excluded = [...state.excluded[type]];
      excluded.push(state[type].detail.find((item: any) => item.id === id));
      state.excluded[type] = excluded;
    },
    removeFromExcluded: (state: any, action: PayloadAction<any>) => {
      const { type, id } = action.payload;
      const excluded = [...state.excluded[type]].filter(
        (item) => item.id !== id
      );
      state.excluded[type] = excluded;
    },
    selectAll: (state: any, action: PayloadAction<any>) => {
      const { type } = action.payload;
      state.excluded[type] = [];
      if (type === "milestones") {
        const excludedMilestones: any = [];
        state.milestones.detail.forEach((item: any) => {
          if (!item.achieved) excludedMilestones.push(item);
        });
        state.excluded.milestones = excludedMilestones;
      }
    },
    deselectAll: (state: any, action: PayloadAction<any>) => {
      const { type } = action.payload;
      state.excluded[type] = [...state[type].detail];
    },
    clearBillableItems: () => {
      return initialState;
    },
  },
});

export const {
  loadMilestones,
  loadResources,
  loadRecurring,
  addToExcluded,
  removeFromExcluded,
  selectAll,
  deselectAll,
  clearBillableItems,
} = billableItemsSlice.actions;

export const selectMilestones = (state: any) => ({
  milestones: state.billableItems.milestones.detail,
  excluded: state.billableItems.excluded.milestones,
});

export const selectMilestonesOrderedBy = (
  sortBy: string,
  criteria: "asc" | "desc",
  dataType: "string" | "date" | "number"
) =>
  createSelector([selectMilestones], (milestones) => {
    return {
      milestones: sortDataBy(milestones.milestones, sortBy, criteria, dataType),
      excluded: milestones.excluded,
    };
  });

export const selectResources = (state: any) => ({
  resources: state.billableItems.resources.detail,
  excluded: state.billableItems.excluded.resources,
});

export const selectResourcesOrderedBy = (
  sortBy: string,
  criteria: "asc" | "desc",
  dataType: "string" | "date" | "number"
) =>
  createSelector([selectResources], (resources) => {
    return {
      resources: sortDataBy(resources.resources, sortBy, criteria, dataType),
      excluded: resources.excluded,
    };
  });

export const selectRecurring = (state: any) => ({
  recurring: state.billableItems.recurring.detail,
  excluded: state.billableItems.excluded.recurring,
});

export const selectRecurringOrderedBy = (
  sortBy: string,
  criteria: "asc" | "desc",
  dataType: "string" | "date" | "number"
) =>
  createSelector([selectRecurring], (recurring) => {
    return {
      recurring: sortDataBy(recurring.recurring, sortBy, criteria, dataType),
      excluded: recurring.excluded,
    };
  });

export const selectAreMilestones = createSelector(
  [selectMilestones],
  ({ milestones }: { milestones: any[] }) => {
    return milestones !== null && !milestones.length;
  }
);

export const selectAreResources = createSelector(
  [selectResources],
  ({ resources }: { resources: any[] }) => {
    return resources !== null && !resources.length;
  }
);

export const selectAreRecurring = createSelector(
  [selectRecurring],
  ({ recurring }: { recurring: any[] }) => {
    return recurring !== null && !recurring.length;
  }
);

export const selectCountsAndAmounts = (state: any) => ({
  counts: {
    resources: state.billableItems.resources.count || 0,
    milestones: state.billableItems.milestones.count || 0,
    recurring: state.billableItems.recurring.count || 0,
  },
  deselected: {
    resources: state.billableItems.excluded.resources.length,
    milestones: state.billableItems.excluded.milestones.length,
    recurring: state.billableItems.excluded.recurring.length,
  },
  amounts: {
    resources:
      state.billableItems.resources.totalAmount -
        state.billableItems.excluded.resources
          .map((item: any) => {
            return item.totalAmount;
          })
          .reduce((current: number, accum: number) => (accum += current), 0) ||
      0,
    milestones:
      state.billableItems.milestones.totalAmount -
        state.billableItems.excluded.milestones
          .map((item: any) => (item.achieved ? item.amount : 0))
          .reduce((current: number, accum: number) => (accum += current), 0) ||
      0,
    recurring:
      state.billableItems.recurring.totalAmount -
        state.billableItems.excluded.recurring
          .map((item: any) => item.amount)
          .reduce((current: number, accum: number) => (accum += current), 0) ||
      0,
  },
});

export const selectSelectedBillableItems = (state: any) => {
  return {
    resourcesIds:
      state.billableItems.resources.detail
        ?.filter(
          (resource: any) =>
            !state.billableItems.excluded.resources.some(
              (item: any) => item.id === resource.id
            )
        )
        .map((resource: any) => resource.id) || [],
    milestonesIds:
      state.billableItems.milestones.detail
        ?.filter(
          (milestones: any) =>
            !state.billableItems.excluded.milestones.some(
              (item: any) => item.id === milestones.id
            )
        )
        .map((milestone: any) => milestone.id) || [],
    recurringIds:
      state.billableItems.recurring.detail
        ?.filter(
          (recurring: any) =>
            !state.billableItems.excluded.recurring.some(
              (item: any) => item.id === recurring.id
            )
        )
        .map((recurring: any) => recurring.id) || [],
  };
};

export default billableItemsSlice.reducer;
