import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UsersService } from "../../../SharedModule/services/users";
import { ExternalService } from "../../services/external";
import {
  KeyValuePair,
  Project,
  SelectItem,
  Department,
  User,
  WorkOrder,
} from "../../../types";
import { formatForDropdowns } from "../../../SharedModule/utils/formatters";

export const fetchMetaBilling = async () => {
  const [users, asuiteOwners, workOrders, departments, ...rest] =
    await Promise.all([
      UsersService.getUsers(),
      ExternalService.getInvoiceOwners(),
      ExternalService.getWorkOrders(),
      ExternalService.getDepartments(),
      ExternalService.getBillingCycles(),
      ExternalService.getProjects(),
      ExternalService.getClients(),
    ]);

  const [billingCycles, projects, clients] = rest.map((metaItem) => {
    return metaItem.map(formatForDropdowns<KeyValuePair | Project>("name"));
  });

  return {
    invoiceOwners:
      (asuiteOwners &&
        asuiteOwners.map((owner) => ({
          value: owner.id,
          label: `${owner.lastName} ${owner.firstName}`,
        }))) ||
      [],
    workOrders: workOrders.map(
      formatForDropdowns<WorkOrder>("shortDescription")
    ),
    users,
    billingCycles,
    projects,
    clients,
    departments,
  };
};

// Users could be moved to a shared metaState (Shared module) if we split the hub in diff reacts app
// we keep users here to avoid restructure "selectAdvancedFiltersOptions" and avoid to duplicate users info (UsersService.getUsers)
export type MetaBillingState = {
  billingCycles: Array<SelectItem> | null;
  clients: Array<SelectItem> | null;
  projects: Array<SelectItem> | null;
  workOrders: Array<SelectItem> | null;
  invoiceOwners: Array<SelectItem> | null;
  departments: Array<Department> | null;
  users: Array<User> | null;
  loaded: boolean;
};

type SetItem = Partial<MetaBillingState>;

const initialState: MetaBillingState = {
  billingCycles: null,
  clients: null,
  projects: null,
  workOrders: null,
  invoiceOwners: null,
  departments: null,
  users: null,
  loaded: false,
};

export const metaSlice = createSlice({
  name: "metaBilling",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    setMetaBilling: (
      state: MetaBillingState,
      action: PayloadAction<SetItem>
    ) => {
      return { ...state, ...action.payload, loaded: true };
    },
  },
});

export const { setMetaBilling } = metaSlice.actions;

// Selectors
export const selectMetaLoaded = ({
  metaBilling,
}: {
  metaBilling: MetaBillingState;
}) => metaBilling.loaded;

export const selectBillingCycles = ({
  metaBilling,
}: {
  metaBilling: MetaBillingState;
}) => metaBilling.billingCycles;

export const selectUsers = ({
  metaBilling,
}: {
  metaBilling: MetaBillingState;
}) =>
  (metaBilling.users &&
    metaBilling.users.map((user) => ({
      value: user.id,
      label: user.fullName,
    }))) ||
  [];

export const selectClients = ({
  metaBilling,
}: {
  metaBilling: MetaBillingState;
}) => metaBilling.clients;

// helper to indent sub-deparments
const setLeftIndent = (dept: any) => {
  return "-".repeat(dept.level) + dept.name;
};

export const selectAdvancedFiltersOptions = createSelector(
  [(state: { metaBilling: MetaBillingState }) => state.metaBilling],
  (metaBilling) => ({
    ...metaBilling,
    users:
      metaBilling.users &&
      metaBilling.users.map((user) => ({
        value: user.asuiteId,
        label: user.fullName,
      })),
    projectsOrWO: [
      {
        label: "Projects",
        options: metaBilling.projects?.map((item) => ({
          ...item,
          isProject: true,
        })),
      },
      {
        label: "Work Orders",
        options: metaBilling.workOrders,
      },
    ],
    departments: [
      {
        label: "Departments",
        options: metaBilling.departments?.map((department) => ({
          value: department.id,
          label: setLeftIndent(department),
          level: department.level,
          path: department.path,
          parentId: department.parentId,
        })),
      },
    ],
  })
);

export const selectRecurringOptions = (state: {
  metaBilling: MetaBillingState;
}) => {
  return {
    loaded: selectMetaLoaded(state),
    billingCycles: selectBillingCycles(state),
    clients: selectClients(state),
  };
};

export default metaSlice.reducer;
