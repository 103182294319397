import { SuccessCriteriaData } from "../types/types";
import HttpService from "../../SharedModule/utils/api";
const BASE_URL = "/products/SuccessCriterias";

function getSuccessCriteriaList(
  productId: number
): Promise<SuccessCriteriaData> {
  return HttpService.req({
    url: `${BASE_URL}?productId=${productId}`,
    hasOwnHandler: true,
  });
}

function getSuccessCriteriaStatus<T>(): Promise<T> {
  return HttpService.req(`${BASE_URL}/Status`);
}

function addSuccessCriteria<T>(productId: number, body: any): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/Add?productId=${productId}`,
    data: { ...body },
  });
}

function updateSuccessCriteria<T>(
  productId: number,
  successCriteriaId: number,
  body: any
): Promise<T> {
  return HttpService.req({
    method: "put",
    url: `${BASE_URL}/Edit?productId=${productId}&successCriteriaId=${successCriteriaId}`,
    data: { ...body },
  });
}

function removeSuccessCriteria<T>(
  productId: number,
  successCriteriaId: number
): Promise<T> {
  return HttpService.req({
    method: "patch",
    url: `${BASE_URL}/Delete?productId=${productId}&successCriteriaId=${successCriteriaId}`,
  });
}

function achieveSuccessCriteria<T>(
  productId: number,
  successCriteriaId: number,
  isAcheived: boolean
): Promise<T> {
  return HttpService.req({
    method: "patch",
    url: `${BASE_URL}/Achieve?productId=${productId}&successCriteriaId=${successCriteriaId}&isAcheived=${isAcheived}`,
  });
}

export const SuccessCriteriaService = {
  getSuccessCriteriaList,
  getSuccessCriteriaStatus,
  addSuccessCriteria,
  updateSuccessCriteria,
  removeSuccessCriteria,
  achieveSuccessCriteria,
};
