import styles from "./Tooltip.module.scss";

export const Tooltip = ({
  children,
  message,
}: {
  children: React.JSX.Element | string | null;
  message: string;
}) => {
  return (
    <span className={styles.container}>
      {children}
      <div className={styles.tootip}>{message}</div>
    </span>
  );
};
