import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect } from "react";
import {
  actualUIState,
  setHours,
  setInfoUser,
  setRolesTimesModule,
} from "../../SharedModule/redux/reducers/uiShared.reducer";
import { UsersService } from "../services/users";
import { selectUserRoles } from "../redux/reducers/auth.reducer";
import { TimesheetsService } from "../../TimesModule/services/timesheets";

export const useTopNavigationInfo = () => {
  const dispatch = useDispatch();
  const { activeUserEmail } = useSelector(selectUserRoles);
  const {
    todayHours,
    weeklyHours,
    photo,
    links,
    modulePermissions,
    userRolesTimesModule,
    asuiteTimesPermissions,
  } = useSelector(actualUIState);

  const loadInfo = useCallback(async () => {
    if (activeUserEmail) {
      const [infoHours, userInfo, userRolesTimesModule] = await Promise.all([
        TimesheetsService.getWeeklyHours(),
        UsersService.getUserProfileInfo(activeUserEmail),
        TimesheetsService.getUserRolesForTimeModule(),
      ]);

      if (infoHours.data) {
        dispatch(setHours(infoHours.data));
      }
      if (userInfo) {
        dispatch(
          setInfoUser({
            photo: userInfo.photo,
            links: userInfo.links,
            modulePermissions: userInfo.modulePermissions,
            asuiteTimesPermissions: userInfo.asuitePermissions,
          })
        );
      }
      if (userRolesTimesModule && userRolesTimesModule.data) {
        dispatch(setRolesTimesModule(userRolesTimesModule.data));
      }
    }
  }, [dispatch, activeUserEmail]);

  useEffect(() => {
    loadInfo();
  }, [loadInfo]);

  return {
    todayHours,
    weeklyHours,
    photo,
    links,
    modulePermissions,
    userRolesTimesModule,
    asuiteTimesPermissions,
    loadInfo,
  };
};
