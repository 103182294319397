import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IsLoadingReports,
  load,
  selectOrderedBy,
  setIsLoading,
  SpotlightStatuses,
  loadStatuses,
} from "../redux/reducers/spotlightReport.reducer";
import { SpotlightService } from "../services/spotlight";
import { getAmountByStatus } from "../utils/overviewTableUtils";

export const useSpotlightReports = () => {
  const dispatch = useDispatch();

  const isLoadingReports = useSelector(IsLoadingReports);

  const { list: spotlightReports } = useSelector(selectOrderedBy);

  const spotlightStatusesToFilter = useSelector(SpotlightStatuses);

  const loadReports = useCallback(async () => {
    dispatch(setIsLoading(true));

    const spotlightStatusesToFilter: Array<any> =
      await SpotlightService.getSpotlightStatuses();

    const spotlightReports = await SpotlightService.getSpotlightSummary();

    spotlightStatusesToFilter.forEach((status: any) => {
      status.amount = getAmountByStatus(spotlightReports, status.id);
    });

    // SET FILTERS TAB ORDER MANUALLY FOR SPOTLIGHT SUMMARY REPORT 
    spotlightStatusesToFilter.forEach((elem) => {
      switch (elem.name) {
        case "No Major Issues":
          elem.tabOrder = 5;
          break;
        case "Cautionary":
          elem.tabOrder = 4;
          break;
        case "Critical":
          elem.tabOrder = 2;
          break;
        case "On Hold":
          elem.tabOrder = 6;
          break;
        case "Internal Critical":
          elem.tabOrder = 3;
          break;
      }
    });
    spotlightStatusesToFilter.push({
      amount: spotlightReports.length,
      id: 0,
      name: "All",
      sortOrder: -1,
      tabOrder: 1,
    });
    spotlightStatusesToFilter.sort((a: any, b: any) => a.tabOrder - b.tabOrder);

    dispatch(loadStatuses(spotlightStatusesToFilter));
    dispatch(load(spotlightReports.sort((a: any, b: any) => a.status.sortOrder - b.status.sortOrder)));
  }, [dispatch]);

  useEffect(() => {
    loadReports();
  }, [loadReports]);

  return {
    spotlightReports,
    loadReports,
    isLoadingReports,
    spotlightStatusesToFilter,
  };
};
