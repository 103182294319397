import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  load,
  IsLoadingProducts,
  selectOrderedBy,
  IsShowingActives,
  setIsLoadingProducts,
  IsLoadingExternalBilling,
  IsLoadingExternalProducts,
} from "../redux/reducers/products.reducer";
import { ProductsService } from "./../services/products";

export const useProducts = () => {
  const dispatch = useDispatch();
  const isLoadingProducts = useSelector(IsLoadingProducts);
  const isLoadingExternalBilling = useSelector(IsLoadingExternalBilling);
  const isLoadingExternalProducts = useSelector(IsLoadingExternalProducts);
  const isShowingActives = useSelector(IsShowingActives);
  const { list: products } = useSelector(selectOrderedBy);

  const loadProducts = useCallback(async () => {
    dispatch(setIsLoadingProducts(true));
    const products = await ProductsService.getAllProducts();
    dispatch(load(products));
  }, [dispatch]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  return {
    products,
    isLoadingProducts,
    isShowingActives,
    loadProducts,
    isLoadingExternalBilling,
    isLoadingExternalProducts
  };
};
