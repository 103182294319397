import colors from "./../../../src/styles/main.module.scss";

const base = {
  color: "white",
  fontWeight: "normal" as "normal",
  fontSize: "12px",
  borderRadius: "4px",
  textTransform: "capitalize" as "capitalize",
};

interface StatusStyles {
  pending: any;
  disabled: any;
  invoiced: any;
}

const statusStyles = {
  voided: {
    backgroundColor: colors.colorBadgeVoid,
  },
  reset: {
    backgroundColor: colors.colorBadgeReset,
  },
  pending: {
    backgroundColor: colors.colorBadgePending,
  },
  approved: {
    backgroundColor: colors.colorBadgeApproved,
  },
  exported: {
    backgroundColor: colors.colorBadgeExported,
  },
};

const getStyle = (status: any, isFullWidth: boolean) => {
  let style;
  status = status.toLowerCase();
  if (status === "voided") {
    style = statusStyles.voided;
  } else if (status === "reset") {
    style = statusStyles.reset;
  } else if (status === "pending") {
    style = statusStyles.pending;
  } else if (status === "approved") {
    style = statusStyles.approved;
  } else if (status === "exported") {
    style = statusStyles.exported;
  } else {
    style = statusStyles[status as keyof StatusStyles];
  }
  return { ...base, ...style, width: isFullWidth ? "100%" : "auto" };
};

export const Badge = ({ status, className, isFullWidth }: any) => {
  return (
    <span
      className={`badge ${className ? className : ""}`}
      style={getStyle(status, isFullWidth)}
    >
      {status}
    </span>
  );
};
