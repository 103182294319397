import { createSlice } from "@reduxjs/toolkit";
import { SuccessCriteria } from "../../types/types";

type SuccessCriteriaListState = {
  actualProductName: string;
  isLoadingData: boolean;
  isContentEditable: boolean;
  isProductActive: boolean;
  successCriteriaList: SuccessCriteria[];
  successCriteriaStatuses: [];
};

const initialState: SuccessCriteriaListState = {
  actualProductName: "",
  isLoadingData: true,
  isContentEditable: false,
  isProductActive: false,
  successCriteriaList: [],
  successCriteriaStatuses: []
};

export const successCriteriaListSlice = createSlice({
  name: "successCriteria",
  initialState,
  reducers: {
    loadSuccessCriteriaListData: (state, action) => {
      state.successCriteriaList = action.payload.successCriteriaList;
      state.successCriteriaStatuses = action.payload.successCriteriaStatuses;
      state.isLoadingData = false;
    },
    setIsLoadingData: (state, action) => {
      state.isLoadingData = action.payload;
    },
    setActualProductName: (state, action) => {
      state.actualProductName = action.payload;
    },
    setIsContentEditable: (state, action) => {
      state.isContentEditable = action.payload;
    },
    setIsProductActive: (state, action) => {
      state.isProductActive = action.payload;
    },
    clear: (state) => {
      state = initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadSuccessCriteriaListData,
  setIsLoadingData,
  setActualProductName,
  setIsContentEditable,
  setIsProductActive,
  clear,
} = successCriteriaListSlice.actions;

export const IsLoadingData = (state: any) => {
  return state.successCriteria.isLoadingData;
};

export const GetActualProductName = (state: any) => {
  return state.successCriteria.actualProductName;
};

export const GetIsContentEditable = (state: any) => {
  return state.successCriteria.isContentEditable;
};

export const GetIsProductActive = (state: any) => {
  return state.successCriteria.isProductActive;
};

export const GetSuccessCriteriaList = (state: any) => {
  return state.successCriteria.successCriteriaList;
};

export const SuccessCriteriaStatuses = (state: any) => {
  return state.successCriteria.successCriteriaStatuses;
};

export default successCriteriaListSlice.reducer;
