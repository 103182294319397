export const ModalDelete = ({
  title,
  productName,
  btnLabel,
  btnCancelLabel,
  btnAction,
  btnCancel,
  dataType
}: any) => {

  let message:string = dataType === 'product' ? 'Product' : 'Success Criteria';
  let name = !title ? productName() : null;

  return (
    <>
      {title && <h4 className="text-center text-capitalize">{title}</h4>}
      {!title && <h4 className="text-center text-capitalize">{`Confirm delete ${name}?`}</h4>}
      <div className="d-flex mt-4 flex-column mb-4">
        <label className="text-center">
          {`Are you sure to want to delete this ${message}?`}
        </label>
      </div>
      <div className="d-flex mt-2">
        <button
          className="btn button-secondary w-100"
          onClick={btnCancel}
        >
          {btnCancelLabel}
        </button>
        <button
          className="btn btn-primary w-100 ms-2"
          onClick={btnAction}
        >
          {btnLabel}
        </button>
      </div>
    </>
  );
};
