import {
  KeyContactsData,
  KeyContactDetail,
  ConfidenceHistory,
} from "./../types/types";
import HttpService from "../../SharedModule/utils/api";
const BASE_URL = "/products/KeyContacts";

function getKeyContacts(productId: number): Promise<KeyContactsData> {
  return HttpService.req({
    url: `${BASE_URL}?productId=${productId}`,
    hasOwnHanlder: true,
  });
}

function addKeyContact<T>(productId: number, contactId: number): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/Add?productId=${productId}&contactId=${contactId}`,
  });
}

function deleteKeyContact<T>(productId: number, contactId: number): Promise<T> {
  return HttpService.req({
    method: "patch",
    url: `${BASE_URL}/Remove?productId=${productId}&keyContactId=${contactId}`,
  });
}

function getKeyContactDetails(
  productId: number,
  contactId: number
): Promise<KeyContactDetail> {
  return HttpService.req({
    url: `${BASE_URL}/KeyContactDetails?productId=${productId}&keyContactId=${contactId}`,
    hasOwnHanlder: true,
  });
}

function editKeyContact<T>(
  productId: number,
  contactId: number,
  body: any
): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/UpdateContactDetails?productId=${productId}&keyContactId=${contactId}`,
    data: { ...body },
  });
}

function GetAdvocacyLevelStatuses<T>(): Promise<T> {
  return HttpService.req(`${BASE_URL}/GetAdvocacyLevelStatuses`);
}

function getConfidendeHistory(
  productId: number,
  contactId: number
): Promise<ConfidenceHistory[]> {
  return HttpService.req(
    `${BASE_URL}/ConfidenceHistory?productId=${productId}&keyContactId=${contactId}`
  );
}

export const KeyContactsService = {
  getKeyContacts,
  getKeyContactDetails,
  addKeyContact,
  editKeyContact,
  deleteKeyContact,
  GetAdvocacyLevelStatuses,
  getConfidendeHistory,
};
