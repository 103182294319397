import { TotalTile } from "../TotalTile";
import { TabsConfig } from "../../hooks/useBillableItemsTabs";
import { billableItemType } from "./../../redux/reducers/filters.reducer";
import { currencyFormat } from "../../../SharedModule/utils/formatters";
import { CheckSkeleton } from "../../../SharedModule/utils/checkSkeleton";

export const BillableItemsTotals = ({
  tabs,
  activeTabs,
  amounts,
  counts,
  deselected,
  grandTotal,
  isLoading
}: {
  tabs: TabsConfig;
  activeTabs: billableItemType[];
  amounts: any;
  counts: any;
  deselected: any;
  grandTotal: number;
  isLoading: any
}) => {
  return (
    <div className="card d-flex flex-row shadow-sm mt-4">
      <div className="p-4 border-end" style={{ width: "275px" }}>
        <div>Ready to Invoice</div>
        <CheckSkeleton isLoading={isLoading} classes="fs-4" style={{ width: '70%'}}>
          <div className="fs-4 fw-bold">{currencyFormat(grandTotal)}</div>
        </CheckSkeleton>
      </div>
      <div className="d-flex flex-fill flex-wrap">
        {activeTabs.map((el: billableItemType) => (
          <TotalTile
            isLoading={isLoading}
            key={el}
            title={tabs[el].title}
            tag={tabs[el].tag}
            amount={amounts[tabs[el].name]}
            count={counts[tabs[el].name] - deselected[tabs[el].name]}
          />
        ))}
      </div>
    </div>
  );
};
