import { Select } from "../../../SharedModule/components/Select";

interface FiltersSelectProps<T> {
  label: string;
  options: Array<T> | null;
  value: T;
  isLoading: boolean;
  onChange: (element: T) => void;
  className?: string;
  isDisabled?: boolean;
}

export const FiltersSelect = ({
  label,
  options,
  value,
  isLoading,
  onChange,
  className,
  isDisabled,
}: FiltersSelectProps<any>) => {
  return (
    <div className={className || "row justify-content-center mt-4"}>
      <label className="col-sm-3 col-form-label">{label}</label>
      <div className="col-sm-5">
        <Select
          isMulti
          options={options}
          value={value}
          isLoading={isLoading}
          onChange={onChange}
          isDisabled={isDisabled}
        />
      </div>
    </div>
  );
};
