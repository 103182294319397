import { Invoice } from "../../types";
import HttpService from "../../SharedModule/utils/api";
import { ROUTE_INVOICES_LIST } from "../../SharedModule/utils/constants";

const BASE_URL = ROUTE_INVOICES_LIST;

function getBillingMenus<T>(token: any): Promise<T> {
  return HttpService.req(`${BASE_URL}/BillingMenus`);
}

function getBillingMenu<T>(menuId: number): Promise<T> {
  return HttpService.req(`${BASE_URL}/BillingMenu/${menuId}`);
}

function searchInvoices<T>(body: any): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/Invoices/Search`,
    data: {
      ...body,
    },
  });
}

function exportInvoices<T>(body: any): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/Invoices/Export`,
    data: {
      ...body,
    },
  });
}

function getInvoice(
  invoiceId: string,
  includeActivityLogs: boolean
): Promise<Invoice> {
  return HttpService.req({
    method: "get",
    url: `${BASE_URL}/Invoices/${invoiceId}?includeActivityLogs=${includeActivityLogs}`,
  });
}

function addCommentToInvoice<T>(
  invoiceId: string,
  comment: string
): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/Invoices/AddComment?invoiceId=${invoiceId}&comment=${encodeURIComponent(
      comment
    )}`,
  });
}

function deleteComment<T>(commentId: string): Promise<T> {
  return HttpService.req({
    method: "delete",
    url: `${BASE_URL}/Invoices/DeleteComment?commentId=${commentId}`,
  });
}

function generateInvoices<T>(body: any): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/Invoices`,
    data: {
      ...body,
    }
  });
}

function sendReminder<T>(body: any): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/Invoices/SendReminder`,
    data: [...body],
  });
}

function updateInvoices<T>(body: any): Promise<T> {
  return HttpService.req({
    method: "put",
    url: `${BASE_URL}/Invoices`,
    data: [...body],
    hasOwnHandler: true,
  });
}

export const BillingService = {
  getBillingMenus,
  getBillingMenu,
  searchInvoices,
  exportInvoices,
  getInvoice,
  addCommentToInvoice,
  deleteComment,
  generateInvoices,
  updateInvoices,
  sendReminder,
};
