export const getSummaryStatusBackground = (statusId: number) => {
	let summaryStatus = "";
	switch (statusId) {
		case 1:
			summaryStatus = "spotlight-no-major-issues";
			break;
		case 2:
			summaryStatus = "spotlight-cautionary";
			break;
		case 3:
			summaryStatus = "spotlight-critical";
			break;
		case 4:
			summaryStatus = "spotlight-on-hold";
			break;
		case 5:
			summaryStatus = "spotlight-internal-critical";
			break;
	}
	return summaryStatus;
};

export const getAmountByStatus = (spotlightReports: any, id: number) => {
	let response = spotlightReports.filter((elem: any) => elem.status.id === id);
	return response.length;
};
