const resolve = (obj: any, path: string) => {
  let properties = Array.isArray(path) ? path : path.split(".");
  // When order is by a boolean, we should cast to string
  // because on 'false' value never reach the sort on next line (aprox. 23)
  if (path === "achieved") {
    return (properties as any).reduce(
      (prev: any, curr: string) => prev && prev[curr].toString(),
      obj
    );
  }
  // On recurring tab and column 'Billed through', this date could be null, so we set this dates
  // at first when order desc (just set value as '0').
  if (path === "billThruDate" && obj[path] === null) {
    return (properties as any).reduce((prev: any) => prev && "0", obj);
  }
  // On resources tab and column description, we use two diff properties to show info,
  // description and purpose (Resources.tsx), so we should use "purpose" when obj['description'] is null
  if (path === "description" && obj[path] === null) {
    return (properties as any).reduce(
      (prev: any) => prev && prev["purpose"] && prev["purpose"].toString(),
      obj
    );
  }

  // Spotlight report summary - successCriteriaAmount
  if (path === "successCriteriaAmount") {
    return (properties as any).reduce(
      (prev: any, curr: string) => prev && getNumberFromString(prev[curr]),
      obj
    );
  }

  // Spotlight report summary - keyContactsAmount
  if (path === "keyContactsAmount") {
    return (properties as any).reduce(
      (prev: any, curr: string) => prev && getKeyContactsAmount(prev[curr]),
      obj
    );
  }

  // Key Contacts - all props that could be null when no data
  if (
    (path === "confidenceLevel" ||
      path === "advocacyLevel" ||
      path === "discProfile" || 
      path === "relationshipSteward" || 
      path === "budget" || 
      path === "spentBillable" || 
      path === "spentNonBillable") &&
    obj[path] === null
  ) {
    return (properties as any).reduce(
      (prev: any) => prev && "0",
      obj
    );
  }

  return (properties as any).reduce(
    (prev: any, curr: string) => prev && prev[curr],
    obj
  );
};

// helper functions to order successCriteriaAmount by none, achieve and then numbers
const getNumberFromString = (prev) => {
  if (prev === "None") {
    return -2;
  } else if (prev === "Achieved") {
    return -1;
  } else {
    return Number(prev);
  }
};
// helper functions to order keyContactsAmount by none and then by number (3 out of 5 equals to -2)
const getKeyContactsAmount = (prev) => {
  if (prev === "None") {
    return 1;
  } else {
    // Considere number of 3 figures for key contacts
    let total = prev[9]
      .concat("", prev[10] ? prev[10] : "")
      .concat("", prev[11] ? prev[11] : "");
    return Number(prev[0]) - Number(total);
  }
};

export const sortDataBy = <T>(
  data: Array<T>,
  sortBy: string,
  criteria: "asc" | "desc",
  type: "date" | "string" | "number" | undefined
): T[] => {
  if (!data || resolve(data[0], sortBy) === null) return data;

  const result = data.slice().sort((a: T, b: T) => {
    let first, last;
    if (criteria === "asc") {
      first = a;
      last = b;
    } else {
      first = b;
      last = a;
    }
    switch (type) {
      case "date":
        return (
          new Date(resolve(first, sortBy) as unknown as string).getTime() -
          new Date(resolve(last, sortBy) as unknown as string).getTime()
        );
      case "string":
        return (resolve(first, sortBy) as unknown as string).localeCompare(
          resolve(last, sortBy) as unknown as string
        );
      case "number":
        return (
          (resolve(first, sortBy) as unknown as number) -
          (resolve(last, sortBy) as unknown as number)
        );
      default:
        return (resolve(first, sortBy) as any) - (resolve(last, sortBy) as any);
    }
  });

  return result;
};
