import image from "./../../styles/legacy/disc-profile-info.png";

export const ModalImage = ({
  btnCancel,
}: any) => {
  
  return (
    <>
      <div className="d-flex justify-content-end p-2">
        <div className="close-label" onClick={btnCancel}>Close</div>
      </div>
      <div className="image-container">
        <img src={image} alt="" className="image" style={{width: '100%'}}/>
      </div>
    </>
  );
};
