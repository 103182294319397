import {
  Account,
  BillingCycle,
  Department,
  DetailRequestBody,
  Holidays,
  InvoiceOwner,
  Project,
  TimeEntryDetail,
  TimeEntryHeader,
  WorkOrder,
} from "../../types";
import HttpService from "../../SharedModule/utils/api";
const BASE_URL = "/billing/External";

function getBillingCycles(): Promise<BillingCycle[]> {
  return HttpService.req(`${BASE_URL}/BillingCycles`);
}

function getClients(): Promise<Account[]> {
  return HttpService.req(`${BASE_URL}/Clients`);
}

function getProjects(): Promise<Project[]> {
  return HttpService.req(`${BASE_URL}/Projects`);
}

function getWorkOrders(): Promise<WorkOrder[]> {
  return HttpService.req(`${BASE_URL}/WorkOrders`);
}

function getDepartments(): Promise<Department[]> {
  return HttpService.req(`${BASE_URL}/Departments`);
}

function getInvoiceOwners(): Promise<InvoiceOwner[]> {
  return HttpService.req(`${BASE_URL}/InvoiceOwners`);
}

function getTimeEntries(body: any): Promise<TimeEntryHeader> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/TimeEntries/ToBill`,
    data: {
      ...body,
    },
  });
}

const { getTimeEntriesDetail, clearQueue } = (function timeEntriesDetails() {
  let queue: any[] = [];
  let recurring = 0;

  function clearQueue() {
    queue = [];
  }

  function getTimeEntriesDetail(
    body: DetailRequestBody
  ): Promise<TimeEntryDetail[]> {
    const next = () => {
      // Check if there's something queued and there's a free slot
      if (queue.length && recurring < 10) {
        const { callback, resolve, reject } = queue.shift();
        // Run queued
        callback()
          .then((data: any) => {
            // Resolve promise
            resolve(data);
          })
          .catch((err: any) => {
            // Reject promise
            reject(err);
          })
          .finally(() => {
            // Do either way when it ends
            recurring--;
            next();
          });
      }
    };

    // return promise
    return new Promise((resolve, reject) => {
      // schedule request
      queue.push({
        resolve,
        reject,
        callback: () => {
          recurring++;
          return HttpService.req({
            method: "post",
            url: `${BASE_URL}/TimeEntries/ToBill/Detail`,
            data: {
              ...body,
            },
          });
        },
      });

      next();
    });
  }

  return {
    getTimeEntriesDetail,
    clearQueue,
  };
})();

function getMilestonesDetail<T>(body: any): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/Milestones/ToBill/Detail`,
    data: {
      ...body,
    },
  });
}

function getResourcesDetail<T>(body: any): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/Resources/ToBill/Detail`,
    data: {
      ...body,
    },
  });
}

function getRecurringDetail<T>(body: any): Promise<T> {
  return HttpService.req({
    method: "post",
    url: `${BASE_URL}/Recurring/ToBill/Detail`,
    data: {
      ...body,
    },
  });
}

function getYearHolidays(country: string, year: number): Promise<Holidays[]> {
  return HttpService.req(`${BASE_URL}/Bamboo/Holidays/${country}/${year}`);
}

export const ExternalService = {
  getBillingCycles,
  getClients,
  getProjects,
  getWorkOrders,
  getDepartments,
  getInvoiceOwners,
  getTimeEntries,
  getTimeEntriesDetail,
  getMilestonesDetail,
  getResourcesDetail,
  getRecurringDetail,
  clearQueue,
  getYearHolidays,
};
