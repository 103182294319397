import TextField from "@mui/material/TextField";

export const InputNumberHours = ({ hours, setHours, className, disabled }) => {
  const handleChange = (val: number) => {
    if (val > 24 || val < 0) {
      setHours("");
      return;
    }
    let decimals = val.toString().split(".")[1];
    if (!decimals || decimals.length < 3) {
      setHours(val);
    }
  };

  return (
    <TextField
      className={className}
      type="number"
      value={hours}
      lang="en-EN"
      InputProps={{
        inputProps: {
          min: 0,
          max: "24",
          step: "0.25",
          defaultValue: "",
        },
      }}
      onChange={(event: any) => {
        handleChange(Number(event?.target.value));
      }}
      disabled={disabled}
    />
  );
};
