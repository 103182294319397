import { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useInvoiceDisabledActions } from "../../hooks/useInvoicesDisabledActions";
import useOnClickOutside from "../../../SharedModule/hooks/useOnClickOutside";
import { ReactComponent as More } from "../../../SharedModule/svg/ic-more.svg";

export const MoreActions = ({
  status,
  invoiceId,
  approveInvoices,
  resetInvoices,
  voidInvoices,
  sendReminders,
  voidable,
  editable,
}: any) => {
  const [open, setOpen] = useState(false);
  const dropdownRef: any = useRef();

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  const disabled = useInvoiceDisabledActions(status, voidable);

  return (
    <div className="position-relative">
      <More
        style={{ cursor: "pointer" }}
        onClick={() => setOpen((state: any) => !state)}
      />
      <div className={`dropdown-menu ${open ? "show" : ""}`} ref={dropdownRef}>
        <Link
          className={`dropdown-item ${disabled.details && "disabled"}`}
          to={`/billing/invoices/${invoiceId}`}
          state={{ from: "/billing/invoices" }}
        >
          View Details
        </Link>
        {editable && (
          <>
            <button
              className={`dropdown-item pe-auto ${
                disabled.approve && "disabled"
              }`}
              onClick={() => {
                approveInvoices();
                setOpen(false);
              }}
            >
              Approve invoice
            </button>
            <button
              className={`dropdown-item ${disabled.reminder && "disabled"}`}
              onClick={() => {
                sendReminders();
                setOpen(false);
              }}
            >
              Send reminder
            </button>
            <button
              className={`dropdown-item ${disabled.reset && "disabled"}`}
              onClick={() => {
                resetInvoices();
                setOpen(false);
              }}
            >
              Reset
            </button>
            <button
              className={`dropdown-item ${disabled.void && "disabled"}`}
              onClick={() => {
                voidInvoices();
                setOpen(false);
              }}
            >
              Void
            </button>
          </>
        )}
      </div>
    </div>
  );
};
