import { useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import useOnClickOutside from "../../SharedModule/hooks/useOnClickOutside";
import { ReactComponent as More } from "../../SharedModule/svg/ic-more.svg";
import { useSpotlights } from "../hooks/useSpotlights";

export const ProductActions = (props: any) => {
  let { productId } = useParams();

  const { isSpotlightOn } = useSpotlights(Number(productId));

  let historyLink = `/products/${productId}/spotlightHistory`;
  const [open, setOpen] = useState(false);
  const dropdownRef: any = useRef();

  const getSpotlightStateFromStorage = () => {
    let state = localStorage.getItem("spotlightStateChange");
    return state ? JSON.parse(state) : isSpotlightOn;
  };
  const isSpotlightOnValue = getSpotlightStateFromStorage();

  // Call hook passing in the ref and a function to call on outside click
  useOnClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  const toggleSpotlight = () => {
    props.toggleHandler();
    setOpen(false);
  };

  return (
    <>
      <div className="position-relative">
        <More
          style={{ cursor: "pointer", margin: "25% 0 0 5%" }}
          onClick={() => setOpen((state: any) => !state)}
        />
        <div
          className={`dropdown-menu ${open ? "show" : ""}`}
          ref={dropdownRef}
        >
          {props.isLogged && props.isContentEditable && props.isProductActive && (
            <div
              onClick={() => toggleSpotlight()}
              className="dropdown-item pointer"
            >
              {isSpotlightOnValue ? "Turn Off Spotlight" : "Turn On Spotlight"}
            </div>
          )}
          <Link className="dropdown-item" to={historyLink}>
            Status History
          </Link>
        </div>
      </div>
    </>
  );
};
