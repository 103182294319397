import { useEffect, useState } from "react";
import { Select } from "../../SharedModule/components/Select";
import { empty } from "../utils/timesUtils";
import { TimesheetsService } from "../services/timesheets";
import { ProjectOrWo } from "../types";

export const ModalActionChangeProject = ({
  btnAction,
  btnCancel,
  userListId,
}: any) => {
  const [project, setProject] = useState(empty);
  const [commonProjects, setCommonProjects] = useState([]);

  useEffect(() => {
    loadProjects();
  }, []);

  const loadProjects = async () => {
    let projects;
    let partial = await TimesheetsService.getProjectsByUsers(userListId);
    projects = partial.map((elem: ProjectOrWo) => ({
      value: elem.entityId,
      label:
        elem.entityId > 0
          ? elem.entityId + " - " + elem.entityName
          : elem.entityName,
      entityId: elem.entityId,
      categoryId: elem.categoryId,
      clientId: elem.clientId,
    }));
    setCommonProjects(projects);
  };

  return (
    <>
      <h4 className="text-capitalize">Change Project</h4>
      <label className="color-neutrals-800">
        Move entries to this project, task or work order.
      </label>
      <div className="d-flex my-4 flex-column">
        <label className="mb-1">Project / Work order</label>
        <div className="mb-2">
          <Select
            placeholder="All"
            options={commonProjects}
            value={project}
            onChange={(proj) => setProject(proj)}
            isDisabled={false}
          />
        </div>
      </div>
      <div className="d-flex mt-2">
        <button className="btn button-secondary w-100" onClick={btnCancel}>
          Cancel
        </button>
        <button
          className="btn btn-primary w-100 ms-2"
          onClick={() => btnAction(6, project)}
        >
          Save
        </button>
      </div>
    </>
  );
};
