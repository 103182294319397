import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { UserInfoLink } from "../../../types";

type UIState = {
  isLeftNavBarFixed: boolean;
  activeTab: string | null;
  todayHours: number;
  weeklyHours: number;
  photo: string | null | undefined;
  links: UserInfoLink[];
  modulePermissions: object;
  userRolesTimesModule: string[];
  asuiteTimesPermissions: string[];
  isLoadingUserInfo: boolean;
};

const initialState: UIState = {
  isLeftNavBarFixed: false,
  activeTab: null,
  todayHours: 0,
  weeklyHours: 0,
  photo: undefined,
  links: [],
  modulePermissions: {},
  userRolesTimesModule: [],
  asuiteTimesPermissions: [],
  isLoadingUserInfo: true,
};

export const uiSharedSlice = createSlice({
  name: "uiShared",
  initialState,
  reducers: {
    // Remember Redux Toolkit allows us to write "mutating" logic in reducers.
    toggleLeftNavBarFixed: (state) => {
      state.isLeftNavBarFixed = !state.isLeftNavBarFixed;
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      state.activeTab = action.payload;
    },
    setHours: (
      state,
      action: PayloadAction<{ totalHoursDay: number; totalHoursWeek: number }>
    ) => {
      state.todayHours = action.payload.totalHoursDay;
      state.weeklyHours = action.payload.totalHoursWeek;
    },
    setInfoUser: (
      state,
      action: PayloadAction<{ photo: string; links: UserInfoLink[], modulePermissions: object, asuiteTimesPermissions: string[] }>
    ) => {
      state.photo = action.payload.photo;
      state.modulePermissions = action.payload.modulePermissions;
      state.asuiteTimesPermissions = action.payload.asuiteTimesPermissions;
      let links = action.payload.links;
      if (links){
        state.links = links.sort((a: UserInfoLink, b: UserInfoLink) =>
          a.title.localeCompare(b.title)
        );
      }
    },
    setRolesTimesModule: (state, action: PayloadAction<any>) => {
      state.userRolesTimesModule = action.payload;
      state.isLoadingUserInfo = false;
    },
    clear: (state) => {
      state.todayHours = 0;
      state.weeklyHours = 0;
      state.photo = undefined;
      state.links = [];
      state.modulePermissions = {};
      state.userRolesTimesModule = [];
      state.asuiteTimesPermissions = [];
      state.isLoadingUserInfo = true;
    },
  },
});

export const {
  toggleLeftNavBarFixed,
  setActiveTab,
  setHours,
  setInfoUser,
  setRolesTimesModule,
  clear,
} = uiSharedSlice.actions;

// Selectors
export const actualUIState = ({ uiShared }: { uiShared: UIState }) => ({
  isLeftNavBarFixed: uiShared.isLeftNavBarFixed,
  activeTab: uiShared.activeTab,
  todayHours: uiShared.todayHours,
  weeklyHours: uiShared.weeklyHours,
  photo: uiShared.photo,
  links: uiShared.links,
  modulePermissions: uiShared.modulePermissions,
  userRolesTimesModule: uiShared.userRolesTimesModule,
  asuiteTimesPermissions: uiShared.asuiteTimesPermissions,
  isLoadingUserInfo: uiShared.isLoadingUserInfo
});

export default uiSharedSlice.reducer;
