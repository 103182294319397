import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";

export const exportToExcel = async (excelData: any, fileName: any) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spredsheetml.sheet;charset=UFT-8";
  const fileExtension = ".xlsx";
  let ws = XLSX.utils.json_to_sheet(excelData);
  // columns width
  ws["!cols"] = [
    { wpx: 60 },
    { wpx: 80 },
    { wpx: 70 },
    { wpx: 180 },
    { wpx: 240 },
    { wpx: 140 },
    { wpx: 60 },
    { wpx: 70 },
    { wpx: 130 },
  ];

  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};
