import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSummaryStatusBackground } from "../utils/overviewTableUtils";
import {
  changeOrder,
  selectOrderCriteria,
  setFilters,
} from "../redux/reducers/spotlightReport.reducer";
import { useSpotlightReports } from "../hooks/useSpotlightReports";
import { Spinner } from "../../SharedModule/components/Spinner";
import { spotlightSummaryReport } from "../types/types";
import {
  ROUTE_PRODUCT_SPOTLIGHT,
  ROUTE_PRODUCT_SPOTLIGHT_SUMMARY_REPORT,
} from "../../SharedModule/utils/constants";
import {
  currencyFormat,
  dateFormatLocal,
} from "../../SharedModule/utils/formatters";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { setIsLoadingData } from "../redux/reducers/spotlight.reducer";
import { setActiveTab } from "../../SharedModule/redux/reducers/uiShared.reducer";
import Footer from "../../SharedModule/components/Footer";

const columnHeaders: Array<any> = [
  {
    key: "status.sortOrder",
    type: "number",
    title: "Status",
    subtitle: null,
    columnSize: 5,
  },
  {
    key: "client",
    type: "string",
    title: "Client and Product",
    subtitle: null,
    columnSize: 15,
  },
  {
    key: "successCriteriaAmount",
    type: "number",
    title: "Success Criteria",
    subtitle: "Critical + Cautionary",
    columnSize: 10,
  },
  {
    key: "keyContactsAmount",
    type: "number",
    title: "Key Contacts",
    subtitle: "at Trust or Higher",
    columnSize: 10,
  },
  {
    key: "budget",
    type: "number",
    title: "Budget",
    subtitle: "Billable + Financed",
    columnSize: 10,
  },
  {
    key: "spentBillable",
    type: "number",
    title: "Spent",
    subtitle: "Billable + Financed",
    columnSize: 10,
  },
  {
    key: "spentNonBillable",
    type: "number",
    title: "Spent",
    subtitle: "Non-Billable",
    columnSize: 10,
  },
  {
    key: "comment",
    type: "string",
    title: "Comment",
    subtitle: "Latest",
    columnSize: 40,
  },
  {
    key: "lastUpdate",
    type: "date",
    title: "Updated",
    subtitle: "Date, name",
    columnSize: 10,
  },
];

let statusAmountFilter: Array<any> = [];

export const SpotlightSummaryReportPage = () => {
  const dispatch = useDispatch();
  const sortBy = useSelector(selectOrderCriteria);

  const { trackPageView } = useMatomo();

  useEffect(() => {
    document.title = "The Hub - Spotlight Summary";
    // matomo page tracker
    trackPageView({
      documentTitle: document.location.hostname + "/" + document.title,
    });

    dispatch(setActiveTab(ROUTE_PRODUCT_SPOTLIGHT_SUMMARY_REPORT));
  }, []);

  const { spotlightReports, isLoadingReports, spotlightStatusesToFilter } =
    useSpotlightReports();

  if (!isLoadingReports && spotlightStatusesToFilter.length > 0) {
    statusAmountFilter = spotlightStatusesToFilter;
  }

  const [filterState, setFilterState] = useState({
    statusId: 0,
    selectedCategoryItem: "All",
  });

  let listspotlightReports: any = spotlightReports;

  if (filterState.statusId) {
    listspotlightReports = spotlightReports?.filter(
      (elem: any) => elem.status.id === filterState.statusId
    );
  }

  const filterReportByStatus = (status: number) => {
    if (!isLoadingReports) {
      switch (status) {
        case 0:
          setFilterState({ statusId: 0, selectedCategoryItem: "All" });
          dispatch(setFilters({ status: 0 }));
          break;
        case 1:
          setFilterState({
            statusId: 1,
            selectedCategoryItem: "No Major Issues",
          });
          dispatch(setFilters({ status: 1 }));
          break;
        case 2:
          setFilterState({ statusId: 2, selectedCategoryItem: "Cautionary" });
          dispatch(setFilters({ status: 2 }));
          break;
        case 3:
          setFilterState({ statusId: 3, selectedCategoryItem: "Critical" });
          dispatch(setFilters({ status: 3 }));
          break;
        case 4:
          setFilterState({ statusId: 4, selectedCategoryItem: "On Hold" });
          dispatch(setFilters({ status: 4 }));
          break;
        case 5:
          setFilterState({
            statusId: 5,
            selectedCategoryItem: "Internal Critical",
          });
          dispatch(setFilters({ status: 5 }));
          break;
      }
    }
  };

  const goToSpotlightPage = () => {
    dispatch(setIsLoadingData(true));
  };

  return (
    <div className="content">
      <div className="button-header">
        <h2>Spotlight Summary Report</h2>
      </div>

      <div className="spotlight-report-summary-sort">
        {statusAmountFilter.map((status: any) => {
          return (
            <div
              key={status.id}
              className={`sort-status ${
                filterState.selectedCategoryItem === status.name
                  ? "selected"
                  : ""
              } ${isLoadingReports ? "disabled" : ""}`}
              onClick={() => filterReportByStatus(status.id)}
            >
              <p className="sort-title">{status.name}</p>
              <p className={`sort-bubble ${status.name.replace(/\s/g, "")}`}>
                {status.amount}
              </p>
            </div>
          );
        })}
      </div>

      {isLoadingReports && (
        <Spinner
          style={{
            marginLeft: "50%",
            marginTop: "5%",
          }}
        />
      )}

      {!isLoadingReports && (
        <table className="table grey-table-header-background mt-5">
          <thead className="align-middle sticky sticky-header">
            <tr>
              {columnHeaders.map((column: any) => {
                return (
                  <th
                    key={column.key}
                    className={`border-dark border-top column-${
                      column.columnSize
                    } ${
                      sortBy.orderBy === column.key
                        ? "sorted-" + sortBy.criteria
                        : ""
                    }`}
                    onClick={() =>
                      dispatch(
                        changeOrder({
                          orderBy: column.key,
                          dataType: column.type,
                        })
                      )
                    }
                    style={{ fontSize: "10px" }}
                  >
                    {column.title && (
                      <p className="main-title">{column.title}</p>
                    )}
                    {column.subtitle && (
                      <p className="helper-title">{column.subtitle}</p>
                    )}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {listspotlightReports &&
              listspotlightReports.length > 0 &&
              listspotlightReports?.map(
                (product: spotlightSummaryReport) => {
                  return (
                    <tr key={product.productId}>
                      <td
                        style={{ verticalAlign: "middle", padding: 0 }}
                        className={`spotlight-report-summary-status-parent ${getSummaryStatusBackground(
                          product.status.id
                        )}`}
                      >
                        <div className="spotlight-report-summary-status">
                          <div
                            className={`icon ${getSummaryStatusBackground(
                              product.status.id
                            )}`}
                          ></div>
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle", padding: 0 }}>
                        <div className="spotlight-report-summary-client-container">
                          <Link
                            to={ROUTE_PRODUCT_SPOTLIGHT.replace(
                              ":productId",
                              product.productId.toString()
                            )}
                            onClick={() => goToSpotlightPage()}
                          >
                            <p className="client-name">{product.client}</p>
                            <p className="product-name">
                              {product.productName}
                            </p>
                          </Link>
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle", padding: 0 }}>
                        <div className="spotlight-report-summary-value-block">
                          <p
                            className={`status-value ${
                              product.flaggedSuccessCriterias
                                ? "red"
                                : "none-red"
                            }`}
                          >
                            {product.successCriteriaAmount}
                          </p>
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle", padding: 0 }}>
                        <div className="spotlight-report-summary-value-block">
                          <p
                            className={`status-value ${
                              product.flaggedKeyContacts ? "red" : "none-red"
                            }`}
                          >
                            {product.keyContactsAmount}
                          </p>
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div
                          className={"spotlight-report-summary-budget-block"}
                        >
                          <p>
                            {product.budget === 0 || product.budget > 0
                              ? `${currencyFormat(product.budget)}`
                              : "-"}
                          </p>
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div className="spotlight-report-summary-budget-block">
                          <p>
                            {product.spentBillable === 0 ||
                            product.spentBillable > 0
                              ? `${currencyFormat(product.spentBillable)} `
                              : "-"}
                            {(product.spentBillable === 0 ||
                              product.spentBillable > 0) && (
                              <span>({product.spentBillablePercentage})</span>
                            )}
                          </p>
                        </div>
                      </td>
                      <td
                        style={{ verticalAlign: "middle" }}
                        className={`${
                          product.isSpentExceeded ? "spotlight-red" : ""
                        }`}
                      >
                        <div className="spotlight-report-summary-budget-block">
                          <p>
                            {product.spentNonBillable === 0 ||
                            product.spentNonBillable > 0
                              ? `${currencyFormat(product.spentNonBillable)} `
                              : "-"}
                            {(product.spentNonBillable === 0 ||
                              product.spentNonBillable > 0) && (
                              <span>
                                ({product.spentNonBillablePercentage})
                              </span>
                            )}
                          </p>
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle" }}>
                        <div className="spotlight-report-comments-block">
                          <div
                            className="comments long-text"
                            dangerouslySetInnerHTML={{
                              __html: product.comment,
                            }}
                          ></div>
                        </div>
                      </td>
                      <td style={{ verticalAlign: "middle", padding: 0 }}>
                        <div className="spotlight-report-updated-block">
                          <p className="date">{`${dateFormatLocal(
                            product.lastUpdate
                          )}`}</p>
                          <p className="name long-text">{product.updatedBy}</p>
                        </div>
                      </td>
                    </tr>
                  );
                }
              )}
          </tbody>
        </table>
      )}
      <Footer />
    </div>
  );
};
