import { createSlice } from "@reduxjs/toolkit";
import { groupByProperty } from "../../utils/timesUtils";
import { UserToApprove } from "../../types";

type ApprovalTimeByUserState = {
  isLoading: boolean;
  summary: {
    total: string;
    billable: string;
    nonBillable: string;
    internal: string;
    timeOff: string;
  };
  timeEntriesList: object;
  searchState: {
    user: UserToApprove | null;
    filters: any;
    hoursType: string;
  }
  nextPersonIndex: number;
};

const initialState: ApprovalTimeByUserState = {
  isLoading: true,
  summary: {
    total: "—",
    billable: "—",
    nonBillable: "—",
    internal: "—",
    timeOff: "—",
  },
  timeEntriesList: {},
  searchState: {
    user: null,
    filters: {},
    hoursType: "",
  },
  nextPersonIndex: -1,
};

export const approvalTimeByUserSlice = createSlice({
  name: "approvalTimeByUser",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadTimeEntriesInfo: (state, action) => {
      state.summary = action.payload.summary;
      // set some IDs to help super admin to select/unselect multiples
      let i = 1;
      action.payload.result.forEach((element) => {
        element.id = i;
        i += 1;
      });
      state.timeEntriesList = groupByProperty(
        action.payload.result,
        "entryDate"
      );
      state.isLoading = false;
    },
    setSearchState: (state, action) => {
      state.searchState = action.payload;
    },
    setNextPersonIndex: (state, action) => {
      state.nextPersonIndex = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, loadTimeEntriesInfo, setSearchState, setNextPersonIndex } =
  approvalTimeByUserSlice.actions;

// Selectors
export const IsLoading = (state: any) => {
  return state.approvalTimeByUser.isLoading;
};

export const Summary = (state: any) => {
  return state.approvalTimeByUser.summary;
};

export const SelectTimeEntriesList = (state: any) => {
  return state.approvalTimeByUser.timeEntriesList;
};

export const SelectSearchState = (state: any) => {
  return state.approvalTimeByUser.searchState;
};

export const SelectNextPersonIndex = (state: any) => {
  return state.approvalTimeByUser.nextPersonIndex;
};

export default approvalTimeByUserSlice.reducer;
