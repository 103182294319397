import { ActionCreatorWithoutPayload } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { MemoedBillableHours as BillableHours } from "../components/BillableItemsResultsPage/BillableHours";
import { Milestones } from "../components/BillableItemsResultsPage/Milestones";
import { Recurring } from "../components/BillableItemsResultsPage/Recurring";
import { Resources } from "../components/BillableItemsResultsPage/Resources";
import { loadHeaders } from "../redux/reducers/billableHours.reducer";
import {
  loadMilestones,
  loadRecurring,
  loadResources,
} from "../redux/reducers/billableItems.reducer";
import { billableItemType } from "../redux/reducers/filters.reducer";
import { ExternalService } from "../services/external";

type LoadingState = {
  billableHours: boolean | null;
  resources: boolean;
  milestones: boolean;
  recurring: boolean;
};

export type TabsConfig = {
  [k in billableItemType]: {
    name: "billableHours" | "resources" | "milestones" | "recurring";
    tag: string;
    title: string;
    component: ({ loading }: { loading: boolean }) => React.JSX.Element;
    service: <T>(body: any) => Promise<T>;
    callback: ActionCreatorWithoutPayload;
  };
};

export const useBillableItemsTabs = (filters: any, advancedFilters: any) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<LoadingState>({
    billableHours: null,
    resources: true,
    milestones: true,
    recurring: true,
  });

  const proServicesParams = useMemo(
    () => ({
      dateFrom: (() => {
        const from = DateTime.fromISO(filters.dateFrom);
        const fromUTC = DateTime.utc(
          from.year,
          from.month,
          from.day,
          from.hour,
          from.minute,
          from.second
        );
        return fromUTC.toString();
      })(),
      dateTo: (() => {
        const to = DateTime.fromISO(filters.dateTo);
        const toUTC = DateTime.utc(
          to.year,
          to.month,
          to.day,
          to.hour,
          to.minute,
          to.second
        );
        return toUTC.toString();
      })(),
      employeeIds: advancedFilters.employees?.map((item: any) => item.value),
      invoiceOwners: advancedFilters.invoiceOwners?.map(
        (item: any) => item.value
      ),
      projectIds: advancedFilters.projects?.map((item: any) => item.value),
      workOrderIds: advancedFilters.workOrders?.map((item: any) => item.value),
      accountIds: advancedFilters.clients?.map((item: any) => item.value),
      billingCycleIds: filters.billingCycles?.map((item: any) => item.value),
      includeWoAttachedToProject: filters.includeWO,
    }),
    [filters, advancedFilters]
  );

  const recurringParams = useMemo(
    () => ({
      dateTo: filters.postingDate,
      accountIds: proServicesParams.accountIds,
      billingCycleIds: proServicesParams.billingCycleIds,
    }),
    [filters, proServicesParams]
  );

  const loadFunction: (
    itemKey: string,
    service: any,
    params: any,
    callback: Function
  ) => void = async (itemKey, service, params, callback) => {
    setLoading((loading) => ({ ...loading, [itemKey]: true }));
    const data = await service(params);
    dispatch(callback(data));
    setLoading((loading) => ({ ...loading, [itemKey]: false }));
  };

  const tabs = useMemo(
    () =>
      ({
        [billableItemType.billableHours]: {
          name: "billableHours",
          title: "Billable Hours",
          tag: "entries",
          component: BillableHours,
          service: ExternalService.getTimeEntries,
          callback: loadHeaders,
        },
        [billableItemType.resources]: {
          name: "resources",
          title: "Resources",
          tag: "resources",
          component: Resources,
          service: ExternalService.getResourcesDetail,
          callback: loadResources,
        },
        [billableItemType.billingMilestones]: {
          name: "milestones",
          title: "Milestones",
          tag: "milestones",
          component: Milestones,
          service: ExternalService.getMilestonesDetail,
          callback: loadMilestones,
        },
        [billableItemType.recurring]: {
          name: "recurring",
          title: "Recurring",
          tag: "entries",
          component: Recurring,
          service: ExternalService.getRecurringDetail,
          callback: loadRecurring,
        },
      } as unknown as TabsConfig),
    []
  );

  return {
    proServicesParams,
    recurringParams,
    tabs,
    loadFunction,
    loading,
  };
};
