import { createSlice } from "@reduxjs/toolkit";
import { ProjectsWorkOrders } from "../../../types";

type EntryHourBoxState = {
  isLoading: boolean;
  projectsWorkOrders: ProjectsWorkOrders[];
};

const initialState: EntryHourBoxState = {
  isLoading: true,
  projectsWorkOrders: [],
};

export const EntryHourBoxSlice = createSlice({
  name: "entryHourBox",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    loadEntryHourBoxInfo: (state, action) => {
      state.projectsWorkOrders = action.payload.projectList;
      state.isLoading = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLoading, loadEntryHourBoxInfo } = EntryHourBoxSlice.actions;

// Selectors
export const IsLoading = (state: any) => {
  return state.entryHourBox.isLoading;
};

// To load select grouped by
export const SelectProjectOrWorkOrder = (state: any) => {
  return [
    {
      label: "Work Orders",
      options: state.entryHourBox.projectsWorkOrders?.filter((item) => {
        if (item.categoryId === 3) {
          return item;
        }
      }),
    },
    {
      label: "Projects",
      options: state.entryHourBox.projectsWorkOrders?.filter((item) => {
        if (item.categoryId !== 3) {
          return item;
        }
      }),
    },
  ];
};

export default EntryHourBoxSlice.reducer;
