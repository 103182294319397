export const ModalDeleteTimeEntry = ({ btnAction, btnCancel }: any) => {
  return (
    <>
      <h4 className="text-center text-capitalize">{`Confirm delete?`}</h4>
      <div className="d-flex mt-4 flex-column mb-4">
        <label className="text-center">
          Are you sure you want to delete this time entry?
        </label>
      </div>
      <div className="d-flex mt-2">
        <button className="btn button-secondary w-100" onClick={btnCancel}>
          Cancel
        </button>
        <button className="btn btn-primary w-100 ms-2" onClick={btnAction}>
          Delete
        </button>
      </div>
    </>
  );
};
