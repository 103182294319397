import React from "react";
import styles from "./Radio.module.scss";

export const Radio = ({ className, label, ...props }: any) => (
  <label className={`${styles.radio} ${styles.radio_before} ${className}`}>
    <span className={styles.radio__input}>
      <input type="radio" {...props} />
      <span className={styles.radio__control}></span>
    </span>
    <span className={styles.radio__label}>{label}</span>
  </label>
);
