export class EventEmitter {
  _events: any;

  constructor() {
    this._events = {};
  }

  on(name: string, listener: Function) {
    if (!this._events[name]) {
      this._events[name] = [];
    }

    this._events[name].push(listener);
    return true;
  }

  removeListener(name: string, listenerToRemove: Function) {
    if (!this._events[name]) {
      throw new Error(
        `Can't remove a listener. Event "${name}" doesn't exits.`
      );
    }

    const filterListeners = (listener: Function) =>
      listener !== listenerToRemove;

    this._events[name] = this._events[name].filter(filterListeners);
  }

  emit(name: string, data?: any) {
    if (!this._events[name]) {
      throw new Error(`Can't emit an event. Event "${name}" doesn't exits.`);
    }

    const fireCallbacks = (callback: Function) => {
      callback(data);
    };

    this._events[name].forEach(fireCallbacks);
  }
}
