import loadable from "@loadable/component"
import { IconBaseProps } from "react-icons/lib"

interface TypesPropsIcon {
  icon: string;
  propsIcon?: IconBaseProps;
  className: string
}

export default function DynamicBiIcon({ icon, propsIcon, className }: TypesPropsIcon): React.JSX.Element {
  const lib = icon.replace(/([a-z0-9])([A-Z])/g, '$1 $2').split(" ")[0].toLocaleLowerCase();
  const ElementIcon: any = loadable(() => import(`react-icons/${lib}/index.js`), {
    resolveComponent: (el: React.JSX.Element) => el[icon as keyof React.JSX.Element]
  });

  return <ElementIcon {...propsIcon} className={className}/>
}
