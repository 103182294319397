import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TimeEntryDetail as TimeEntryDetailType,
  TimeEntryHeader as TimeEntryHeaderType,
} from "../../../types";
import { sortDataBy } from "../../../SharedModule/utils/dataSort";

type TimeEntryHeader = TimeEntryHeaderType & { isProject: boolean };
type TimeEntryDetail = TimeEntryDetailType & { selected?: boolean };

type BillableHoursState = {
  totalCount: number;
  totalAmount: number;
  headers: {
    [id: string]: TimeEntryHeader;
  };
  details: {
    [id: string]: TimeEntryDetail[];
  };
  sorting: {
    [id: number]: {
      orderBy: "dateWorked";
      criteria: "desc";
      dataType: "date";
    };
  };
};

const initialState: BillableHoursState = {
  totalCount: 0,
  totalAmount: 0,
  headers: {},
  details: {},
  sorting: {},
};

export const billableHoursSlice = createSlice({
  name: "billableHours",
  initialState,
  reducers: {
    loadHeaders: (state, action: PayloadAction<TimeEntryHeader[]>) => {
      let totalCount = 0,
        totalAmount = 0;
      action.payload.forEach((header) => {
        const { projectId, workOrderId } = header;
        header.isProject = !!projectId;
        // Prefix added to prevent id collisions
        state.headers[
          header.isProject ? "PR-" + projectId : "WO-" + workOrderId
        ] = header;
        totalCount += header.count;
        totalAmount += header.totalAmount;
      });
      state.totalCount = totalCount;
      state.totalAmount = totalAmount;
    },
    loadDetail: (
      state,
      action: PayloadAction<{
        data: TimeEntryDetail[];
        uuid: string;
      }>
    ) => {
      const { data, uuid } = action.payload;
      state.details[uuid] = data?.map((item) => {
        item.selected = true;
        return item;
      });
    },
    toggleSelection: (
      state,
      action: PayloadAction<{ parentId: string; id: number }>
    ) => {
      const { parentId, id } = action.payload;
      const entries = state.details[parentId].map((element) => {
        if (element.id === id) {
          element.selected = !element.selected;
        }
        return element;
      });
      state.details[parentId] = entries;
    },
    selectAll: (state, action: PayloadAction<string>) => {
      const parentId = action.payload;
      const entries = state.details[parentId].map((element) => {
        element.selected = true;
        return element;
      });
      state.details[parentId] = entries;
    },
    deselectAll: (state, action: PayloadAction<string>) => {
      const parentId = action.payload;
      const entries = state.details[parentId].map((element) => {
        element.selected = false;
        return element;
      });
      state.details[parentId] = entries;
    },
    clear: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadHeaders,
  loadDetail,
  toggleSelection,
  selectAll,
  deselectAll,
  clear,
} = billableHoursSlice.actions;

export const selectBillableHoursHeaders = createSelector(
  ({ billableHours }: { billableHours: BillableHoursState }) => billableHours,
  (billableHours) => {
    return {
      billableHoursHeaders: Object.keys(billableHours.headers).map((key) => ({
        ...billableHours.headers[key],
        uuid: key,
      })),
    };
  }
);

export const selectAreBillableHours = createSelector(
  [selectBillableHoursHeaders],
  ({ billableHoursHeaders }) => {
    return billableHoursHeaders !== null && !billableHoursHeaders.length;
  }
);

const selectDetails = ({
  billableHours,
}: {
  billableHours: BillableHoursState;
}) => billableHours.details;

const selectAllDetailsAsList = createSelector(
  [({ billableHours }: { billableHours: BillableHoursState }) => billableHours],
  (billableHours) => {
    return Object.keys(billableHours.details).flatMap((key) => {
      return billableHours.details[key];
    });
  }
);

export const selectOrderedBy = (
  id: string,
  sortBy: keyof TimeEntryDetail,
  criteria: "asc" | "desc",
  dataType: "string" | "date"
) =>
  createSelector([selectDetails], (details) => {
    return sortDataBy(details[id], sortBy, criteria, dataType);
  });

export const selectCountAndAmount = createSelector(
  [
    ({ billableHours }: { billableHours: BillableHoursState }) => ({
      totalAmount: billableHours.totalAmount,
      totalCount: billableHours.totalCount,
    }),
    selectAllDetailsAsList,
  ],
  ({ totalCount, totalAmount }, details) => {
    let count = 0,
      amount = 0;
    details.forEach((entry) => {
      if (entry.selected) {
        count++;
        amount += entry.amount || 0;
      }
    });
    return {
      totalCount: totalCount,
      totalAmount: totalAmount,
      count,
      amount,
    };
  }
);

export const selectAllDetailsLoaded = (state: any) => {
  const headersLength = Object.keys({
    ...state.billableHours.headers,
  }).length;

  const detailsLength = Object.keys({
    ...state.billableHours.details,
  }).length;

  return headersLength > 0 ? headersLength === detailsLength : true;
};

export const selectedBillableHoursIds = createSelector(
  [selectAllDetailsAsList],
  (details) =>
    details.filter((entry) => entry.selected).map((entry) => entry.id)
);

export const selectProjectWOTotalAmount = createSelector(
  [selectDetails],
  (details) => {
    const result: { [uuid: string]: number } = {};
    Object.keys(details).forEach((key) => {
      result[key] = details[key].reduce(
        (accum, entry) => (accum += (entry.selected && entry.amount) || 0),
        0
      );
    });
    return result;
  }
);

export default billableHoursSlice.reducer;
