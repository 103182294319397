import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetActualProductName,
  IsLoadingData,
  GetKeyContactList,
  loadKeyContactListData,
  setActualProductName,
  setIsContentEditable,
  GetIsContentEditable,
  selectOrderedBy,
  GetIsProductActive,
  setIsProductActive,
} from "../redux/reducers/keyContact.reducer";
import { KeyContactsService } from "./../services/keyContacts";

export const useKeyContacts = (productId: number) => {
  const dispatch = useDispatch();
  const actualProductName = useSelector(GetActualProductName);
  const isContentEditable = useSelector(GetIsContentEditable);
  const isProductActive = useSelector(GetIsProductActive);
  const isLoadingData = useSelector(IsLoadingData);
  const keyContactList = useSelector(GetKeyContactList);

  // could be load with an endpoint
  const trendingOptions = [
    { value: 1, label: "Up" },
    { value: 2, label: "Down" },
    { value: 3, label: "Same" },
  ];

  const { keyContactList: keyContacts } = useSelector(selectOrderedBy);

  const loadKeyContactsInfo = useCallback(async () => {
    const keyContactsData = await KeyContactsService.getKeyContacts(productId)
      .then((data) => {
        return data;
      })
      .catch((error) => {
        return error.data;
      });

    let action: any = { keyContactList: keyContactsData.errors ? keyContactsData : keyContactsData.keyContactList };
    dispatch(loadKeyContactListData(action));

    dispatch(setActualProductName(keyContactsData.productName));

    dispatch(setIsContentEditable(keyContactsData.isContentEditable));

    dispatch(setIsProductActive(keyContactsData.isProductActive));
  }, [dispatch, productId]);

  useEffect(() => {
    loadKeyContactsInfo();
  }, [loadKeyContactsInfo]);

  return {
    keyContacts,
    actualProductName,
    isContentEditable,
    isProductActive,
    isLoadingData,
    keyContactList,
    trendingOptions,
  };
};
