export const NoContent = ({
  title,
  text,
  subText,
  action,
  image,
  style,
  conditionButton = false,
  disabledButton = false,
  textButton,
}: {
  title: string;
  text?: any;
  subText?: string[];
  action?: any;
  image: string;
  style?: any;
  conditionButton?: boolean;
  disabledButton?: boolean;
  textButton?: string;
}) => (
  <div className="no-data-yet" style={style}>
    <img src={image} alt="" className="image" />
    <h5 className="fw-600">{title}</h5>
    <h6 className="fw-400">{text}</h6>
    {subText &&
      subText.length > 0 &&
      subText.map((elem: string) => {
        return (
          <span className="fw-400" key={elem}>
            {elem}
          </span>
        );
      })}

    {conditionButton && (
      <button
        onClick={action}
        className="btn button-secondary"
        disabled={disabledButton}
      >
        {textButton}
      </button>
    )}
  </div>
);
