import { useState } from 'react';

export const ModalLayout = ({
  title,
  text,
  secondText,
  btnLabel,
  btnCancelLabel,
  btnAction,
  btnCancel,
  hasInput,
  actionPlaceholder,
}: any) => {
  const [comment, setComment] = useState('');
  const handleChange = (comment: string) => {
    setComment(comment);
  }
  return (
    <>
      <h4 className="text-center text-capitalize">{title}</h4>
      <p className="text-center mt-2">{text}</p>
      {secondText && <p className="text-center">{secondText}</p>}
      {hasInput && <div className="d-flex mt-4">
        <input
          type="text"
          className="form-control form-control-sm mb-3"
          placeholder={`Please enter a reason for the ${actionPlaceholder}...`}
          value={ comment }
          onChange={(event) => handleChange(event.target.value)}
            />
      </div>}
      <div className="d-flex mt-4">
        {btnCancel && <button
          className="btn button-secondary w-100"
          onClick={btnCancel}
        >
          {btnCancelLabel ? btnCancelLabel : 'No'}
        </button>}
        <button
          className={`btn btn-primary ${btnCancel ? "w-100 ms-2" : "w-50 m-auto"}`}
          disabled={hasInput && comment.length === 0}
          onClick={() => {
            btnAction(comment);
          }}
        >
          {btnLabel}
        </button>
      </div>
    </>
  );
};
