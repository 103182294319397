import { CSSProperties } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

export const CheckSkeleton = ({
  isLoading,
  children,
  classes,
  baseColor,
  highlightColor,
  style
}: {
  isLoading: boolean;
  children: React.JSX.Element;
  classes?: string;
  baseColor?: string;
  highlightColor?: string,
  style?: CSSProperties
}) => {

  const bColor = baseColor ? `#${baseColor}` : '#CDCDCD';
  const hColor = highlightColor ? `#${highlightColor}` : '#9A9A9A';
  if (!isLoading) return children;

  return (
    <Skeleton 
      containerClassName={classes} 
      baseColor={bColor}
      highlightColor={hColor}
      style={style}
    />
    )
};