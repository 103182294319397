import Footer from "../components/Footer";
import { useSelector } from "react-redux";
import { selectUserRoles } from "../redux/reducers/auth.reducer";
import image from "./../../../src/styles/legacy/landing-empty.svg";
import { useEffect } from "react";

export const HomePage = () => {
  const { activeUserName } = useSelector(selectUserRoles);

  useEffect(() => {
    document.title = "The Hub - Home";
  }, []);

  return (
    <>
      <div className="content">
        <div className="d-flex flex-wrap text-center flex-column mt-5">
          <div className="color-neutrals-800">
            Hi {activeUserName?.split(" ")[0]} 👋
          </div>
          <h1 className="mt-2">Welcome to The Hub</h1>
          <div className="mt-1">
            New features are coming soon! Your patience is golden as we craft
            something amazing together.
          </div>
          <a
            className="link-text mt-4 fw-500"
            href="mailto:asuitesupport@itx.com"
            style={{ textDecoration: "underline", margin: "auto" }}
          >
            Provide Feedback
          </a>
          <img src={image} alt="" className="image mt-5" />
        </div>
        <Footer />
      </div>
    </>
  );
};
