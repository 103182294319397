import { ReactComponent as Warning } from "../../SharedModule/svg/ic-warning-sign.svg";
import styles from "./MessageIcon.module.scss";

export const MessageIcon = ({ message }: { message: string }) => {
  return (
    <div className={styles.container}>
      <Warning />
      <div className={styles.tootip}>{message}</div>
    </div>
  );
};
