import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DateTime } from "luxon";
import {
  ALL_CLIENTS,
  ALL_CYCLES,
  ALL_DEPARTMENTS,
  ALL_OWNERS,
  ALL_PROJECTS_AND_WORK_ORDERS,
  ALL_USERS,
} from "../../../SharedModule/utils/constants";
import { MetaBillingState } from "./metaBilling.reducer";
import {
  LAST_MONTHS_END,
  LAST_MONTHS_START,
  NOW,
  THIS_MONTHS_START,
} from "./../../../SharedModule/utils/dateUtils";

const DEFAULT_BILLING_CYCLE = [{ value: 3, label: "ITX Standard" }];

export enum billableItemType {
  billableHours,
  resources,
  billingMilestones,
  recurring,
}

type SelectItem = {
  value: number;
  label: string;
};

export type FiltersState = {
  dateFrom: string;
  dateTo: string;
  postingDate: string;
  selectedCategories: Array<number>;
  includeWO: boolean;
  billingCycles: Array<SelectItem> | null;
  clients: Array<SelectItem> | null;
  projects: Array<SelectItem> | null;
  workOrders: Array<SelectItem> | null;
  invoiceOwners: Array<SelectItem> | null;
  departments: Array<SelectItem> | null;
  employees: Array<SelectItem> | null;
  showAdvancedFilters: boolean;
};

export type SetItemPayload = Partial<FiltersState>;

const initialState: FiltersState = {
  dateFrom: LAST_MONTHS_START.toISO(),
  dateTo: LAST_MONTHS_END.toISO(),
  postingDate: THIS_MONTHS_START.toISO(),
  billingCycles: DEFAULT_BILLING_CYCLE,
  selectedCategories: [
    billableItemType.billableHours,
    billableItemType.billingMilestones,
    billableItemType.resources,
  ],
  includeWO: true,
  clients: null,
  projects: null,
  workOrders: null,
  invoiceOwners: null,
  departments: null,
  employees: null,
  showAdvancedFilters: false,
};

const recurringInitialState: any = {
  postingDate: THIS_MONTHS_START.toISO(),
  billingCycles: DEFAULT_BILLING_CYCLE,
  selectedCategories: [billableItemType.recurring],
  includeWO: true,
  clients: null,
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    toggleSelectedCategory: (state, action: PayloadAction<number>) => {
      let newState;
      if (state.selectedCategories.indexOf(action.payload) === -1) {
        newState = [...state.selectedCategories, action.payload];
      } else {
        newState = state.selectedCategories?.filter(
          (f) => f !== action.payload
        );
      }
      state.selectedCategories = newState;
    },
    setItem: (state: FiltersState, action: PayloadAction<SetItemPayload>) => {
      return { ...state, ...action.payload };
    },
    selectMidmonthDate: (state) => {
      state.dateFrom = THIS_MONTHS_START.toISO();
      state.dateTo = NOW.toISO();
      state.includeWO = false;
    },
    selectEndmonthDate: (state) => {
      state.dateFrom = LAST_MONTHS_START.toISO();
      state.dateTo = LAST_MONTHS_END.toISO();
      state.includeWO = true;
    },
    setupRecurring: (state) => {
      state.postingDate = recurringInitialState.postingDate;
      state.selectedCategories = recurringInitialState.selectedCategories;
      state.billingCycles = recurringInitialState.billingCycles;
      state.clients = null;
    },
    setupProServices: (state) => {
      state.selectedCategories = initialState.selectedCategories;
    },
    resetFilters: () => {
      return initialState;
    },
    resetFiltersRecurring: () => {
      return recurringInitialState;
    },
    resetAdvancedFilters: (state: FiltersState) => {
      return {
        ...state,
        clients: null,
        projects: null,
        workOrders: null,
        invoiceOwners: null,
        departments: null,
        employees: null,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setItem,
  toggleSelectedCategory,
  selectMidmonthDate,
  selectEndmonthDate,
  setupRecurring,
  setupProServices,
  resetFilters,
  resetFiltersRecurring,
  resetAdvancedFilters,
} = filtersSlice.actions;

export const selectFilters = (state: { filters: FiltersState }) => {
  return {
    dateFrom: state.filters.dateFrom,
    dateTo: state.filters.dateTo,
    postingDate: state.filters.postingDate,
    billingCycles: state.filters.billingCycles,
    selectedCategories: state.filters.selectedCategories,
    includeWO: state.filters.includeWO,
  };
};

export const selectRecurringFilters = (state: { filters: FiltersState }) => {
  return {
    postingDate: state.filters.postingDate,
    billingCycles: state.filters.billingCycles,
    clients: state.filters.clients,
  };
};

export const selectAdvancedFilters = (state: { filters: FiltersState }) => {
  return {
    clients: state.filters.clients,
    projects: state.filters.projects,
    workOrders: state.filters.workOrders,
    invoiceOwners: state.filters.invoiceOwners,
    departments: state.filters.departments,
    employees: state.filters.employees,
    showAdvancedFilters: state.filters.showAdvancedFilters,
  };
};

export const selectFiltersLabels = (state: {
  filters: FiltersState;
  metaBilling: MetaBillingState;
}) => {
  return {
    postingDate: DateTime.fromISO(state.filters.postingDate)
      .setLocale("en-US")
      .toFormat("DD"),
    dateFrom: DateTime.fromISO(state.filters.dateFrom)
      .setLocale("en-US")
      .toFormat("DD"),
    dateTo: DateTime.fromISO(state.filters.dateTo)
      .setLocale("en-US")
      .toFormat("DD"),
    billingCycles:
      (state.filters.billingCycles &&
        state.metaBilling.billingCycles &&
        state.filters.billingCycles.length <
          state.metaBilling.billingCycles.length &&
        state.filters.billingCycles?.map((item) => item.label)?.join(", ")) ||
      ALL_CYCLES,
    clients:
      state.filters.clients?.map((item) => item.label)?.join(", ") ||
      ALL_CLIENTS,
    projects:
      state.filters.projects?.map((item) => item.label)?.join(", ") ||
      ALL_PROJECTS_AND_WORK_ORDERS,
    invoiceOwners:
      state.filters.invoiceOwners?.map((item) => item.label)?.join(", ") ||
      ALL_OWNERS,
    departments:
      state.filters.departments?.map((item) => item.label)?.join(", ") ||
      ALL_DEPARTMENTS,
    employees:
      state.filters.employees?.map((item) => item.label)?.join(", ") ||
      ALL_USERS,
  };
};

export default filtersSlice.reducer;
